import * as React from "react";
import { styled } from "@mui/system";
import { Tabs } from "@mui/base/Tabs";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader/Loader";
import { Button, InputAdornment, TextField } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import CloseIcvon from "../images/Income/close-square.png";
import PaymentMethodSelect from "../UI/PaymentMethodSelect";
import AddImageIcon from "../images/Income/AddImageIcon.png";
import arrowImage from "../images/hisabdetails/LeftArrow.png";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import DownArrowIcon from "../images/Income/DownArrowIcon.png";
import { apiService } from "../app/services/api";
import { decryptAES, encryptAES } from "../components/CommonLibrary";
import { setFetchAllHisab } from "../app/Redux/reducer";

export default function AddIncome() {
  // Constants and Hooks
  const navigate = useNavigate();
  const { transactionId, hisabId, hisabType } = useParams();
  const fileInputRef = useRef();
  let UrlTransactionId;
  if (transactionId) {
    const decryptedID = decryptAES(transactionId);
    const urlObject = JSON.parse(decryptedID ? decryptedID : null);
    UrlTransactionId = urlObject?.transactionId;
  }

  // localstorage
  const tokenUrl = localStorage.getItem("accessToken");
  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];

  // Selectors
  const userId = useSelector(
    (state) => state.usersLoginData.loginUserDetails.user_id
  );
  const userTransactionData = useSelector(
    (state) => state.usersLoginData?.transactionDetails?.transactions_data
  );
  const language = useSelector((state) => state.usersLoginData.allLanguage);
  const dispatch = useDispatch();

  // Extract hisab number and type
  let decryptedHisabId;
  let decryptedHisabType;
  if (hisabId) {
    const urlHisabId = decryptAES(hisabId);
    decryptedHisabId = JSON.parse(urlHisabId ? urlHisabId : null);
  }
  if (hisabType) {
    const urlHisabType = decryptAES(hisabType);
    decryptedHisabType = JSON.parse(urlHisabType ? urlHisabType : null);
  }
  const hisabNumber = decryptedHisabId?.id;
  const transactionType = decryptedHisabType?.hisabType;

  // State Management
  const [formState, setFormState] = useState({
    title: "",
    amount: "",
    description: "",
    incomeType: transactionId ? "" : "cash",
    transactionDate: "",
    selectedTab: transactionId ? "" : transactionType === "Income" ? 1 : 2,
    incomeOrExpense: transactionId ? "" : transactionType,
    actualImages: [],
    deletedImages: [],
    isLoading: false,
    fileError: "",
    hisabDetailsData: [],
    imageError: "",
  });

  // Destructure state for easier access
  const {
    title,
    amount,
    description,
    incomeType,
    transactionDate,
    selectedTab,
    incomeOrExpense,
    actualImages,
    deletedImages,
    isLoading,
    hisabDetailsData,
  } = formState;

  // Update state utility function
  const updateFormState = (updates) => {
    setFormState((prev) => ({ ...prev, ...updates }));
  };

  // API Calls
  const fetchTransactionDetails = async () => {
    updateFormState({ isLoading: true });
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/transaction/list_transaction`;
      const body = { hisab_id: hisabNumber, page_no: 1 };
      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);

      const response = await apiService(url, "POST", formData, tokenUrl);
      const decryptedData = JSON.parse(decryptAES(response.data.response));

      if (decryptedData.status === 200 || decryptedData.status === "200") {
        toast.success(decryptedData.message);
        updateFormState({
          hisabDetailsData: decryptedData,
          isLoading: false,
        });
      } else {
        throw new Error(decryptedData.message);
      }
    } catch (error) {
      toast.error(error.message);
      updateFormState({ isLoading: false });
    }
  };

  const handleSubmit = async () => {
    updateFormState({ isLoading: true });
    const rawValue = amount.replace(/,/g, "");
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}${
        transactionId
          ? "/transaction/edit_transaction"
          : "/transaction/add_transaction"
      }`;

      const body = {
        user_id: userId,
        hisab_id: hisabNumber || userTransactionData.hisab_id,
        transaction_type: incomeOrExpense,
        transaction_title: title,
        transaction_amount: rawValue,
        transaction_mode: incomeType,
        transaction_detail: description,
        transaction_date: transactionDate || formattedToday,
        transactions_id: transactionId ? userTransactionData.id : "",
        ...(transactionId &&
          deletedImages.length > 0 && {
            deleted_transaction_photo_id: deletedImages,
          }),
      };

      const formData = new FormData();
      const encryptedData = encryptAES(JSON.stringify(body));
      formData.append("data", encryptedData);
      actualImages.forEach((file) =>
        formData.append("transaction_photo[]", file)
      );

      const response = await apiService(url, "POST", formData, tokenUrl);
      const decryptedData = JSON.parse(decryptAES(response.data.response));

      if (decryptedData.status === 200 || decryptedData.status === "200") {
        toast.success(decryptedData.message);
        dispatch(setFetchAllHisab(true));
        updateFormState({
          hisabDetailsData: decryptedData,
          isLoading: false,
        });
        navigate(-1);
      } else {
        throw new Error(decryptedData.message);
      }
    } catch (error) {
      toast.error(error.message);
      updateFormState({ isLoading: false });
    }
  };

  // Event Handlers
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["image/png", "image/jpg", "image/jpeg"];
      if (!allowedTypes.includes(file.type)) {
        updateFormState({
          fileError: "Please upload a valid image (png, jpg, jpeg).",
        });
        return;
      } else if (file.size >= 5000000) {
        updateFormState({ fileError: "Please upload an image less than 5 MB" });
        return;
      }
      updateFormState({
        actualImages: [...actualImages, file],
        fileError: "",
      });
    }
  };

  const handleTabChange = (event, newValue) => {
    updateFormState({
      selectedTab: newValue,
      incomeOrExpense: newValue === 1 ? "Income" : "Expense",
    });
  };

  const handleRemoveImage = (image) => {
    const updatedImages = actualImages.filter((img) => img !== image);
    updateFormState({
      actualImages: updatedImages,
      ...(image.id && {
        deletedImages: [...deletedImages, image.id],
      }),
    });
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Effects
  useEffect(() => {
    if (hisabNumber) {
      fetchTransactionDetails();
    }
  }, [hisabNumber]);

  useEffect(() => {
    if (
      (hisabNumber === undefined || transactionType === undefined) &&
      UrlTransactionId === undefined
    ) {
      navigate("/Error");
    }
  }, [transactionType, hisabNumber, transactionId]);

  useEffect(() => {
    if (formState.fileError.length > 0) {
      toast.error(formState.fileError);
      setTimeout(() => {
        updateFormState({ fileError: "" });
      }, 500);
    }
  }, [formState.fileError]);

  useEffect(() => {
    if (transactionId && userTransactionData) {
      const cleanedAmount = userTransactionData.transaction_amount
        .replace(/,/g, "")
        .split(".")[0];

      const inputDateString = userTransactionData.transaction_date;
      const parsedDate = new Date(
        inputDateString.replace(/(\d{2}) (\w{3}), (\d{2})/, "$3-$2-$1")
      );
      const localDate = new Date(
        parsedDate.getTime() - parsedDate.getTimezoneOffset() * 60000
      );
      const editDate = localDate.toISOString().split("T")[0];

      updateFormState({
        title: userTransactionData.transaction_title,
        amount: cleanedAmount,
        description: userTransactionData.transaction_detail,
        actualImages: userTransactionData.transaction_images,
        selectedTab: userTransactionData.transaction_type === "income" ? 1 : 2,
        incomeOrExpense: userTransactionData.transaction_type,
        incomeType: userTransactionData.transaction_mode,
        transactionDate: editDate,
      });
    }
  }, [transactionId, userTransactionData]);

  return (
    <>
      {isLoading ? (
        <div className="flex items-center !justify-center !w-[96%] !h-[100vh]">
          <Loader width={40} height={40} color={"black"} />
        </div>
      ) : (
        <div className="flex flex-col items-center h-[auto] overflow-x-hidden">
          {/* header div */}
          <div className="flex pl-[10px] justify-start w-[100%] max-680:w-[90%] border-b">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              <img alt="back" src={arrowImage}></img>
            </button>
            <h2 className="text-[16px] font-sans pt-2 pb-3 pl-2 font-semibold">
              {/* {hisabDetailsData.hisab_name
                ? hisabDetailsData.hisab_name
                : userTransactionData?.hisab_name} */}
                {transactionId ? language.edit_Transcation : (selectedTab == 1 ? language.add_Income : language.add_Expenses )}
            </h2>
          </div>

          {/* main div */}
          <div className="w-[90%] flex flex-col gap-3 items-center mt-1 max-480:w-[95%]">
            <Tabs
              value={selectedTab}
              className="mt-1 w-full border-solid border-[1px] border-[#E7E7E8]  rounded-[5px]"
              onChange={handleTabChange}
            >
              <TabsList>
                <Tab className="h-[98%] flex items-center" value={1}>
                  <img
                    className="w-[20px] h-[20px] mr-2 max-680:w-[20px] max-680:h-[28px]"
                    src={DownArrowIcon}
                    alt="arrow"
                  ></img>
                  <p className="text-[15px] max-480:text-[12px]">
                    {" "}
                    {language.income}
                  </p>
                </Tab>
                <Tab className="h-[100%] flex items-center" value={2}>
                  <img
                    className="w-[20px] h-[20px] mr-2 rotate-180 max-680:w-[20px] max-680:h-[28px]"
                    src={DownArrowIcon}
                    alt="arrow"
                  ></img>
                  <p className="text-[15px] max-480:text-[12px]">
                    {" "}
                    {language.expense}
                  </p>
                </Tab>
              </TabsList>
            </Tabs>

            <section className="flex w-full justify-evenly max-680:flex-col gap-5">
            <TextField
                value={amount}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  let value = e.target.value.replace(/\D/g, "");
                  if (value.length <= 13) {
                    let formattedValue = new Intl.NumberFormat("en-IN").format(
                      value
                    );
                    e.target.value = formattedValue;
                    updateFormState({ amount: formattedValue });
                  }
                }}
                className="w-[50%] bg-[white] max-680:w-[100%]"
                type="text"
                inputProps={{ maxLength: 13 }}
                label={
                  <span>
                    {language.amount} <span style={{ color: "red" }}>*</span>
                  </span>
                }
              />
              <TextField
                value={title}
                onChange={(e) => updateFormState({ title: e.target.value })}
                className="w-[50%] bg-[white] max-680:w-[100%] h-[40px]"
                inputProps={{ maxLength: 25 }}
                size="small"
                label={
                  <span>
                    {language.title} <span style={{ color: "red" }}>*</span>
                  </span>
                }
              ></TextField>
            </section>
            <PaymentMethodSelect
              updateFormState={updateFormState}
              incomeType={incomeType}
            />
            <TextField
              label={<span>{language.description} </span>}
              value={description}
              inputProps={{ maxLength: 250 }}
              variant="outlined"
              fullWidth
              className="!w-full !h-[auto] border-[#00000026] border-[1.5px] rounded bg-white"
              multiline
              rows={3}
              defaultValue="1"
              maxRows={10}
              sx={{
                "& .MuiInputBase-root": {
                  height: "100px",
                  display: "flex", // Use flexbox
                  flexDirection: "column", // Ensure text flows vertically
                  alignItems: "flex-start",
                  maxHeight: "100px",
                  flexWrap: "wrap",
                },
              }}
              InputProps={{
                sx: {
                  ["textarea"]: {
                    // whiteSpace: 'pre',
                    overflowY: "auto", // requires !important, cannot be accessed or overwritten otherwise
                  },
                },
              }}
              onChange={(e) => updateFormState({ description: e.target.value })}
            />

            <section className="w-full flex justify-evenly max-680:flex-col gap-3">
              <TextField
                variant="outlined"
                type="date"
                className="w-[50%] bg-[white] max-680:w-[98%]"
                label={language.transaction_start_date}
                value={transactionDate || formattedToday}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    max: formattedToday,
                  },
                }}
                onChange={(e) =>
                  updateFormState({ transactionDate: e.target.value })
                }
              />
              <div className="w-[50%] max-680:w-[98%] max-680:h-[50px] rounded-md flex items-center border border-solid bg-[white]">
                <label
                  htmlFor="file-upload"
                  className="btn-custom bg-gray-200 ml-2 rounded-sm text-[14px] p-2 h-[1.6rem] flex items-center cursor-pointer"
                >
                  {language.upload_Image}
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={handleFileChange}
                  className="hidden" // hide the default file input
                  ref={fileInputRef}
                  disabled={actualImages.length >= 5}
                />
              </div>
            </section>

            <div
              className={`w-full !gap-[4%] overflow-auto flex justify-start flex-nowrap mt-3 mb-3 border-solid border-[2px] border-[#ddd] rounded-md p-3 bg-[white] ${actualImages.length > 0 ? "" : "hidden"}`}
            >
              <div
                className={` cursor-pointer flex items-center justify-center flex-shrink-0 w-[100px] h-[100px] border-dashed border-[#F5B820] border-[2px] ${actualImages.length > 4 ? "hidden" : ""}`}
                onClick={handleImageClick}
              >
                <img
                  src={AddImageIcon}
                  className="w-[30%] h-[30%]"
                  alt="add icon"
                ></img>
              </div>
              {actualImages.map((item) => (
                <div className="!relative flex-shrink-0 mr-[20px]">
                  <img
                    className="w-[100px] h-[100px] rounded"
                    src={
                      item?.transaction_photo
                        ? `${process.env.REACT_APP_API_BASE_URL}/uploads/transactions/hisab_${hisabId ? hisabId : userTransactionData.hisab_id}/${item.transaction_photo}`
                        : item
                          ? URL.createObjectURL(item)
                          : ""
                    }
                    alt={`preview-${item}`}
                  />
                  <Button
                    className="!absolute !top-0 !right-0 !z-10 !w-[20px] !justify-end"
                    onClick={() => {
                      handleRemoveImage(item);
                    }}
                  >
                    <img
                      src={CloseIcvon}
                      alt="close"
                      className="w-[28px] h-[28px]"
                    />
                  </Button>
                </div>
              ))}
            </div>
            <div className="flex w-full gap-[5%] mb-3 max-680:flex-col">
              <Button
                type="submit"
                className={`w-[50%] !capitalize md:!h-[48px] md:!text-[14px] !bg-gradient-to-r from-[#FFD87A] via-[#F6B825] to-[#F6B825] ${title.length && amount.length > 0 ? "filter-none" : "blur-[1px] opacity-[0.4]"}  !text-[black] !font-semibold max-680:w-[97%] mt-2 mb-4`}
                onClick={handleSubmit}
                disabled={title.length <= 0 || amount.length <= 0}
              >
                {transactionId ? language.edit_Transcation : (selectedTab == 1 ? language.add_Income : language.add_Expenses )}

              </Button>
              <Button
                type="Button"
                className="w-[50%] !capitalize md:!h-[48px]  md:!text-[14px] !border-[1px] !border-solid !border-[#E2E8F0] !text-[black] !font-semibold max-680:w-[97%] mt-2 mb-4"
                onClick={() => {
                  navigate(-1);
                }}
              >
                {language.cancel}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const Tab = styled(BaseTab)`
  font-family: "IBM Plex Sans", sans-serif;
  color: black;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  line-height: 2.5;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &:focus {
    color: #fff;
  }

  &.${tabClasses.selected} {
    background: linear-gradient(135.53deg, #ffd87a 2.61%, #f6b825 96.58%);
    color: black;
    height: 100%;
  }
`;

const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
  background-color: white;
  border-radius: 5px;
  // margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  height: 40px;
  `
);
