import { toast } from "react-toastify";
import OTPInput from "react-otp-input";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import Otp from "../../../../components/OtpInput/Otp";
import Loader from "../../../../components/Loader/ButtonLoader";
import { apiService } from "../../../../app/services/api";
import React, { useCallback, useEffect, useState } from "react";
import CustomModal from "../../../../components/CommonModal/CustomModal";
import { decryptAES, encryptAES } from "../../../../components/CommonLibrary";
import MpinLogin from "../MpinLogin";

const MpinSet = () => {
  const language = useSelector((state) => state.usersLoginData.allLanguage);
  const userPhoneNumber = useSelector((state) => state.usersLoginData.useMobileNumber);

  const [otp, setOtp] = useState("");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmMpin, setConfirmMpin] = useState("");
  const [mpinCancel, setMpinCancel] = useState(false);
  const [dynamicWidth, setDynamicWidth] = useState("48px");
  const [dynamicHeight, setDynamicheight] = useState("40px");
  const [isSuccessMessage, setIsSuccessMessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [dynamicMarginRight, setDynamicMarginRight] = useState("30px");

  useEffect(() => {
    updateMarginRight();

    window.addEventListener("resize", updateMarginRight);

    return () => window.removeEventListener("resize", updateMarginRight);
  }, [dynamicWidth, dynamicHeight, dynamicMarginRight]);

  // validate PIN
  const validateMpin = useCallback(() => {
    if (otp.length === 4 && confirmMpin.length === 4) {
      if (otp === confirmMpin) {
        setIsButtonDisabled(false);
      } else {
        toast.error(language.pin_Does_Not_Set);
        setIsButtonDisabled(true);
      }
    } else {
      setIsButtonDisabled(true);
    }
  }, [otp, confirmMpin, language]);

  useEffect(() => {
    validateMpin();
  }, [validateMpin]);

  const handleOnClick = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setIsButtonDisabled(true);
    try {
      const url = process.env.REACT_APP_API_BASE_URL + "/security/reset_pin";

      const tokenUrl = localStorage.getItem("auth_default_token");

      const parameters = {
        mobile_number: Number(userPhoneNumber),
        country_id: "91",
        pin: confirmMpin,
      };

      const encryptedData = encryptAES(JSON.stringify(parameters));

      const formData = new FormData();
      formData.append("data", encryptedData);

      const result = await apiService(url, "POST", formData, tokenUrl);

      const decryptedResponse = decryptAES(result.data.response);

      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        // toast.success(JSON.parse(decryptedResponse).message);
        setIsSuccessMessage(JSON.parse(decryptedResponse).message);
        setIsLoading(false);
        setIsButtonDisabled(false);
        setOpen(true);
        setMpinCancel(true);
        // navigate("/mpinlogin");
      } else {
        toast.error(JSON.parse(decryptedResponse).message);
        setIsLoading(false);
        setIsButtonDisabled(false);
      }
    } catch (error) {
      // setIsSetPin(false);
      setIsLoading(false);
      setIsButtonDisabled(false);
    }
  };

  const updateMarginRight = () => {
    if (window.innerWidth <= 320) {
      setDynamicWidth("50px");
      setDynamicheight("50px");
      setDynamicMarginRight("15px");
    } else if (window.innerWidth <= 375) {
      setDynamicWidth("60px");
      setDynamicheight("60px");
      setDynamicMarginRight("20px");
    } else if (window.innerWidth <= 425) {
      setDynamicWidth("60px");
      setDynamicheight("60px");
      setDynamicMarginRight("34px");
    } else {
      setDynamicWidth("48px");
      setDynamicheight("40px");
      setDynamicMarginRight("30px");
    }
  };

  const handleMPINCancel = () => {
    setMpinCancel(true);
  }

  return (
    <>
      {mpinCancel ? <MpinLogin /> :
        <Box>
          {/* <Header title={language.set_PIN}/> */}
          <Box className="flex justify-center items-center flex-col mt-[30px]">
            <Box className=" overflow-hidden flex justify-center items-center flex-col">
              <Box className=" max-425:!w-[100%] mt-6">
                <form onSubmit={handleOnClick} >
                  <Box className=" max-425:ml-[0px]">
                    <Box>
                      <Typography className="text-[14px] font-medium text-[#444444] !mb-[11px]">
                        {language.new_pin}
                      </Typography>
                      <Box className="mt-[20.56px] z-[9999]"
                        sx={{
                          transform: 'translateZ(0)', // Fix for Chrome mobile blur
                          '-webkit-font-smoothing': 'antialiased',
                          backfaceVisibility: 'hidden'
                        }}
                      >
                        <Otp
                          setOtp={setOtp}
                          otp={otp}
                          inputsNum={4}
                          width={dynamicWidth}
                          height={dynamicHeight}
                          marginRight={dynamicMarginRight}
                          fontSize={18}
                          fontWeight={"600"}
                        />
                      </Box>
                    </Box>

                    <Box>
                      <Typography className="text-[14px] font-medium text-[#444444] !my-[11px]">
                        {language.verify_pin}
                      </Typography>
                      <OTPInput
                        value={confirmMpin}
                        onChange={setConfirmMpin}
                        numInputs={4} // 6
                        renderSeparator={false}
                        renderInput={(props) => (
                          <input
                            {...props}
                            type="tel"
                            pattern="[0-9]*"
                            maxLength={1}
                            style={{
                              width: dynamicWidth, // 45,
                              height: dynamicHeight, // 55,
                              textAlign: "center",
                              fontSize: "18px",
                              border: "1px solid #EEF0F2",
                              borderRadius: "4px",
                              outline: "none",
                              fontWeight: "600",
                              marginRight: dynamicMarginRight, //25
                              transition: "border-color 0.3s ease",
                              WebkitTransform: 'translateZ(0)',
                              WebkitFontSmoothing: 'antialiased',
                              backfaceVisibility: 'hidden'
                            }}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                  <Box className="flex justify-center items-center">

                    <button
                      className={`${isButtonDisabled !== true
                        ? "bg-gradient-to-r from-[#FFD87A] to-[#F6B825]"
                        : "bg-[#FFD87A] opacity-[35%] text-gray-400"
                        } w-[128px] max-425:w-[100%] h-[40px] rounded-[5px] text-[16px] font-medium mt-[30px]`}
                      disabled={isButtonDisabled}
                      onClick={handleOnClick}
                    >
                      {isLoading === true ? (
                        <Loader width={20} height={20} color={"black"} />
                      ) : (
                        language.set_PIN_btn
                      )}
                    </button>
                    <NavLink
                      className="bg-white  mt-[27px] mr-[30px] !font-medium ml-5 !text-[16px] w-[128px] h-[40px] px-4 rounded-md transition-colors flex justify-center items-center"
                      style={{ border: "1px solid #E2E8F0" }}
                      onClick={handleMPINCancel}
                    >
                      {language.cancel}
                    </NavLink>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>

          {/* Success Modal */}
          <CustomModal
            open={open}
            handleClose={() => setOpen(false)}
            isCloseIcon={false}
            widthFor={{ xs: "90%", sm: "400px", md: "280px" }}
            height="150px"
            borderRadiusForBox="15px"
          >
            <div className="flex flex-col justify-center items-center h-full gap-7">
              <Typography
                variant="h6"
                className="text-center font-semibold text-[16px]"
                sx={{ fontSize: "1rem", "@media (max-width: 768px)": { fontSize: "12px" } }}
              >
                {isSuccessMessage}
              </Typography>
              <NavLink
                to="/account"
                className="bg-yellow-400 h-10 font-semibold px-4 py-2 text-[16px] rounded-md text-center w-full"
              >
                {language?.okay || "Okay"}
              </NavLink>
            </div>
          </CustomModal>
        </Box>}

    </>
  );
};

export default MpinSet;