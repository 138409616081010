import {
  Avatar,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { div, useNavigate, useParams } from "react-router-dom";
import Add from "../images/DetailsSection/add.png";
import View from "../images/DetailsSection/View.png";
import Admin from "../images/DetailsSection/admin.png";
import { useState } from "react";
import Model from "../components/CommonModal/Model";
import { toast } from "react-toastify";
import { decryptAES, encryptAES } from "../components/CommonLibrary";
import { apiService } from "../app/services/api";
import { useDispatch, useSelector } from "react-redux";
import { SelectAll } from "@mui/icons-material";
import { setFetchAllHisab } from "../app/Redux/reducer";
import { getOnlyDate } from "../utils/helper";

const ParticipantCard = ({ participant, hisabStartDate, adminId }) => {
  const { id } = useParams();
  let decryptedID;
  if (id) {
    decryptedID = decryptAES(id);
  }
  const urlObject = JSON.parse(decryptedID ? decryptedID : null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const number = urlObject?.id;
  const [open, setOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modalFlag, setModalFlag] = useState(false);
  const [participantToRemove, setParticipantToRemove] = useState(null);
  const [isBtnDisabled ,setIsBtnDisabled ] = useState(false);
  const language = useSelector((state) => state.usersLoginData.allLanguage);
  const userData = useSelector(
    (state) => state.usersLoginData.loginUserDetails
  );

  const handleActionSelect = (event) => {
    setSelectedAction(event.target.value);
    setShowConfirmation(true);
    setModalFlag(false);
  };

  const handleConfirmAction = async () => {
    if (selectedAction === "view" || selectedAction === "edit") {
      await markHisabCompleted(participant.id, selectedAction);
    }
    setShowConfirmation(false);
    setOpen(false);
  };

  const markHisabCompleted = async (participant, selectedAction) => {
    try {
      const tokenUrl = localStorage.getItem("accessToken");
      const url =
        process.env.REACT_APP_API_BASE_URL + "/participant/edit_participant";
      const body = {
        hisab_id: number,
        user_id: participant,
        participant_permission: selectedAction,
      };
      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);
      const res = await apiService(url, "POST", formData, tokenUrl);
      const decryptedResponse = decryptAES(res.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        toast.success(JSON.parse(decryptedResponse).message);
        const data = await JSON.parse(decryptedResponse);
        navigate(-1);
        dispatch(setFetchAllHisab(true));
        setOpen(false);
      } else {
        toast.error(JSON.parse(decryptedResponse).message);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const removeParticipant = async (participant) => {
    try {
      setIsBtnDisabled(true)
      const tokenUrl = localStorage.getItem("accessToken");
      const url = process.env.REACT_APP_API_BASE_URL + "/hisab/exit_hisab";
      const body = {
        hisab_id: number,
        user_id: participantToRemove.id,
      };
      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);
      const res = await apiService(url, "POST", formData, tokenUrl);
      const decryptedResponse = decryptAES(res.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        toast.success(JSON.parse(decryptedResponse).message);
        const data = await JSON.parse(decryptedResponse);
        navigate(-1);
        dispatch(setFetchAllHisab(true));
        setOpen(false);
        setIsBtnDisabled(false)
      } else {
        toast.error(JSON.parse(decryptedResponse).message);
        setIsBtnDisabled(false)
      }
    } catch (error) {
      console.error("error", error);
      setIsBtnDisabled(false)
    }
  };

  const handleClick = () => {
    if (adminId && participant.id != userData.user_id) {
      setOpen(true);
    }
  };

  const handleremove = () => {
    if (participant.type !== "Admin" && participant.id !== userData.id) {
      setParticipantToRemove(participant);
      setShowConfirmation(true);
      setModalFlag(true);
    }
  };

  return (
    <div className="w-[100%] flex flex-col items-center">
      <div className="flex w-[96%] gap-1 border-[#F5F5F5] border-2 items-center pl-2 pr-2 rounded-lg h-[63px]">
        <Avatar
          className="!h-[20px] !w-[20px] md:!h-[40px] md:!w-[40px]"
          alt={participant.name}
          src={`${process.env.REACT_APP_API_BASE_URL}/uploads/user/${participant.id}/${participant.photo}`}
        />
        <div className="flex items-center flex-col w-[100%]">
          <section className="flex justify-between w-[98%] mb-2">
            <p className="font-medium text-[14px] max-480:text-[10px]">
              {participant.name}
            </p>
            <p
              className={`font-bold text-[12px] max-480:text-[10px] 
              ${participant.balance.startsWith("-") ? "text-[#E5474D]" : "text-[#46A759]"}`}
            >
              {participant.balance}
            </p>
          </section>
          <section className="flex justify-between w-[98%] items-center">
            <div
              className={`flex gap-1 items-center ${participant.type === "Admin" || participant.id === userData.id ? "cursor-not-allowed" : adminId ? "cursor-pointer" : "cursor-default"}`}
              onClick={handleClick}
            >
              <img
                src={
                  participant.type === "Admin"
                    ? Admin
                    : participant.type === "User" &&
                        participant.permission === "view"
                      ? View
                      : participant.type === "User" &&
                          participant.permission === "edit"
                        ? Add
                        : null
                }
                alt="icon"
                className="h-[10px]"
              />
              <p className="text-[12px] px-[6px] font-medium">
                {participant.type === "Admin"
                  ? "Admin"
                  : participant.type === "User" &&
                      participant.permission === "view"
                    ? "View"
                    : participant.type === "User" &&
                        participant.permission === "edit"
                      ? "Edit"
                      : null}
              </p>
            </div>
            <p className="text-[10px] text-[#B1B1B1] max-480:text-[10px]">
              {getOnlyDate(hisabStartDate)}
            </p>
          </section>
        </div>
      </div>

      <Model
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        isCloseIcon={false}
        widthFor={{ xs: "90%", sm: "280px", md: "380px" }}
        className="h-fit"
      >
        <FormControl className="w-[100%] mt-7" sx={{ minWidth: 200 }}>
          <InputLabel className="text-[14px]" size="small">
            {participant.permission === "edit" ? "Edit hisab" : "View Hisab"}
          </InputLabel>
          <Select
            value={selectedAction}
            onChange={handleActionSelect}
            label={
              participant.permission === "edit" ? "Edit hisab" : "View Hisab"
            }
            size="small"
          >
            <MenuItem value="view">
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                {language.view_hisab_appbar}
              </div>
            </MenuItem>
            <MenuItem value="edit">
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                {language.edit_hisab_appbar}
              </div>
            </MenuItem>
          </Select>
        </FormControl>

        <div className="flex mb-6 w-full justify-evenly mt-3">
          <Button
            className="mt-5 px-4 rounded w-[40%] h-[32px] text-black sm:text-sm !text-[10px] max-680:text-[8px]"
            style={{ border: "1px solid black" }}
            onClick={() => {
              setOpen(false);
            }}
          >
            {language.cancel}
          </Button>
          <Button
            onClick={handleremove}
            className="mt-5 bg-gradient-to-r from-[#FFD87A] via-[#F6B825] to-[#F6B825] text-black w-[40%] h-[32px] !text-[10px] max-680:text-[8px] p-2"
          >
            {language.remove_participant}
          </Button>
        </div>
      </Model>

      <Model
        open={showConfirmation}
        handleClose={() => setShowConfirmation(false)}
        isCloseIcon={true}
        widthFor={{ xs: "90%", sm: "280px", md: "350px" }}
        height="150px"
      >
        {modalFlag ? (
          <div className="flex flex-col items-center mt-3">
            <p className="font-medium text-center mt-[4px]">
              {language.ask_Remove_Uesr}
            </p>
            <div className="flex gap-2 mt-3">
              <button
                style={{ border: "1px solid black" }}
                className="px-4 rounded w-28 h-[32px] text-black"
                onClick={() => {
                  setShowConfirmation(false);
                  setOpen(false);
                }}
              >
                {language.no}
              </button>
              <button
                className="px-4 !bg-gradient-to-r from-[#FFD87A] via-[#F6B825] to-[#F6B825] text-black rounded w-28 h-[32px]"
                onClick={removeParticipant}
                disabled={isBtnDisabled}
              >
                {language.yes}
              </button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center mt-3">
            <p className="font-medium text-center mt-[4px]">
              {language.ask_Chnage_Permission_Uesr}
            </p>
            <div className="flex gap-2 mt-3">
              <button
                style={{ border: "1px solid black" }}
                className="px-4 rounded w-28 h-[32px] text-black"
                onClick={() => {
                  setShowConfirmation(false);
                  setOpen(false);
                }}
              >
                {language.no}
              </button>
              <button
                className="px-4 !bg-gradient-to-r from-[#FFD87A] via-[#F6B825] to-[#F6B825] text-black rounded w-28 h-[32px]"
                onClick={handleConfirmAction}
              >
                {language.yes}
              </button>
            </div>
          </div>
        )}
      </Model>
    </div>
  );
};

export default ParticipantCard;
