import React from "react";
import { Button } from "@mui/material";
import HisabLogo from "../../images/sideBar/Group 1943.png";
import addIcon from '../../images/sideBar/Shape.png.png'
import { useLocation, useNavigate } from "react-router-dom";
import "../HisabDashboard/HisabDashboard.css";
import { useSelector } from "react-redux";
import { matchPath } from "react-router-dom";  // Import matchPath

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const language = useSelector((state) => state.usersLoginData.allLanguage);

  const noAddHisabPaths = [
    "/account",
    "/profile",
    "/currentmpin",
    "/newpinset",
    "/numberchange",
    "/HelpCenter",
    "/ReportIssue",
    "/FAQ",
    "/ContactUs",
    "/deleteaccount",
    "/language",
    "/supportTickets"
  ];

  const isNoAddHisabPage = 
    noAddHisabPaths.includes(location.pathname) || 
    matchPath("/HelpCenter/:id", location.pathname) ||  
    matchPath("/account/:id", location.pathname);   


  const containerClassName = `${
    isNoAddHisabPage
      ? "flex justify-between items-center w-[100%] mt-[18px] pr-[15px] md:pr-[15px] pl-[15px] md:pl-[14px] mb-[20px]"
      : "flex justify-between items-center w-[100%] mt-[18px] pr-[15px] md:pr-[15px] pl-[15px] md:pl-[14px] mb-[20px]"
  }`;
  
  const handleAddHisabClick = () => {
    // Prevent navigating multiple times to the same AddHisab page
    if (location.pathname !== "/AddHisab") {
      navigate("/AddHisab");
    }
  };

  return (
    <div className={containerClassName}>
      <img src={HisabLogo} alt="logo" className="w-[124px] max-960:w-[90px]" />
      {!isNoAddHisabPage && (
        <Button
          variant="outlined"
          className="text-[21px] h-[34px] !text-black p-1 !border-none !capitalize !font-bold max-h-[45px] !bg-gradient-to-r from-[#FFD87A] via-[#F6B825] to-[#F6B825] max-1097:h-[30px] max-1097:w-[120px]"
          onClick={handleAddHisabClick}
        >
          <img
            src={addIcon}
            alt="logo"
            className="w-[20px] h-[20px] max-640:w-[28px] mr-1 max-1097:!h-[20px] max-1097:!w-[18px]"
          />
          <span className="max-h-[26px] flex items-center font-medium text-[14px] max-640:text-[15px] max-640:h-[42px] max-1097:!text-[12px]">
            {language.add_Hisab}
          </span>
        </Button>
      )}
    </div>
  );
}
export default Header;
