import CryptoJS from "crypto-js";

export function decryptAES(encryptedText) {
  const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET_KEY_IV);
  const secretKey = process.env.REACT_APP_SECRET_KEY;

  const encryptedBytes = CryptoJS.enc.Base64.parse(encryptedText);

  const decryptedBytes = CryptoJS.AES.decrypt(
    { ciphertext: encryptedBytes },
    CryptoJS.enc.Utf8.parse(secretKey),
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  return decryptedBytes.toString(CryptoJS.enc.Utf8);
}

export const encryptAES = (plainText) => {
  try {
    const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET_KEY);
    const ivBytes = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET_KEY_IV);

    const encrypted = CryptoJS.AES.encrypt(plainText, key, {
      iv: ivBytes,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });

    return encrypted.toString(CryptoJS.format.Base64);
  } catch (error) {
    console.error("Encryption failed:", error);
    return null;
  }
};

// export function getDecryptedItem(key) {
//   const encryptedData = localStorage.getItem(key);
//   if (encryptedData) {
//     return decryptAES(encryptedData);
//   }
//   return null;
// }
