import icons from "./SidebarData";
import { toast } from "react-toastify";
import { apiService } from "../../app/services/api";
import CustomModal from "../CommonModal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { removeUserData } from "../../app/Redux/reducer";
import LogoutImage from "../../images/sideBar/Logout.png";
import { decryptAES, encryptAES } from "../CommonLibrary";
import { useLocation, useNavigate } from "react-router-dom";
import DesiHisabLogo from "../../images/sideBar/DesiHisabLogo.png";
import { Badge, Button, Tooltip, Typography } from "@mui/material";
import notificationicon from "../../images/sideBar/notification1.png";
import React, { useCallback, useEffect, useMemo, useState } from "react";

function SideBar({ setDrawerOpen }) {
  // hooks
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.usersLoginData.allLanguage);  

  // State
  const [open, setOpen] = useState(false);
  const [notificationClick, setNotificationClick] = useState(false);
  const [hasViewedNotifications, setHasViewedNotifications] = useState(() => {
    return JSON.parse(localStorage.getItem('hasViewedNotifications') || 'false');
  });

  // Selectors
  const userLoginData = useSelector(state => state.usersLoginData.loginUserDetails);
  const notificationData = useSelector(state => state.usersLoginData.notificationLength);
  const FirstIdAllHisab = useSelector(state => state.usersLoginData?.firstId);
  const encryptedId = encryptAES(JSON.stringify({ id: FirstIdAllHisab }));
  const encodedData = encodeURIComponent(encryptedId);  
  
  // Memoized values
  const dimensions = useMemo(() => ({
    width: window.innerWidth <= 768 ? "45px" : window.innerWidth <= 1097 ? "30px" : "47px",
    height: window.innerWidth <= 768 ? "45px" : window.innerWidth <= 1097 ? "30px" : "45px"
  }), []);

  // Helper functions
  const isPathActive = useCallback((path, subPaths) => {
    if (subPaths) return subPaths.includes(location.pathname);
    if (path.includes(":id")) return location.pathname.startsWith("/AllHisab");
    return location.pathname === path;
  }, [location.pathname]);

  // Handlers
const handleNotificationClick = useCallback(() => {
  setNotificationClick(true);
  setDrawerOpen(false);
  setHasViewedNotifications(true);
  localStorage.setItem('hasViewedNotifications', 'true');
  window.innerWidth > 991 ? navigate(`Notification/${encodedData}`) : navigate(`/Notification/:id=`)
}, [navigate]);

  const handleLogout = useCallback(async (e) => {
    e.preventDefault();

    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/users/logout`;
      const tokenUrl = localStorage.getItem("accessToken");
      const parameters = { user_id: userLoginData.user_id };

      const formData = new FormData();
      formData.append("data", encryptAES(JSON.stringify(parameters)));

      const result = await apiService(url, "POST", formData, tokenUrl);
      const decryptedResponse = JSON.parse(decryptAES(result.data.response));

      if (decryptedResponse.status === 200 || decryptedResponse.status === "200") {
        setOpen(false);
        localStorage.removeItem("accessToken");
        localStorage.setItem("selectedHisabId", 0)
        dispatch(removeUserData());
        toast.success("User logout successfully");
        navigate("/login");
      } else {
        setOpen(false);
        localStorage.removeItem("accessToken");
        dispatch(removeUserData());
        toast.error(decryptedResponse.message);
      }
    } catch (error) {
      console.error("Error in logout:", error);
      toast.error("Logout failed. Please try again.");
    }
  }, [userLoginData.user_id, dispatch, navigate]);

  const handleLogoutClick = useCallback(() => {
    setOpen(true);
  }, []);

  // Effects
  useEffect(() => {
    if (notificationClick) {
      setHasViewedNotifications(true);
      localStorage.setItem('hasViewedNotifications', 'true');
    }
  }, [notificationClick]);
  
  // Effect to reset viewed status when new notifications arrive
  useEffect(() => {
    if (notificationData > 0) {
      const hasViewed = JSON.parse(localStorage.getItem('hasViewedNotifications') || 'false');
      setHasViewedNotifications(hasViewed);
    }
  }, [notificationData]);
  
  return (
    <div className=" h-[100vh] flex flex-col items-center justify-between bg-[#FAFAFA] max-768:!w-[100%]">
      <img
        src={DesiHisabLogo}
        alt="logo"
        className="w-[47px] h-[48px] mb-2 mt-3 max-1097:w-[31px] max-1097:h-[32px] max-768:h-[42px] max-768:w-[42px]"
      />
      <div className="h-[64%] flex flex-col items-center max-1097:gap-[15px] gap-[4px] max-768:gap-[27px]">
        <>
          {icons({language ,encodedData}).map((item) => {
            return (
              <Tooltip
                placement="right"
                title={item.label}
                key={item.id} className="text-[10px]" arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      mr: 0,
                      bgcolor: '#333',
                      color: '#fff',
                      fontSize: '10px',
                      padding: '4px 8px',
                      borderRadius: '4px',
                      '& .MuiTooltip-arrow': {
                        color: '#333'
                      },
                      [`&[data-popper-placement*="right"]`]: {
                        marginLeft: "0px !important"
                      }
                    }
                  }
                }}
              >
                <Button
                  onClick={() => {
                    navigate(item.path);
                    setNotificationClick(false);
                    setDrawerOpen(false);
                  }}
                  className={`flex items-center justify-center max-1097:!h-[30px]`}
                >
                  <div
                    style={{
                      border: isPathActive(item.path, item.subPaths) ? "1px solid #F6B825" : "none",
                      width: dimensions?.width,
                      height: dimensions?.height,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "8px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={item.image}
                      className={`w-[29px] max-1097:w-[16px] max-768:w-[29px]`}
                      alt="sideBar_Image"
                    />
                  </div>
                </Button>
              </Tooltip>
            );
          })}
        </>
      </div>
      <div className="h-[20%] flex flex-col justify-evenly">
        <Tooltip title={language?.notification} placement="right" arrow
          componentsProps={{
            tooltip: {
              sx: {
                mr: 0,
                bgcolor: '#333',
                color: '#fff',
                fontSize: '10px',
                padding: '4px 8px',
                borderRadius: '4px',
                '& .MuiTooltip-arrow': {
                  color: '#333'
                },
                [`&[data-popper-placement*="right"]`]: {
                  marginLeft: "0px !important"
                }
              }
            }
          }}>
          <Button
            onClick={handleNotificationClick}
          >
            <Badge
            sx={{
              '& .MuiBadge-badge': {
                color: "white",
                background: "red",
                height: "15px",
                width: "15px",
                minWidth: "10px",
                fontSize: "10px",
                padding: "0",
                marginRight: "9px",
                display: notificationData === 0 || hasViewedNotifications ? 'none' : 'flex'
              }
            }}
              badgeContent={notificationData}
              className="badgecontent"
            >
              <div
                style={{
                  border: notificationClick ? "1px solid #F6B825" : "none",
                  width: dimensions?.width,
                  height: dimensions?.height,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >

                <img
                  src={notificationicon}
                  className="w-[29px] max-1097:w-[16px] max-768:w-[29px]"
                  alt="sideBar_Image"
                />
              </div>
            </Badge>
          </Button>

        </Tooltip>
        <Tooltip title={language?.logout} placement="right" arrow
          componentsProps={{
            tooltip: {
              sx: {
                mr: 0,
                bgcolor: '#333',
                color: '#fff',
                fontSize: '10px',
                padding: '4px 8px',
                borderRadius: '4px',
                '& .MuiTooltip-arrow': {
                  color: '#333'
                },
                [`&[data-popper-placement*="right"]`]: {
                  marginLeft: "0px !important"
                }
              }
            }
          }}
        >
          <Button
            onClick={() => {
              handleLogoutClick();
            }}
          >
            <img
              src={LogoutImage}
              className="w-[29px] max-1097:w-[16px] max-768:w-[29px]"
              alt="sideBar_Image"
            />
          </Button>
        </Tooltip>
      </div>


      <CustomModal
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        isCloseIcon={false}
        widthFor={{ xs: "90%", sm: "445px" }}
        height="150px"
        borderRadiusForBox="15px"
      >
        <div className="flex justify-center items-center flex-col h-full gap-10">
          <Typography
            variant="h6"
            className="text-center font-semibold text-1xl"
            sx={{
              fontSize: "1rem",
              "@media (max-width: 768px)": {
                fontSize: "10px",
              },
            }}
          >
           {language.ask_Logout}
          </Typography>
          <div className="flex gap-4 max-768:gap-7">
            <button
              onClick={() => {
                setOpen(false);
                setDrawerOpen(false);
              }}
              style={{ border: "1px solid black" }}
              className="rounded-md px-14 max-425:px-11 max-320:!px-8 py-2  h-[40px] font-semibold text-[14px]"
            >
              {language.no}
            </button>
            <button
              onClick={handleLogout}
              className="bg-yellow-400 rounded-md px-14 max-425:px-11   h-[40px] max-320:!px-8 py-2 font-semibold text-[14px]"
            >
              {language.yes}
            </button>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}

export default SideBar;
