import React from "react";
import { Outlet } from "react-router-dom";
import styles from './HisabDetails.module.css'

function HisabDetails() {

  return (
    <div className={`h-full max-991:!w-[100%] ${styles.AddHisabCont}`}>
      <Outlet />
    </div>
  );
}

export default HisabDetails;
