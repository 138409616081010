import React from "react";
import defaultUserImage from "../../../images/loginpage/profile-user.png";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

function MpinProfile() {
  const userData = useSelector(
    (state) => state.usersLoginData.getUser
  );

  return (
    <div className="flex justify-center items-center h-[100vh]">
      <div className="md:w-[649px] m-4 md:!m-0 md:h-[553px] w-auto h-auto bg-white rounded-3xl shadow-2xl overflow-hidden flex justify-between items-center flex-col">
        <div className="md:mt-10">
          <div className="flex justify-center pt-6">
            <img
              src={defaultUserImage}
              alt="Login form illustration"
              className="w-20 h-20 rounded-full border-2 border-yellow-500 object-cover"
            />
          </div>
          <div className="text-center py-6">
            <h3 className="text-lg font-medium text-gray-900">
              Hello {userData.first_name} {userData.last_name}
            </h3>
            <NavLink
              to="/login"
              onClick={() => {
                localStorage.removeItem("auth_default_token");
              }}
            >
              <p className="text-yellow-500 font-medium cursor-pointer">
                Not you?
              </p>
            </NavLink>
          </div>
        </div>
        <div className="px-6 pb-7 flex justify-center items-center w-full">
          <NavLink
            to="/mpinlogin"
            className="text-black font-medium md:w-[63%] px-4 py-3 rounded-md transition-colors border-2 border-gray-600 text-center"
          >
            Login with MPIN
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default MpinProfile;
