import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Otp from "../../../components/OtpInput/Otp";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader/ButtonLoader";
import VerifyPinOtp from "./forgotMpinSection/VerifyPinOtp";
import { setGetUser, setUserLoginData, setUserMobileNumber } from "../../../app/Redux/reducer";
import React, { useEffect, useState, useCallback, memo } from "react";
import { apiService } from "../../../app/services/api";
import { decryptAES, encryptAES } from "../../../components/CommonLibrary";
import Avatar from '@mui/material/Avatar';

const INITIAL_DIMENSIONS = {
  default: { width: "55px", height: "47px", marginRight: "20px" },
  mobile: { width: "65px", height: "70px", marginRight: "20px" },
  small: { width: "53px", height: "65px", marginRight: "20px" },
  tiny: { width: "55px", height: "60px", marginRight: "20px" }
};

function MpinLogin() {
  // States
  const [formState, setFormState] = useState({
    mpin: "",
    isLoading: false,
    isForgetMpin: false,
    isBtnDisabled: false
  });

  const [dimensions, setDimensions] = useState({
    width: INITIAL_DIMENSIONS.default.width,
    height: INITIAL_DIMENSIONS.default.height,
    marginRight: INITIAL_DIMENSIONS.default.marginRight
  });

  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useSelector(state => state.usersLoginData.allLanguage);
  const userLoginData = useSelector(state => state.usersLoginData.loginUserDetails);
  const userPhoneNumber = useSelector(state => state.usersLoginData.useMobileNumber);

   
  // Handlers
  const handleMpinSubmit = useCallback(async (e) => {
    e.preventDefault();
    setFormState(prev => ({ ...prev, isLoading: true, isBtnDisabled: true }));

    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/security/verify_pin`;
      const formData = new FormData();
      formData.append("data", encryptAES(JSON.stringify({ pin: formState.mpin })));

      const result = await apiService(
        url,
        "POST",
        formData,
        encryptAES(userLoginData.access_token)
      );
      
      const response = JSON.parse(decryptAES(result.data.response));

      if (response.status === 200 || response.status === "200") {
        localStorage.setItem("accessToken", encryptAES(userLoginData.access_token));
        localStorage.removeItem("auth_default_token");
        dispatch(setUserLoginData(userLoginData));
        toast.success(response.message);
        navigate("/AllHisab/:id=");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("MPIN verification failed:", error);
      toast.error("MPIN verification failed");
    } finally {
      setFormState(prev => ({ ...prev, isLoading: false, isBtnDisabled: false }));
    }
  }, [formState.mpin, userLoginData, dispatch, navigate]);

  const handleForgotPin = useCallback(async () => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/security/forgot_pin`;
      const tokenUrl = localStorage.getItem("auth_default_token");
      
      const parameters = {
        mobile_number: Number(userPhoneNumber),
        country_id: "91"
      };

      const formData = new FormData();
      formData.append("data", encryptAES(JSON.stringify(parameters)));

      const result = await apiService(url, "POST", formData, tokenUrl);
      const response = JSON.parse(decryptAES(result.data.response));

      if (response.status === 200 || response.status === "200") {
        localStorage.setItem("isActive", "true");
        localStorage.setItem("time", "60");
        setFormState(prev => ({ ...prev, isForgetMpin: true }));
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Forgot PIN request failed:", error);
      toast.error("Failed to process forgot PIN request");
    }
  }, [userPhoneNumber]);

  // Effects
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      let newDimensions;

      if (width <= 320) newDimensions = INITIAL_DIMENSIONS.tiny;
      else if (width <= 375) newDimensions = INITIAL_DIMENSIONS.small;
      else if (width <= 425) newDimensions = INITIAL_DIMENSIONS.mobile;
      else newDimensions = INITIAL_DIMENSIONS.default;

      setDimensions(newDimensions);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (formState.isForgetMpin) {
    return <VerifyPinOtp />;
  }

  return (
    <div className="flex relative flex-col gap-y-[30px] p-6">
      <div className="mt-[20px]">
      <Avatar alt="Remy Sharp" className="mx-auto" src="/static/images/avatar/1.jpg"  sx={{ width: 50, height: 50}}/>
      <h2 className="text-[20px] font-bold text-center mt-1">{language.hello} {userLoginData.first_name} {userLoginData.last_name}</h2>
      <p className="text-[#F5B820] text-center"><a href="/login">{language.not_you}</a></p>
      </div>
      <div className="">
        <h1 className=" text-[16px] md:!text-[14px] font-bold">
          {language.enter_PIN}
        </h1>
        
        <form onSubmit={handleMpinSubmit}>
          <div className="mt-[10.56px]">
            <Otp
              inputsNum={4}
              setOtp={(mpin) => setFormState(prev => ({ ...prev, mpin }))}
              otp={formState.mpin}
              {...dimensions}
              fontSize={18}
              fontWeight="600"
            />
          </div>
          
          <div className="mt-[5.12px]">
            <span
              onClick={handleForgotPin}
              className="text-[#F6B825] font-normal text-[12px] cursor-pointer"
            >
              {language.forgot_PIN}
            </span>
          </div>
          
          <button
            onClick={handleMpinSubmit}
            className={`w-[100%] h-[40.67px] rounded-[5px] text-[14px] font-medium mt-[10px] `}
            style={{
              background:
              formState.mpin.length >= 4
                  ? "linear-gradient(45deg, #EDB61E, #FFA500), linear-gradient(135.53deg, #FFD87A 2.61%, #F6B825 96.58%)"
                  : "linear-gradient(0deg, #EDB61E, #EDB61E), linear-gradient(135.53deg, #FFD87A 2.61%, #F6B825 96.58%)",
              color:"#000", // Apply a lighter/disabled color when inactive
              opacity: formState.mpin.length >= 4 ? 1 : 0.6, // Adjust opacity for disabled effect
            }}
            disabled={formState.mpin.length >= 4 ? formState.isBtnDisabled : true}
            
          >
            {formState.isLoading ? (
              <Loader width={20} height={20} color="black" />
            ) : (
              language.confirm_PIN
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

export default memo(MpinLogin);