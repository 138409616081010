import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Otp from "../OtpInput/Otp";
import { decryptAES, encryptAES } from "../CommonLibrary";
import { loginApi } from "../../app/services/api";
import { toast } from "react-toastify";
import {
  setFetchAllHisab,
  setUserLoginData,
  setUserMobileNumber,
} from "../../app/Redux/reducer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader/ButtonLoader";

function ChangeVerifyNumber({
  mobileNumber,
  setVerifyOtp,
  time,
  setTime,
  isActive,
  setIsActive,
  userId,
  userLoginId,
}) {
  const language = useSelector((state) => state.usersLoginData.allLanguage);
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [dynamicWidth, setDynamicWidth] = useState("63.01px");
  const [dynamicHeight, setDynamicheight] = useState("47px");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let timerInterval;
    if (isActive && time > 0) {
      timerInterval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (time === 0) {
      setIsActive(false);
    }

    return () => clearInterval(timerInterval);
  }, [isActive, time, setIsActive, setTime]);

  const resetTimer = async (e) => {
    e.preventDefault();
    const userData = JSON.stringify({
      mobile_number: mobileNumber,
      country_id: "91",
      language_code: "en",
    });

    const encryptedData = encryptAES(userData);

    const formData = new FormData();
    formData.append("data", encryptedData);

    try {
      const url = process.env.REACT_APP_API_BASE_URL + "/users/generate_otp";
      const result = await loginApi(url, "POST", formData);
      const decryptedResponse = decryptAES(result.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        setTime(60);
        setIsActive(true);
        toast.success(JSON.parse(decryptedResponse).message);
      } else {
        toast.error(JSON.parse(decryptedResponse).message);
      }
    } catch (error) {
      console.error("Error in API call:", error);
    }
  };

  const handleApiCall = async () => {
    setIsLoading(true);
    setIsBtnDisabled(true);
    try {
      const url = process.env.REACT_APP_API_BASE_URL + "/users/verify_otp";

      const parameters = JSON.stringify(
        userId === true
          ? {
              mobile_otp: otp,
              mobile_number: mobileNumber,
              country_id: "91",
              temp_number: true,
            }
          : {
              mobile_otp: otp,
              mobile_number: mobileNumber,
              country_id: "91",
            }
      );

      const encryptedData = encryptAES(parameters);

      const formData = new FormData();
      formData.append("data", encryptedData);

      const result = await loginApi(url, "POST", formData);
      const decryptedResponse = decryptAES(result.data.response);

      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        if (userId === true) {
          localStorage.setItem(
            "accessToken",
            encryptAES(JSON.parse(decryptedResponse).access_token)
          );
          toast.success(JSON.parse(decryptedResponse).message);
          setIsLoading(false);
          setIsBtnDisabled(false);
          setVerifyOtp(false);
          dispatch(setFetchAllHisab(true));
        } else {
          if (JSON.parse(decryptedResponse).set_pin === 1) {
            localStorage.setItem(
              "auth_default_token",
              encryptAES(JSON.parse(decryptedResponse).access_token)
            );
            dispatch(setUserLoginData(JSON.parse(decryptedResponse)));
            dispatch(setUserMobileNumber(mobileNumber));
            navigate("/mpinloginprofile");
          } else {
            toast.success(JSON.parse(decryptedResponse).message);
            localStorage.setItem(
              "accessToken",
              encryptAES(JSON.parse(decryptedResponse).access_token)
            );
            dispatch(setUserLoginData(JSON.parse(decryptedResponse)));
            dispatch(setUserMobileNumber(mobileNumber));
            setIsLoading(false);
            setIsBtnDisabled(false);
            navigate("/");
          }
        }
      } else {
        setIsLoading(false);
        setIsBtnDisabled(false);
        toast.error(JSON.parse(decryptedResponse).message);
      }
    } catch (error) {
      console.error("Error in API call:", error);
      setIsLoading(false);
      setIsBtnDisabled(false);
    }
  };

  const onHandleClick = (e) => {
    e.preventDefault();
    handleApiCall();
  };

  // const handleKeyDown = (e) => {
  //   if (e.key === "Enter" && mobileNumber.length === 10) {
  //     e.preventDefault();
  //     handleApiCall();
  //   }
  // };

  const updateMarginRight = () => {
    if (window.innerWidth <= 320) {
      setDynamicWidth("37px");
      setDynamicheight("50px");
    } else if (window.innerWidth <= 375) {
      setDynamicWidth("43px");
      setDynamicheight("55px");
    } else if (window.innerWidth <= 425) {
      setDynamicWidth("50px");
      setDynamicheight("55px");
    } else {
      // setDynamicWidth("5%");
    }
  };

  useEffect(() => {
    updateMarginRight();

    window.addEventListener("resize", updateMarginRight);

    return () => window.removeEventListener("resize", updateMarginRight);
  }, [dynamicWidth, dynamicHeight]);

  return (
    <Box className="flex justify-center items-center mt-[30px] max-425:mt-[20px]">
      <Box className="w-[65%] max-1024:w-[75%] max-425:!w-[90%] max-h-[80%] h-full flex justify-center items-center  overflow-hidden pt-[50px] max-425:pt-[30px] pb-[88.33px] max-425:pb-[50px]">
        <Box className="max-381:!pl-[18px] max-381:!pr-[20px] max-381:!overflow-hidden z-[9999]">
          <Box>
            <Typography className="!font-bold !text-[20px] md:!text-[24px]">
              {language.verify_Number}
            </Typography>
            <Typography className="mt-[4.44px] text-[#8A8A8A] font-normal text-[12px]  md:!text-[14px]">
              {language.login_Otp_Sent} +91 {mobileNumber}{" "}
              <Typography
                component="span"
                className="cursor-pointer text-[#FFB300] font-normal text-[14px] md:!text-[14px]"
                onClick={() => setVerifyOtp(false)}
              >
                {language.edit}
              </Typography>
            </Typography>
          </Box>

          <Box className="mt-[10.56px] z-[9999] flex justify-center flex-col">
            <Otp
              setOtp={setOtp}
              otp={otp}
              inputsNum={6}
              width={dynamicWidth}
              height={dynamicHeight}
              marginRight={"7.13px"}
              fontSize={18}
              fontWeight={"600"}
            />
            <Box className="mt-[2px] ">
              {time === 0 ? (
                <Typography
                  onClick={resetTimer}
                  className="cursor-pointer text-[#F6B825] !font-medium !text-[14px]"
                >
                  {language.resend}
                </Typography>
              ) : (
              
                <span className="text-[14px] mt-0">Resend OTP in: <span className="text-[#F6B825] text-[14px]">{`00:${String(time).padStart(2, "0")}`}</span></span>
              )}
            </Box>

            <Box className="flex justify-center items-center">
              <button
                className={`${
                  otp.length >= 6
                    ? "bg-gradient-to-r from-[#FFD87A] to-[#F6B825]"
                    : "bg-[#FFD87A] opacity-[35%] text-gray-400"
                } font-medium text-[16px] w-[100%] h-[40.67px] rounded-md mt-2`}
                disabled={otp.length < 6 || isBtnDisabled}
                onClick={onHandleClick}
              >
                {isLoading === true ? (
                  <Loader width={20} height={20} color={"black"} />
                ) : (
                  language.verify_Proceed
                )}
              </button>
            </Box>
          </Box>

          <Typography className=" text-[#8A8A8A] !font-normal !text-[12px] flex  items-center ">
            {language.clicking_Accept}{" "}
            <span className="text-[#F6B825] cursor-pointer" onClick={() => window.open("https://desihisab.com/legal", "_blank")}>{" "}{language.terms}</span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default ChangeVerifyNumber;
