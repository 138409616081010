import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import searchImage from '../../images/hisabdetails/Search.png'
import { useSelector } from "react-redux";

const SearchBar = ({ onSearch, placeholder = "Search" }) => {
  const language = useSelector((state) => state.usersLoginData.allLanguage);  

  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    const newQuery = e.target.value;
    if (!isValidInput(newQuery)) {
      return;
    }
    setQuery(newQuery);
    if (onSearch) {
      onSearch(newQuery);
    }
  };

  const isValidInput = (value) => {
    const urlPattern = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/gi;
    return !urlPattern.test(value);
  };

  const handlePaste = (e) => {
    const pastedText = e.clipboardData.getData('text');
    if (!isValidInput(pastedText)) {
      e.preventDefault();
    }
  };

  return (
    <TextField
    value={query}
    onChange={handleInputChange}
    onPaste={handlePaste}
    placeholder={language.search_Text}
    fullWidth
    variant="outlined"
    sx={{
      "& .MuiOutlinedInput-root": {
        height: "40px", 
        borderRadius: "8px",
        background: "#fff",
        "& fieldset": {
          borderColor: "#e0e0e0",
        },
        "&:hover fieldset": {
          borderColor: "#bdbdbd",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#9e9e9e",
        },
      },
      "& .MuiInputBase-input": {
        height: "100%",
        fontSize: "16px",
        paddingBottom:"0px",
        paddingTop:'0px'
      },
      "& .MuiInputBase-input::placeholder": {
        fontSize: "16px", 
      },
    }}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start" className="mr-[14px] pb-0">
          <img src={searchImage} alt="search" className="h-[16px] w-[16px]" />
        </InputAdornment>
      ),
    }}
  />
  );
};

export default SearchBar;