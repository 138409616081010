import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { QRCodeSVG } from "qrcode.react";
import BackArrow from "../images/hisabdetails/LeftArrow.png";
import { useNavigate } from "react-router-dom";
import DesiHisabLogo from "../images/sideBar/DesiHisabLogo.png";

const Invite = () => {
  const [assign, setAssign] = useState();
  const userData = useSelector((state) => state.usersLoginData.getUser);
  const QrLink = `${process.env.REACT_APP_QR_LINK}REFNO`;
  const navigate = useNavigate();

  const referralCode = userData?.referral_code;
  const link = QrLink.toString().replace("{REFNO}", referralCode);
  const language = useSelector((state) => state.usersLoginData.allLanguage);  

  useEffect(() => {
    setAssign(link);
  }, [link]);

  const InviteLink = () => {
    const inviteUrl = QrLink.toString().replace("{REFNO}", referralCode);
    window.open(inviteUrl, "_blank")
    // window.location.href = inviteUrl;
  };

  return (
    <div>
      <Box className="flex border-b">
        <button
          className="p-2 max-480:w-[46px]"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img alt="backImage" src={BackArrow} />
        </button>
        <h1 className="flex justify-center items-center p-2 text-[20px] font-medium max-480:text-[16px]">
          {language.qr_code}
        </h1>
      </Box>
      <Box className="flex flex-col justify-center items-center">
        <Box className="p-8">
          <QRCodeSVG
            value={assign}
            size={180}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            imageSettings={{
              src: DesiHisabLogo,
              x: undefined,
              y: undefined,
              height: 45,
              width: 45,
              opacity: 1,
              excavate: false,
            }}
          />
        </Box>
        <Box className=" w-[280px]">
          <Button
            onClick={InviteLink}
            className="!w-[100%] h-[42px] md:h-[40px] rounded-[5px]  text-[12px] md:text-[18px] bg-gradient-to-r from-[#FFD87A] to-[#F6B825] !text-black !font-semibold"
          >
            {language.invite}
          </Button>
        </Box>
      </Box>

    </div>
  );
};

export default Invite;
