import { createSlice } from "@reduxjs/toolkit";

const userDataSlice = createSlice({
  name: "user",
  initialState: {
    loginUserDetails: {},
    getUser: {},
    useMobileNumber: "",
    fetchAllHisab: false,
    selectedHisabId: null,
    autoOpenHisab: false,
    allLanguage: {},
    transactionDetails: {},
    notificationLength:'',
    firstId :'',
    transactionId: null,
  },
  reducers: {
    setUserLoginData: (state, action) => {
      state.loginUserDetails = action.payload;
    },
    setUserMobileNumber: (state, action) => {
      state.useMobileNumber = action.payload;
    },
    setGetUser: (state, action) => {
      state.getUser = action.payload;
    },
    setFetchAllHisab: (state, action) => {
      state.fetchAllHisab = action.payload;
    },
    setSelectedHisabId: (state, action) => {
      state.selectedHisabId = action.payload;
    },
    setAutoOpenHisab: (state, action) => {
      state.autoOpenHisab = action.payload;
    },
    setLanguage: (state, action) => {
      state.allLanguage = action.payload;
      localStorage.setItem("persist:userData_Desihisab", JSON.stringify(state));
    },
    setTransactionDetails: (state, action) => {
      state.transactionDetails = action.payload;
    },
    removeUserData: (state) => {
      state.loginUserDetails = {};
      state.useMobileNumber = "";
      state.getUser = {};
      state.fetchAllHisab = false;
      
      const persistedData = JSON.parse(localStorage.getItem("persist:userData_Desihisab"));
      if (persistedData) {
        delete persistedData.loginUserDetails;
        delete persistedData.useMobileNumber;
        delete persistedData.getUser;
        delete persistedData.fetchAllHisab;

        localStorage.setItem("persist:userData_Desihisab", JSON.stringify(persistedData));
      }
    },
    setNotificationLength: (state, action) => {
      state.notificationLength = action.payload;
    },
    setFirstId: (state, action) => {
      state.firstId = action.payload;
    },
    setTransactionId: (state, action) => {
      state.transactionId = action.payload
    }
  },
});

export const {
  setUserLoginData,
  setUserMobileNumber,
  setFetchAllHisab,
  setGetUser,
  setSelectedHisabId,
  setAutoOpenHisab,
  removeUserData,
  setLanguage,
  setTransactionDetails,
  setNotificationLength,
  setFirstId,
  setTransactionId
} = userDataSlice.actions;
export default userDataSlice.reducer;
