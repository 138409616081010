import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./pages/loginPage/Login";
import VerifyPinOtp from "./pages/loginPage/mPinSecurity/forgotMpinSection/VerifyPinOtp";
import TransactionDetails from "./pages/TransactionDetails";
import {
  MpinProfileRoute,
  PrivateRoute,
  PublicRoute,
  PublicRoutes,
} from "./components/PrivateRoute/PrivateRoute";
import MpinProfile from "./pages/loginPage/mPinSecurity/MpinProfile";
import MpinLogin from "./pages/loginPage/mPinSecurity/MpinLogin";
import AddHisab from "./pages/AddHisab";
import MyProfile from "./pages/MyProfile";
import NewSetMpin from "./pages/NewSetMpin";
import CurrentMpin from "./pages/CurrentMpin";
import ChangeMobileNumber from "./pages/ChangeMobileNumber";
import ViewTransaction from "./pages/ViewTransaction";
import ViewAllTransaction from "./pages/ViewAllTransaction";
import AddIncome from "./pages/AddIncome";
import ReportIssue from "./pages/ReportIssue";
import Faq from "./pages/Faq";
import ContactUs from "./pages/ContactUs";
import DeleteAccount from "./pages/DeleteAccount";
import Invite from "./pages/Invite";
import Language from "./pages/Language";
import Details from "./pages/Details";
import HisabPdf from "./pages/HisabPdf";
import ReportHisab from "./pages/ReportHisab";
import ErrorPage from "./pages/ErrorPage";
import MockUP from "./UI/MockUP";
import SupportTickets from "./pages/SupportTickets";
import SupportChat from "./pages/SupportChat";

function App() {
  return (
    <div>
      <Routes>
        {/* Private Routes (authenticated routes) */}
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Home />}>
            <Route path="AllHisab/:id=" element={<TransactionDetails />} />
            <Route path="AddHisab" element={<AddHisab />} />
            <Route path="DeletedHisab/:id=" element={<TransactionDetails />} />
            <Route path="account/:id=" element={<TransactionDetails />} />
            <Route path="/profile" element={<MyProfile />} />
            <Route path="/newpinset" element={<NewSetMpin />} />
            <Route path="/currentmpin" element={<CurrentMpin />} />
            <Route path="/numberchange" element={<ChangeMobileNumber />} />
            <Route path="ViewTransaction/:id" element={<ViewTransaction />} />
            <Route path="ViewAllTransaction/:id" element={<ViewAllTransaction/>} />
            <Route path="EditTransaction/:transactionId" element={<AddIncome/>} />
            <Route
              path="AddIncome/:hisabId/:hisabType"
              element={<AddIncome />}
            />
            <Route path="HelpCenter/:id=" element={<TransactionDetails />} />
            <Route path="/ReportIssue" element={<ReportIssue />} />
            <Route path="/FAQ" element={<Faq />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/Referal" element={<Invite />} />
            <Route path="/deleteaccount" element={<DeleteAccount />} />
            <Route path="/language" element={<Language />} />
            <Route path="Notification/:id=" element={<TransactionDetails/>} />
            <Route path="/Details/:id" element={<Details />} />
            <Route path="/hisab/pdf/:id" element={<HisabPdf />} />
            <Route path="/ReportHisab/:id" element={<ReportHisab />} />
            <Route path="/EditHisab/:id" element={<AddHisab />} />
            <Route path="/supportTickets" element={<SupportTickets />} />
            <Route path="/supportChat/:id" element={<SupportChat />} />
          </Route>
          <Route path="Error" element={<ErrorPage />}></Route>
        </Route>

        {/* Public Routes for MPIN Profile (requires accessTokenm) */}
        <Route element={<MpinProfileRoute />}>
          <Route path="/mpinloginprofile" element={<MpinProfile />} />
          <Route path="/forgotpin" element={<VerifyPinOtp />} />
          <Route path="/mpinlogin" element={<MpinLogin />} />
        </Route>

        {/* Public Routes for other pages (requires accessToken) */}
        <Route element={<PublicRoute />}>
          <Route path="/login" element={<Login />} />
        </Route>
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
