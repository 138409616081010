import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decryptAES, encryptAES } from "../CommonLibrary";
import { apiService } from "../../app/services/api";
import { toast } from "react-toastify";
import CloseIcon from "../../images/Income/close-square.png";
import List from "react-virtualized/dist/commonjs/List";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import Loader from "../Loader/Loader";

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useParams } from "react-router-dom";
import SearchBar from "../Search";
import { debounce } from "lodash";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ContactModal = memo(
  ({
    open,
    setAddParticipantOpen,
    AddHisab = false,
    viewParticipantsFromAdd = {},
    setViewParticipantsFromAdd,
    editParticipantsFromAdd = {},
    setEditParticipantsFromAdd,
  }) => {
    const { id } = useParams();
    let decryptedID;
    if (id) {
      decryptedID = decryptAES(id);
    }
    const urlObject = JSON.parse(decryptedID ? decryptedID : null);
    const number = urlObject?.id;
    //  //

    const [isLoading, setIsLoading] = useState(false);
    const [contactData, setContactData] = useState([]);
   
    const [selectedValue, setSelectedValue] = useState(null);
    const [usersWithIds, setUsersWithIds] = useState([]);
    const [usersWithoutIds, setUsersWithoutIds] = useState([]);
    const [viewParticipants, setViewParticipants] = useState(new Set());
    const [editParticipants, setEditParticipants] = useState(new Set());
    const [participationType, setParticipationType] = useState("view");
    const [checkWithoutIDs , setCheckWithoutIDs] = useState(true);
    const [query, setQuery] = useState("");
    //////
    const userLoginData = useSelector((state) => state.usersLoginData.getUser);
    const language = useSelector((state) => state.usersLoginData.allLanguage);
    const deleteParticipant = useSelector(
      (state) => state.usersLoginData.deleteParticipant
    );
    const mergedArray = usersWithIds.concat(usersWithoutIds);
    const dispatch = useDispatch();
    const [searchData, setSearchData] = useState(usersWithIds.concat(usersWithoutIds));
    //////////
    const getDetails = useCallback(async () => {
      setIsLoading(true);
      try {
        const tokenUrl = localStorage.getItem("accessToken");
        const url =
          process.env.REACT_APP_API_BASE_URL + "/user/get_contact_user";
        const body = {
          transaction_id: userLoginData?.id,
          search_by: "",
        };
        if (query.length > 0) {
          body.search_by = query;
        }
        const encryptedData = encryptAES(JSON.stringify(body));
        const formData = new FormData();
        formData.append("data", encryptedData);
        const res = await apiService(url, "POST", formData, tokenUrl);
        const decryptedResponse = decryptAES(res.data.response);
        if (
          JSON.parse(decryptedResponse).status === 200 ||
          JSON.parse(decryptedResponse).status === "200"
        ) {
          const data = await JSON.parse(decryptedResponse);
          const updatedUsersData = data.users_data.filter(item => item.mobile_number !== userLoginData.mobile_number);
          setContactData(updatedUsersData);
          setSearchData(data.users_data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error("error", error);
        setIsLoading(false);
      }
    }, [contactData]);

    const handelCheckChange = (e = null, item) => {
      if (AddHisab) {
        if (e !== null && e?.target?.checked) {
          if (participationType === "view") {
            setViewParticipantsFromAdd(
              (prevState) => new Set([...prevState, item])
            );
          } else {
            setEditParticipantsFromAdd(
              (prevState) => new Set([...prevState, item])
            );
          }
        } else {
          setViewParticipantsFromAdd((prevState) => {
            const newSet = new Set(prevState);
            if (newSet.has(item)) {
              newSet.delete(item);
            }
            return newSet;
          });
          setEditParticipantsFromAdd((prevState) => {
            const newSet = new Set(prevState);
            if (newSet.has(item)) {
              newSet.delete(item);
            }
            return newSet;
          });
        }
      } else {
        if (e !== null && e?.target?.checked) {
          if (participationType === "view") {
            setViewParticipants((prevState) => new Set([...prevState, item]));
          } else {
            setEditParticipants((prevState) => new Set([...prevState, item]));
          }
        } else {
          setViewParticipants((prevState) => {
            const newSet = new Set(prevState);
            if (newSet.has(item)) {
              newSet.delete(item);
            }
            return newSet;
          });
          setEditParticipants((prevState) => {
            const newSet = new Set(prevState);
            if (newSet.has(item)) {
              newSet.delete(item);
            }
            return newSet;
          });
        }
      }
    };

    useEffect(() => {
      const searchResult = contactData.filter(
        (item) =>
          item.name.toLowerCase().includes(query.toLowerCase()) ||
          item.mobile_number.includes(query)
      );
      const sortedResult = searchResult.sort((a, b) => {
        if (a.user_id?.length>0 && b.user_id?.length==0) return -1;
        if (a.user_id?.length==0 && b.user_id?.length>0) return 1; 
        return 0; 
      });
      setSearchData(sortedResult);
    }, [query]);

    function handelSelectChange(e) {
      setParticipationType(e.target.value);
    }
    async function handelAddParticipant() {
      try {
        const tokenUrl = localStorage.getItem("accessToken");
        const url =
          process.env.REACT_APP_API_BASE_URL + "/participant/add_participant";
        const edit = Array.from(editParticipants)
          .map((participant) => `${participant.user_id}`)
          .join(", ");
        const view = Array.from(viewParticipants)
          .map((participant) => `${participant.user_id}`)
          .join(", ");
        const body = {
          hisab_id: number,
          participant_status: "active",
          edit_participant_user_id: edit,
          view_participant_user_id: view,
        };
        const encryptedData = encryptAES(JSON.stringify(body));
        const formData = new FormData();
        formData.append("data", encryptedData);
        const res = await apiService(url, "POST", formData, tokenUrl);

        const decryptedResponse = decryptAES(res?.data?.response);
        if (
          JSON.parse(decryptedResponse).status === 200 ||
          JSON.parse(decryptedResponse).status === "200"
        ) {
          const data = await JSON.parse(decryptedResponse);
          setEditParticipants(new Set());
          setViewParticipants(new Set());
          setAddParticipantOpen(false);
          toast.success(data.message);
          setQuery("");
        } else {
          toast.error(JSON.parse(decryptedResponse).message);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("error", error);
        setIsLoading(false);
      }
    }
    //
    useEffect(() => {
      getDetails();
    }, []);

    const debouncedSearch = debounce((searchQuery) => {
      setQuery(searchQuery.trim());
    }, 500);

    function renderContact({ index, key, style }) {
      const item = (query? searchData : mergedArray)[index];  
      if (!isLoading && item) {
        if (item.user_id == '' && (query ? searchData[index - 1]?.user_id: mergedArray[index - 1]?.user_id)) {
          setCheckWithoutIDs(false)
          return (
            <div style={style}>
               <h2 className="text-[#848282] text-[14px] border-b border-solid">
                Invite to Desi Hisab
              </h2>
            </div>
          );
        }
        return (
          <Grid key={key} style={style} container className="flex items-center">
            <Grid item size={2}>
              <Avatar
                src={`${process.env.REACT_APP_AWS_LINK}/uploads/user/${item?.user_id}/${item?.profile_photo}`}
                className="max-680:w-[30px] max-680:h-[30px]"
              />
            </Grid>
            <Grid item size={6}>
              <Box className="flex flex-col justify-end">
                <Typography className="text-[13px]">{item.name}</Typography>
                <Typography className="text-[13px]">{item.mobile_number}</Typography>
              </Box>
            </Grid>
            <Grid item size={4} className="flex justify-end">
              {item.user_id ? (
                <Checkbox
                  sx={{
                    color: "#F6B825",
                    "&.Mui-checked": {
                      color: "#F6B825",
                    },
                  }}
                  checked={
                    !AddHisab
                      ? viewParticipants.has(item) || editParticipants.has(item)
                      : viewParticipantsFromAdd.has(item) ||
                        editParticipantsFromAdd.has(item)
                  }
                  onChange={(e) => handelCheckChange(e, item)}
                  {...label}
                />
              ) : (
                <Button className="text-yellow-500 capitalize" onClick={()=>{handleInvite(item.mobile_number)}}>Invite</Button>
              )}
            </Grid>
          </Grid>
        );
      }
    }
    
    function handelAddParticipantNewHisab() {
      setAddParticipantOpen(false);
    }
    async function handleInvite (number){
      try {
            const tokenUrl = localStorage.getItem("accessToken");
            const url =
              process.env.REACT_APP_API_BASE_URL + "/invite/self_invite";
            const body = {
              mobile_number: number,
              referral_code: userLoginData?.referral_code,
            };
            const encryptedData = encryptAES(JSON.stringify(body));
            const formData = new FormData();
            formData.append("data", encryptedData);
            const res = await apiService(url, "POST", formData, tokenUrl);
            const decryptedResponse = decryptAES(res.data.response);
            if (
              JSON.parse(decryptedResponse).status === 200 ||
              JSON.parse(decryptedResponse).status === "200"
            ) {
              toast.success(JSON.parse(decryptedResponse).message);
              setIsLoading(false);
              setAddParticipantOpen(false);
              const data = await JSON.parse(decryptedResponse);      
            } else {
              toast.error(JSON.parse(decryptedResponse).message);
              setIsLoading(false);
            }
          } catch (error) {
            console.error("error", error);
            setIsLoading(false);
          }

    }

    const handleDlgClose = (event, reason) => {
      if (reason && reason == "backdropClick") {
        return;
      }
        setAddParticipantOpen(false)
    }
      
    useEffect(()=>{
      contactData.forEach(user => {
        if (user.user_id?.length>0) {
          setUsersWithIds(prev => [...prev, user]);
        } else {
          setUsersWithoutIds(prev => [...prev, user]);
        }
      });
    },[contactData])

    return (
      <>
        {contactData.length === 0 && !isLoading ? (
          <Dialog
            open={open}
            keepMounted
            onClose={() => {
              setAddParticipantOpen(false);
              setQuery("");
            }}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
              sx: {
                width: window.innerWidth > 768 ? "25%" : "90%",
                height: "fit-content",
                display: "flex",
                flexDirection: "column",
                alignItems: "center", // Centers the children horizontally
              },
            }}
          >
            <h2 className="p-4 text-center pb-0">
              Please login in application to sync your contacts
            </h2>
            <Button
              onClick={() => {
                setAddParticipantOpen(false);
              }}
              className=" w-[30%] h-8 bg-gradient-to-r text-[12px] from-[#FFD87A] via-[#F6B825] to-[#F6B825] !text-black rounded-3xl max-680:text-[10px] max-680:h-7 m-4"
            >
              OK
            </Button>
          </Dialog>
        ) : (
          <Dialog
            open={open}
            onClose={handleDlgClose}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
              sx: {
                width: window.innerWidth > 768 ? "30%" : "90%",
                height: "90%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center", // Centers the children horizontally
                borderRadius: "20px",
              },
            }}
          >
            <Grid container className="p-3 w-full">
              <Grid item size={4}>
                <Button
                  onClick={() => {
                    setAddParticipantOpen(false);
                    setQuery("");
                  }}
                  className="text-black text-[12px] max-680:text-[10px] !capitalize"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item size={4}>
                <Box className="flex flex-col justify-center">
                  <Typography className="text-center max-680:text-[10px]">
                    {language.add_Participant}
                  </Typography>
                  <Typography className="text-center max-680:text-[10px]">
                    {" "}
                    {viewParticipants.size + editParticipants.size} /{" "}
                    {contactData.length}
                  </Typography>
                </Box>
              </Grid>
              <Grid item size={4} className="flex justify-end">
                <Button
                  className={`h-6 bg-gradient-to-r text-[12px] from-[#FFD87A] via-[#F6B825] to-[#F6B825] !text-black rounded-3xl max-680:text-[10px] max-680:h-7 !capitalize ${!AddHisab ? editParticipants.size===0 && viewParticipants.size === 0 : editParticipantsFromAdd.size===0 && viewParticipantsFromAdd.size === 0? "blur-[0.5px] opacity-[0.4]" : ""}`}
                  onClick={
                    AddHisab
                      ? handelAddParticipantNewHisab
                      : handelAddParticipant
                  }
                  disabled={!AddHisab ? editParticipants.size===0 && viewParticipants.size === 0 : editParticipantsFromAdd.size===0 && viewParticipantsFromAdd.size === 0}
                >
                  {language.next}
                </Button>
              </Grid>
            </Grid>
            <Box className="w-[90%]">
              <SearchBar onSearch={debouncedSearch} />
            </Box>
            <FormControl className="w-[90%] mt-4 flex" sx={{ minWidth: 200 }}>
              <InputLabel size="small">
                Grant Permission on this hisab
              </InputLabel>
              <Select
                value={participationType}
                onChange={handelSelectChange}
                size="small"
                label="Grant Permission on this hisab"
              >
                <MenuItem value="view">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {language.view_hisab_appbar}
                  </div>
                </MenuItem>
                <MenuItem value="edit">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {language.edit_hisab_appbar}
                  </div>
                </MenuItem>
              </Select>
            </FormControl>
            <Box className="w-full pl-5 mt-2">
              {(AddHisab
                ? viewParticipantsFromAdd.size > 0
                : viewParticipants.size > 0) && (
                <div className="w-full">
                  <h1 className="text-[12px] text-[#848282]">View Hisab</h1>
                  <div className="flex pl-3 max-w-[98%] overflow-auto horizontal-scrollbar">
                    {(AddHisab
                      ? [...viewParticipantsFromAdd]
                      : [...viewParticipants]
                    ).map((item) => (
                      <div className="!relative flex-shrink-0 mr-[20px] flex flex-col items-center">
                        <Avatar
                          className="w-[40px] h-[40px] "
                          src={`${process.env.REACT_APP_AWS_LINK}/uploads/user/${item?.user_id}/${item?.profile_photo}`}
                          alt={`preview-${item}`}
                        />
                        <button
                          className="!absolute !top-0 !right-0 !z-10 !w-[20px] !justify-end"
                          onClick={() => {
                            handelCheckChange(null, item);
                          }}
                        >
                          <img
                            src={CloseIcon}
                            alt="close"
                            className="w-[18px] h-[18px]"
                          />
                        </button>
                        <Typography className="text-[12px] text-center">
                          {item.name}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {(AddHisab
                ? editParticipantsFromAdd.size > 0
                : editParticipants.size > 0) && (
                <div className="w-full mt-2">
                  <h1 className="text-[12px] text-[#848282]">Edit Hisab</h1>
                  <div className="flex pl-3 max-w-[98%] overflow-auto horizontal-scrollbar">
                    {(AddHisab
                      ? [...editParticipantsFromAdd]
                      : [...editParticipants]
                    ).map((item) => (
                      <div className="!relative flex-shrink-0 mr-[20px] flex flex-col items-center">
                        <Avatar
                          className="w-[40px] h-[40px] "
                          src={`${process.env.REACT_APP_AWS_LINK}/uploads/user/${item?.user_id}/${item?.profile_photo}`}
                          alt={`preview-${item}`}
                        />
                        <button
                          className="!absolute !top-0 !right-0 !z-10 !w-[20px] !justify-end"
                          onClick={() => {
                            handelCheckChange(null, item);
                          }}
                        >
                          <img
                            src={CloseIcon}
                            alt="close"
                            className="w-[18px] h-[18px]"
                          />
                        </button>
                        <Typography className="text-[12px] text-center">
                          {item.name}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Box>
            <Box
              className={`max-h-[100%] overflow-auto p-5 pt-2 flex flex-col gap-4 w-full 
          max-680:p-3 h-[100%] pb-0 relative notification-scrollbar ${true ? 'reduced-padding' : ''}`}
            >
              <h2 className="text-[#848282] text-[14px] border-b border-solid">
                Contact on Desi Hisab
              </h2>
              {isLoading ? (
                <div className="flex items-center !justify-center !w-[96] !h-[60vh]">
                  <Loader width={40} height={40} color={"black"} />
                </div>
              ) : (
                <div className="h-full">
                <div style={{ display: 'flex', flexDirection: 'column', height:'100%' }}>
                  {/* First List (Users with IDs) */}
                  <AutoSizer>
                    {({ width, height }) => (
                      <List
                        width={width}
                        height={height}
                        rowHeight={50}
                        rowRenderer={renderContact}
                        rowCount={(query ? searchData.length : mergedArray.length)}
                        overscanRowCount={5}
                        className="notification-scrollbar"
                      />
                    )}
                  </AutoSizer>
                  </div>
                
              </div>
              
              )}
            </Box>
          </Dialog>
        )}
      </>
    );
  }
);
export default ContactModal;
