import { memo } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import Loader from "../components/Loader/ButtonLoader";
import logoIcon from "../images/loginpage/logo.png";
import Verifyotp from "../pages/loginPage/Verifyotp";
import deleteImage from "../images/loginpage/danger_1.png";
import CustomModal from "../components/CommonModal/CustomModal";
import { Box, Button, TextField, Typography } from "@mui/material";

const textFieldStyles = {
  border: "none",
  fontSize: "14px",
  "& fieldset": { border: "none" },
  padding: "0px 0px",
  "& .MuiInputBase-root": {
    paddingBottom: "3px !important",
  },
  "& .MuiInputBase-input": {
    fontSize: '16px',
    paddingTop: "6px",
    padding: "0rem 0rem",
  },
  "& .MuiInputBase-input::placeholder": {
    opacity: "50%",
    fontSize: "12px",
  },
};

const buttonGradient = (isEnabled) => ({
  background: isEnabled
    ? "linear-gradient(45deg, #EDB61E, #FFA500), linear-gradient(135.53deg, #FFD87A 2.61%, #F6B825 96.58%)"
    : "linear-gradient(0deg, #EDB61E, #EDB61E), linear-gradient(135.53deg, #FFD87A 2.61%, #F6B825 96.58%)",
  color: "#000",
  opacity: isEnabled ? 1 : 0.5,
});

const ModalContent = memo(({ isSuccessMessage, setOpen, setVerifyOtp }) => (
  <div className="flex justify-center items-center flex-col h-full">
    <img src={deleteImage} alt="Warning" className="w-[120px] h-auto" />
    <p className="text-center font-semibold text-[20px]">{isSuccessMessage}</p>
    <div className="flex mt-[15px]">
      <button
        onClick={() => setOpen(false)}
        className="rounded-md px-14 max-425:px-11 max-320:!px-8 py-2 font-semibold text-lg mr-16 max-425:mr-5"
        style={{ border: "1px solid black" }}
      >
        No
      </button>
      <button
        onClick={() => setVerifyOtp(true)}
        className="bg-yellow-400 rounded-md px-14 max-425:px-11 max-320:!px-8 py-2 font-semibold text-lg"
      >
        Yes
      </button>
    </div>
  </div>
));

const LoginForm = memo(({ 
  mobileNumber, 
  handleMobileChange, 
  handleKeyDown, 
  onLogin, 
  isLoading, 
  isBtnDisabled,
  language 
}) => (
  <Box component="form" className="!mt-[12.92px] gap-[2px]">
    <Box className="flex items-center w-[100%] h-[40px] rounded-md border-[#F1F1F1] border-[1px] !z-50">
      <Box className="flex justify-center items-center !z-50 mr-[px]">
        <Typography className="!ml-[4px] font-normal !text-[17px]">+91</Typography>
        <Box
          borderLeft="1px solid #EEF0F2"
          width="2px"
          height="40px"
          margin="0px 9px 0px 9px"
        />
      </Box>
      <TextField
        id="outlined-basic"
        autoFocus
        sx={textFieldStyles}
        placeholder={language.mobile_Number}
        variant="outlined"
        size="small"
        value={mobileNumber}
        onChange={handleMobileChange}
        onKeyDown={handleKeyDown}
        slotProps={{
          maxLength: 10,
          pattern: "[0-9]*",
        }}
        className="!z-50 !w-[83%]"
      />
    </Box>

    <Button
      fullWidth
      sx={buttonGradient(mobileNumber.length >= 10)}
      onClick={onLogin}
      disabled={mobileNumber.length !== 10 || isBtnDisabled}
      className="z-50 h-[39.67px] md:h-[39.67px] font-medium !text-[16px] !mt-[25.85px] !capitalize"
    >
      {isLoading ? <Loader width={20} height={20} color="black" /> : language.login}
    </Button>
  </Box>
));

function RightBar({
  verifyOtp,
  mobileNumber,
  setVerifyOtp,
  time,
  setTime,
  isActive,
  setIsActive,
  handleMobileChange,
  handleKeyDown,
  onLogin,
  isLoading,
  isBtnDisabled,
  isSuccessMessage,
  setOpen,
  open,
}) {
  const language = useSelector((state) => state.usersLoginData.allLanguage);

  return (
    <Box className="pt-[28px]">
      <Box className="flex justify-center items-center mt-[61px]">
        <img
          src={logoIcon}
          alt="Logo"
          className="w-[160px] h-[36.45px], z-[1px]"
        />
      </Box>

      {verifyOtp ? (
        <Box className="md:!px-[111px] px-[3px] !flex !justify-center !items-center">
          <Verifyotp
            mobileNumber={mobileNumber}
            setVerifyOtp={setVerifyOtp}
            time={time}
            isActive={isActive}
            setTime={setTime}
            setIsActive={setIsActive}
          />
        </Box>
      ) : (
        <Box className="px-[20.25px] md:px-[121.25px] max-1030:px-[45.25px]">
          <Box className="!mt-[77.37px]">
            <motion.div initial="initial" animate="animate">
              <Typography variant="h5" className="font-bold text-[12px] md:!text-[20px]">
                {language.welcome_Ask}
              </Typography>
              <Typography className="text-[#8A8A8A] font-normal !text-[13px] md:!text-[14px]">
                {language.login_subtitle}
              </Typography>
            </motion.div>
          </Box>

          <LoginForm
            mobileNumber={mobileNumber}
            handleMobileChange={handleMobileChange}
            handleKeyDown={handleKeyDown}
            onLogin={onLogin}
            isLoading={isLoading}
            isBtnDisabled={isBtnDisabled}
            language={language}
          />

          <CustomModal
            open={open}
            handleClose={() => setOpen(false)}
            isCloseIcon={false}
            width="580px"
            height="300px"
          >
            <ModalContent 
              isSuccessMessage={isSuccessMessage}
              setOpen={setOpen}
              setVerifyOtp={setVerifyOtp}
            />
          </CustomModal>
        </Box>
      )}
    </Box>
  );
}

export default memo(RightBar);