import React, { useEffect, useState, useCallback, } from "react";
import { toast } from "react-toastify";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Otp from "../../components/OtpInput/Otp";
import Loader from "../../UI/Loader";
import MpinLogin from "./mPinSecurity/MpinLogin";
import { loginApi } from "../../app/services/api";
import { decryptAES, encryptAES } from "../../components/CommonLibrary";
import {
  setFetchAllHisab,
  setUserLoginData,
  setSelectedHisabId,
  setUserMobileNumber,
  setAutoOpenHisab,
} from "../../app/Redux/reducer";

function Verifyotp({ mobileNumber, setVerifyOtp, time, setTime, isActive, setIsActive, userId }) {
  const [state, setState] = useState({
    otp: "",
    isLoading: false,
    isBtnDisabled: false,
    MPIN: false,
    dynamicWidth: "50px",
    dynamicHeight: "47px"
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.usersLoginData.allLanguage);

  const updateDimensions = useCallback(() => {
    const width = window.innerWidth;
    let dimensions = { width: "50px", height: "47px" };

    if (width <= 320) dimensions = { width: "39px", height: "49px" };
    else if (width <= 338) dimensions = { width: "42px", height: "50px" };
    else if (width <= 369) dimensions = { width: "45px", height: "55px" };
    else if (width <= 393) dimensions = { width: "49px", height: "55px" };
    else if (width <= 443) dimensions = { width: "57px", height: "74px" };
    else if (width <= 768) dimensions = { width: "63.01px", height: "74px" };
    else if (width <= 1059) dimensions = { width: "57px", height: "74px" };

    setState(prev => ({
      ...prev,
      dynamicWidth: dimensions.width,
      dynamicHeight: dimensions.height
    }));
  }, []);

  useEffect(() => {
    let timerInterval;
    
    if (isActive && time > 0) {
      timerInterval = setInterval(() => {
        setTime(time => {
          if (time <= 1) {
            clearInterval(timerInterval);
            setIsActive(false);
            return 0;
          }
          return time - 1;
        });
      }, 1000);
    }
  
    return () => {
      if (timerInterval) {
        clearInterval(timerInterval);
      }
    };
  }, [isActive, setTime, setIsActive]);

  const resetTimer = async (e) => {
    setState(prev => ({ ...prev, otp:""}));
    e.preventDefault();
    try {
      const userData = {
        mobile_number: mobileNumber,
        country_id: "91",
        language_code: "en"
      };

      const formData = new FormData();
      formData.append("data", encryptAES(JSON.stringify(userData)));

      const url = `${process.env.REACT_APP_API_BASE_URL}/users/generate_otp`;
      const result = await loginApi(url, "POST", formData);
      const response = JSON.parse(decryptAES(result.data.response));

      if (response.status === 200 || response.status === "200") {
        setTime(60);
        setIsActive(true);
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
      toast.error("Failed to resend OTP");
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setState(prev => ({ ...prev, isLoading: true, isBtnDisabled: true }));
    dispatch(setSelectedHisabId(0));
    dispatch(setAutoOpenHisab(true));

    try {
      const parameters = {
        mobile_otp: state.otp,
        mobile_number: mobileNumber,
        country_id: "91",
        ...(userId && { temp_number: true })
      };

      const formData = new FormData();
      formData.append("data", encryptAES(JSON.stringify(parameters)));

      const url = `${process.env.REACT_APP_API_BASE_URL}/users/verify_otp`;
      const result = await loginApi(url, "POST", formData);
      const response = JSON.parse(decryptAES(result.data.response));
      if (response.status === 200 || response.status === "200") {
        handleSuccessfulVerification(response);
      }else if (response.status == 204){
        toast.error(language.invalid_otp_text)
      }
       else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Verification failed:", error);
      toast.error("Verification failed");
    } finally {
      setState(prev => ({ ...prev, isLoading: false, isBtnDisabled: false }));
    }
  };

  const handleSuccessfulVerification = (response) => {
    if (userId) {
      localStorage.setItem("accessToken", encryptAES(response.access_token));
      toast.success(response.message);
      setVerifyOtp(false);
      dispatch(setFetchAllHisab(true));
    } else if (response.set_pin === 1) {
      localStorage.setItem("auth_default_token", encryptAES(response.access_token));
      dispatch(setUserLoginData(response));
      dispatch(setUserMobileNumber(mobileNumber));
      setState(prev => ({ ...prev, MPIN: true }));
    } else {
      localStorage.setItem("accessToken", encryptAES(response.access_token));
      dispatch(setUserLoginData(response));
      dispatch(setUserMobileNumber(mobileNumber));
       if (response.first_name.length === 0 && response.last_name.length === 0){
        navigate("/profile")
       }else{
        navigate("/AllHisab/:id=");
       }
    }
  };

  const handleKeyDown = useCallback((e) => {
    if (e.key === "Enter" && state.otp.length === 6) {
      e.preventDefault();
      handleVerifyOtp(e);
    }
    
    if (e.key === "Backspace" && !e.target.value) {
      e.preventDefault();
      const inputs = e.target.parentElement.getElementsByTagName("input");
      const currentIndex = Array.from(inputs).indexOf(e.target);
      if (currentIndex > 0) {
        inputs[currentIndex - 1].focus();
      }
    }
  }, [state.otp, handleVerifyOtp]);

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [updateDimensions]);

  if (state.MPIN) return <MpinLogin />;

  return (
    <Box className="mt-[76.78px] max-381:!pl-[18px] max-381:!pr-[20px] max-381:!overflow-hidden z-[9999]">
      <Typography className="!font-bold !text-[18px] md:!text-[20px]">
        {language.verify_Number}
      </Typography>
      
      <Typography className="text-[#8A8A8A] font-normal text-[12px] md:!text-[12px]">
        {language.login_Otp_Sent} +91 {mobileNumber}{" "}
        <Typography 
          component="span" 
          className="cursor-pointer text-[#FFB300] font-normal text-[12px] md:!text-[12px]"
          onClick={() => {setVerifyOtp(false); setTime(60)}}
        >
          {language.edit}
        </Typography>
      </Typography>

      <Box className="mt-[20.56px] z-[9999]">
        <Otp
          setOtp={(otp) => setState(prev => ({ ...prev, otp }))}
          otp={state.otp}
          inputsNum={6}
          width={state.dynamicWidth}
          height={state.dynamicHeight}
          marginRight="7.13px"
          fontSize={18}
          fontWeight="600"
          onKeyDown={handleKeyDown}
        />
      </Box>

      <Box className="mt-[10px]">
        {time === 0 ? (
          <Typography 
            onClick={resetTimer}
            className="cursor-pointer text-[#F6B825] !font-medium !text-[12px]"
          >
            {language.resend}
          </Typography>
        ) : (
          <Typography variant="h6" fontWeight="normal" className="text-[14px]">
            <span>Resend OTP in: <span className="text-[#F6B825]">{`00:${String(time).padStart(2, "0")}`}</span></span>
          </Typography>
        )}
      </Box>

      <Button
        className="!mt-[8.79px] w-[337.49px] max-424:!w-[100%] h-[40.67px] !capitalize !font-medium !text-[14px] !z-50"
        style={{
          background:
            state.otp.length >= 6
              ? "linear-gradient(45deg, #EDB61E, #FFA500), linear-gradient(135.53deg, #FFD87A 2.61%, #F6B825 96.58%)"
              : "linear-gradient(0deg, #EDB61E, #EDB61E), linear-gradient(135.53deg, #FFD87A 2.61%, #F6B825 96.58%)",
          color:"#000", // Apply a lighter/disabled color when inactive
          opacity: state.otp.length >= 6 ? 1 : 0.6, // Adjust opacity for disabled effect
        }}
        disabled={state.otp.length < 6 || state.isBtnDisabled}
        onClick={handleVerifyOtp}
        
      >
        {state.isLoading ? (
          <Loader width={20} height={20} color="black" />
        ) : (
          language.verify_Proceed
        )}
      </Button>

      <Typography className="text-[#8A8A8A] !font-normal !text-[12px] md:flex items-center">
        {language.clicking_Accept}
        <span 
          className="text-[#F6B825] !ml-1 text-[12px] cursor-pointer"
          onClick={() => window.open("https://desihisab.com/legal", "_blank")}
        >
          {language.terms}
        </span>
      </Typography>
    </Box>
  );
}

export default React.memo(Verifyotp);