import Loader from "../UI/Loader";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { apiService } from "../app/services/api";
import { Box, Button, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useCallback, memo } from "react";
import BackArrow from "../images/hisabdetails/LeftArrow.png";
import { decryptAES, encryptAES } from "../components/CommonLibrary";

const MAX_DESCRIPTION_LENGTH = 250;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TextFieldStyles = {
  root: {
    position: "relative",
    marginTop: 2,
  },
  input: {
    "& .MuiInputLabel-root": { color: "gray" },
    "& .MuiInputLabel-asterisk": { color: "red", fontSize: "16px" },
    "& .MuiOutlinedInput-root": {
      height: "150px",
    },
  },
  charCount: {
    position: "absolute",
    bottom: "10px",
    right: "10px",
    fontSize: "12px",
    color: "gray",
  },
};

const ReportHisab = () => {
  const { id } = useParams();
  let decryptData ;
  if(id){
     decryptData = decryptAES(id)
  };
  const urlObject = JSON.parse(decryptData ? decryptData : null);
  const hisabId = urlObject?.id;
  const navigate = useNavigate();
  const userLoginData = useSelector((state) => state.usersLoginData.getUser);

  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState("");

  const isSubmitDisabled = !description?.trim()?.length || isLoading;

  const handleSubmit = useCallback(async () => {
    if (isSubmitDisabled) return;

    setIsLoading(true);
    try {
      const tokenUrl = localStorage.getItem("accessToken");
      const body = {
        hisab_id: hisabId,
        description,
        user_id: userLoginData?.id,
      };

      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);

      const response = await apiService(
        `${API_BASE_URL}/hisab/add_report`,
        "POST",
        formData,
        tokenUrl
      );

      const decryptedResponse = JSON.parse(decryptAES(response.data.response));

      if (decryptedResponse.status === 200 || decryptedResponse.status === "200") {
        toast.success(decryptedResponse.message);
        navigate(-1);
        setDescription("");
      }
    } catch (error) {
      console.error("Report Hisab Error:", error);
      toast.error("Failed to submit report");
    } finally {
      setIsLoading(false);
    }
  }, [description, hisabId, userLoginData?.id, isSubmitDisabled]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className="w-[100%] flex flex-col justify-center items-center">
      <div className="w-[100%] px-5">
        <header className="flex gap-2">
          <button
            className="pt-3 max-480:w-[46px]"
            onClick={() => navigate(-1)}
            aria-label="Go back"
          >
            <img alt="Back" src={BackArrow} width={24} height={24} />
          </button>
          <h1 className="pt-3 text-[20px] font-medium max-480:text-[16px]">
            Report Hisab
          </h1>
        </header>

        <Box sx={TextFieldStyles.root}>
          <TextField
            id="description"
            label="Description"
            variant="outlined"
            fullWidth
            required
            multiline
            rows={6}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            onKeyDown={handleKeyDown}
            inputProps={{
              maxLength: MAX_DESCRIPTION_LENGTH,
            }}
            sx={TextFieldStyles.input}
          />
          <Box sx={TextFieldStyles.charCount}>
            {description?.length}/{MAX_DESCRIPTION_LENGTH}
          </Box>
        </Box>

        <Button
          className={`w-full !mt-5 h-[58px] rounded-[5px] text-[18px] font-medium ${
            !isSubmitDisabled
              ? "bg-gradient-to-r from-[#FFD87A] to-[#F6B825] !text-black !font-semibold"
              : "!bg-[#e2b54b] opacity-75 !text-black !font-semibold"
          }`}
          disabled={isSubmitDisabled}
          onClick={handleSubmit}
        >
          {isLoading ? <Loader width={20} height={20} color="black" /> : "Submit"}
        </Button>
      </div>
    </div>
  );
};

export default memo(ReportHisab);