import profileicon from "../images/sideBar/profile1.png";
import mpinicon from "../images/myaccount/pin-code 1.png";
import phoneicon from "../images/myaccount/telephone 1.png";
import deleteIcon from "../images/sideBar/trash.png";
import languageIcon from "../images/myaccount/translate.png";


export const ITEMS_PER_PAGE = window.innerWidth < 768 ? 20 : 30;  

export const getGreeting = (language) => {
    const now = new Date();
    const options = { timeZone: "Asia/Kolkata" };
    const hours = new Date(now.toLocaleString("en-US", options)).getHours();

    if (hours >= 5 && hours < 12) {
      return language.good_morning;
    } else if (hours >= 12 && hours < 17) {
      return language.good_afternoon;
    } else {
      return language.good_evening;
    }
};
  
export const menuItems  = (userData) => [
  {
    path: "/profile",
    icon: profileicon,
    labelKey: "profile",
  },
  {
    path: userData?.set_pin == 0 ? "/newpinset" : "/currentmpin",
    icon: mpinicon,
    labelKey: "mpin_security",
  },
  {
    path: "/numberchange",
    icon: phoneicon,
    labelKey: "change_number",
    extraTextKey: "",
  },
  {
    path: "/language",
    icon: languageIcon,
    labelKey: "cahnge_language",
  },
  {
    path: "/deleteaccount",
    icon: deleteIcon,
    labelKey: "delete_Account",
  },
];
  
export const IncomeImage = (language) => {
  return language.payment_mode_list[0].val1;
};

export const getOnlyDate = (dateString) =>
  dateString.split(" ").slice(0, 2).join(" ");