import MpinSet from "./MpinSet";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import Otp from "../../../../components/OtpInput/Otp";
import Loader from "../../../../components/Loader/ButtonLoader";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { decryptAES, encryptAES } from "../../../../components/CommonLibrary";
import { apiService, loginApi } from "../../../../app/services/api";

const SCREEN_DIMENSIONS = {
  tiny: { width: "43px", height: "45px", marginRight: "7.13px" },
  small: { width: "46px", height: "62px", marginRight: "7.13px" },
  medium: { width: "54px", height: "47px", marginRight: "7.13px" },
  default: { width: "50px", height: "47px", marginRight: "7.13px" }
};

const VerifyPinOtp = () => {
  // Selectors
  const userPhoneNumber = useSelector(state => state.usersLoginData.useMobileNumber);
  const language = useSelector(state => state.usersLoginData.allLanguage);

  // State management
  const [formState, setFormState] = useState({
    otp: "",
    isLoading: false,
    isBtnDisabled: false,
    isOtpDisabled: false,
    isSetPin: false
  });

  const [dimensions, setDimensions] = useState(SCREEN_DIMENSIONS.default);
  
  const [timer, setTimer] = useState({
    time: parseInt(localStorage.getItem("time")) || 60,
    isActive: localStorage.getItem("isActive") === "true"
  });

  // Refs
  const timeRef = useRef(timer.time);
  const isActiveRef = useRef(timer.isActive);

  // Timer management
  useEffect(() => {
    timeRef.current = timer.time;
    isActiveRef.current = timer.isActive;

    if (timer.isActive && timer.time > 0) {
      const interval = setInterval(() => {
        setTimer(prev => ({
          ...prev,
          time: prev.time - 1
        }));
      }, 1000);

      return () => clearInterval(interval);
    }

    if (timer.time === 0) {
      setTimer(prev => ({ ...prev, isActive: false }));
    }
  }, [timer.time, timer.isActive]);

  // Persist timer state
  useEffect(() => {
    if (timer.time > 0) {
      localStorage.setItem("time", timer.time.toString());
      localStorage.setItem("isActive", timer.isActive.toString());
    }
  }, [timer]);

  // Screen resize handler
  const handleResize = useCallback(() => {
    const width = window.innerWidth;
    let newDimensions;

    if (width <= 320) newDimensions = SCREEN_DIMENSIONS.tiny;
    else if (width <= 375) newDimensions = SCREEN_DIMENSIONS.small;
    else if (width <= 425) newDimensions = SCREEN_DIMENSIONS.medium;
    else newDimensions = SCREEN_DIMENSIONS.default;

    setDimensions(newDimensions);
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  // API handlers
  const handleResendOtp = async (e) => {
    e.preventDefault();
    setFormState(prev => ({ ...prev, isOtpDisabled: true }));

    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/users/generate_otp`;
      const formData = new FormData();
      formData.append("data", encryptAES(JSON.stringify({
        mobile_number: userPhoneNumber,
        country_id: "91",
        language_code: "en"
      })));

      const result = await loginApi(url, "POST", formData);
      const response = JSON.parse(decryptAES(result.data.response));

      if (response.status === 200 || response.status === "200") {
        setTimer({ time: 60, isActive: true });
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("OTP resend failed:", error);
      toast.error("Failed to resend OTP");
    } finally {
      setFormState(prev => ({ ...prev, isOtpDisabled: false }));
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setFormState(prev => ({ ...prev, isLoading: true, isBtnDisabled: true }));

    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/security/verify_pin_otp`;
      const tokenUrl = localStorage.getItem("auth_default_token");
      const formData = new FormData();
      formData.append("data", encryptAES(JSON.stringify({
        mobile_number: Number(userPhoneNumber),
        country_id: "91",
        pin_otp: formState.otp
      })));

      const result = await apiService(url, "POST", formData, tokenUrl);
      const response = JSON.parse(decryptAES(result.data.response));

      if (response.status === 200 || response.status === "200") {
        toast.success(response.message);
        setFormState(prev => ({ ...prev, isSetPin: true }));
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("OTP verification failed:", error);
      toast.error("Verification failed");
    } finally {
      setFormState(prev => ({ ...prev, isLoading: false, isBtnDisabled: false }));
    }
  };

  if (formState.isSetPin) {
    return <MpinSet />;
  }

  return (
    <Box className="flex justify-center items-center">
      <div className="pb-4 overflow-hidden z-30">
        <div className="mt-[60px]">
          <h1 className="font-bold text-[16px] md:text-[20px]">
            {language.verify_Number}
          </h1>
          <p className="font-normal text-[#8A8A8A] text-[14px]">
            {language.login_Otp_Sent} +91 {userPhoneNumber}
          </p>
        </div>

        <form onSubmit={handleVerifyOtp}>
          <div className="mt-[20.56px]">
            <Otp
              setOtp={(otp) => setFormState(prev => ({ ...prev, otp }))}
              otp={formState.otp}
              inputsNum={6}
              {...dimensions}
              fontSize={18}
              fontWeight="600"
            />

            <div className="flex mt-[5px]">
              {timer.time === 0 ? (
                <button
                  onClick={handleResendOtp}
                  disabled={formState.isOtpDisabled}
                  className="text-[#F6B825] font-medium text-[14px]"
                >
                  {language.resend}
                </button>
              ) : (
                <Typography variant="h6" fontWeight="normal" className="text-[14px]">
                  Resend OTP in: <span className="text-[#F6B825]">{`00:${String(timer.time).padStart(2, "0")}`}</span>
                </Typography>
              )}
            </div>

            <button
              className={`w-[100%] h-[40.67px] rounded-[5px] text-[16px] font-medium mt-[8px] 
                ${formState.otp.length >= 6 
                  ? "bg-gradient-to-r from-[#FFD87A] to-[#F6B825]" 
                  : "bg-[#FFD87A] opacity-[35%] text-gray-400"}`}
              disabled={formState.otp.length >= 6 ? formState.isBtnDisabled : true}
            >
              {formState.isLoading ? (
                <Loader width={20} height={20} color="black" />
              ) : (
                language.verify_Proceed
              )}
            </button>

            <p className="font-normal text-[#8A8A8A] text-[12px]">
              {language.clicking_Accept}{" "}
              <span 
                className="text-yellow-400 cursor-pointer"
                onClick={() => window.open("https://desihisab.com/legal", "_blank")}
              >
                {language.terms}
              </span>
            </p>
          </div>
        </form>
      </div>
    </Box>
  );
};

export default React.memo(VerifyPinOtp);