import { Category } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { decryptAES, encryptAES } from "../components/CommonLibrary";
import { apiService } from "../app/services/api";
import { toast } from "react-toastify";
import Loader from "../components/Loader/Loader";
import arrowRight from "../images/Support/arrowRight.png";
import issues from "../images/Support/issue.png";
import { useNavigate } from "react-router-dom";
import BackArrow from "../images/hisabdetails/LeftArrow.png";
import { useSelector } from "react-redux";

const SupportTickets = () => {
  const [category, setCategory] = useState("Issue");
  const [supportdata, setSupportData] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const navigate = useNavigate();
  const language = useSelector((state) => state.usersLoginData.allLanguage);

  useEffect(() => {
    const fetchSupportList = async () => {
      await supportList();
    };
    fetchSupportList();
  }, [category]);

  async function supportList() {
    setIsloading(true);
    try {
      const tokenUrl = localStorage.getItem("accessToken");
      const url = process.env.REACT_APP_API_BASE_URL + "/support/support_list";
      const body = {
        category: category,
      };
      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);
      const res = await apiService(url, "POST", formData, tokenUrl);
      const decryptedResponse = decryptAES(res.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        toast.success(JSON.parse(decryptedResponse).message);
        const data = await JSON.parse(decryptedResponse);
        setSupportData(data.supports_data);
        setIsloading(false);
      } else {
        toast.error(JSON.parse(decryptedResponse).message);
        setIsloading(false);
      }
    } catch (error) {
      console.error("error", error);
    }
  }

  const handleCategoryChange = (newCategory) => {
    setCategory(newCategory);
  };

  return (
    <>
      <div className="flex border-b items-center">
        <button
          className="p-2 max-480:w-[46px]"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img alt="backImage" src={BackArrow}></img>
        </button>
        <h1 className="text-[20px] font-[500] font-sans max-768:text-[18px]">
          {language.reported_issue}
        </h1>
      </div>
      <Box className="flex w-[100%] mt-2 gap-3 p-4">
        <Button
          className={`w-[50%] capitalize text-black font-medium text-[14px] max-640:text-[15px] ${category === "Issue" ? "bg-gradient-to-r from-[#FFD87A] to-[#F6B825]" : "bg-[#e5e7eb]"}`}
          onClick={() => handleCategoryChange("Issue")}
        >
          {language.tab_issues}
        </Button>
        <Button
          className={`w-[50%] capitalize text-black font-medium text-[14px] max-640:text-[15px] ${category === "Suggestion" ? "bg-gradient-to-r from-[#FFD87A] to-[#F6B825]" : "bg-[#e5e7eb]"}`}
          onClick={() => handleCategoryChange("Suggestion")}
        >
          {language.tab_suggestion}
        </Button>
      </Box>

      {isLoading ? (
        <div className="flex justify-center items-center w-full mt-48">
          <Loader />
        </div>
      ) : supportdata.length > 0 ? (
        supportdata?.map((item) => (
          <section className="flex flex-col items-center w-full gap-2 mt-2">
            <Box
              className="w-[100%] flex flex-col items-center cursor-pointer"
              onClick={() => {
                const encryptedId = encryptAES(
                  JSON.stringify({ id: item?.id })
                );
                if (item.id && encryptedId) {
                  const encodedData = encodeURIComponent(encryptedId);
                  navigate(`/supportChat/${encodedData}`);
                }
              }}
            >
              <Box
                key={item.id}
                className="flex w-[96%] gap-1 border-[#F5F5F5] border items-center px-3 rounded-lg h-[85px]"
              >
                <Box className="flex items-center flex-row w-[100%]">
                  <section className="flex justify-between w-[98%] mb-2 flex-col">
                    <p className="font-medium text-[14px] max-480:text-[12px]">
                      {item.subject}
                    </p>
                    <p className="text-[12px] px-[6px] font-semibold rounded-[2px] p-[3px] pl-0 max-480:text-[10px] mt-1 text-[#B1B1B1]">
                      {item.created_at}
                    </p>
                  </section>
                  <section className="flex justify-end w-[98%] items-center ">
                    <Box className="flex justify-between flex-row">
                      <p
                        className={`text-[12px] flex justify-between items-center w-fit h-[30px] 
  ${
    item.status === "Pending"
      ? "bg-[#e0e0e0] text-black"
      : item.status === "In Progress" || item.status === "Data Requested"
        ? "bg-[#ffb561]  text-yellow-700"
        : item.status === "Close"
          ? "bg-green-200 text-green-800"
          : ""
  } 
  pl-[10px] pr-[10px] pt-[2px] rounded-full`}
                      >
                        {" "}
                        {item.status}
                      </p>
                      <img src={arrowRight} alt="arrow" className="h-[25px]" />
                    </Box>
                  </section>
                </Box>
              </Box>
            </Box>
          </section>
        ))
      ) : supportdata.length === 0 && category === "Issue" ? (
        <div className="flex justify-center items-center flex-col mt-5">
          <img src={issues} alt="issue" />
          <p>No Cuurent Issues</p>
        </div>
      ) : (
        <div className="flex justify-center items-center flex-col mt-5">
          <img src={issues} alt="issue" />
          <p>No Cuurent Suggestions</p>
        </div>
      )}
    </>
  );
};

export default SupportTickets;
