import { useEffect } from "react";
import { IncomeImage } from "../utils/helper";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";

const PaymentMethodSelect = ({ updateFormState, incomeType }) => {
  const language = useSelector((state) => state.usersLoginData.allLanguage);

  useEffect(() => {
    const savedPaymentMethod = localStorage.getItem("selectedPaymentMethod");
    if (savedPaymentMethod && !incomeType) {
      updateFormState({ incomeType: savedPaymentMethod });
    }
  }, [updateFormState, incomeType]);  

  const handleChange = (event) => {
    const newValue = event.target.value;
    localStorage.setItem("selectedPaymentMethod", newValue);
    updateFormState({ incomeType: newValue });
  };
  return (
    <FormControl className="w-[100%]" sx={{ minWidth: 200 }}>
      <InputLabel>{language.payment_mode}</InputLabel>
      <Select value={incomeType} onChange={handleChange} label="Payment Method">
        {IncomeImage(language)?.map((method) => (
          <MenuItem key={method.id} value={method.id}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <img
                src={method.img}
                alt={method.title}
                style={{ width: 24, height: 24 }}
              />
              {method.title}
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PaymentMethodSelect;
