import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { getGreeting, menuItems } from "../utils/helper";
import defaultUserImage from "../images/loginpage/profile-user.png";

const NavItem = ({ to, icon, label, isActive }) => (
  <NavLink to={to}>
    <Box
      className="flex items-center h-[50px] px-[16px] !rounded-[6px] my-[20px]"
      style={{
        border: `1px solid ${isActive ? "#F6B825" : "#F5F5F5"}`,
        background:`${isActive ? "linear-gradient(135.53deg, rgba(255, 216, 122, 0.1) 2.61%, rgba(246, 184, 37, 0.1) 96.58%)" : ''}`,
        borderRadius: "10px",
      }}
    >
      <img src={icon} className="w-[24px] h-[24px]" alt="menu_icon" />
      <p className="text-[16px] font-medium ml-[15px]">{label}</p>
    </Box>
  </NavLink>
);

function MyAccount() {
  const location = useLocation();
  const [greeting, setGreeting] = useState("");
  const language = useSelector((state) => state.usersLoginData.allLanguage);
  const userLoginData = useSelector((state) => state.usersLoginData.getUser); 
  const userData = useSelector((state) => state.usersLoginData.loginUserDetails);
  const ProfileUrl = `${process.env.REACT_APP_API_BASE_URL}/uploads/user/${userLoginData?.id}/${userLoginData?.profile_photo}`
  
  // useEffect(() => {
  //   console.log("location:", location.pathname);
  // }, [location]);

  useEffect(() => {
    setGreeting(getGreeting(language));
  }, [language]);

  return (
    <Box>
      <Box
        className="flex items-center py-3 pl-[2rem] mt-[22.18px]"
        style={{
          background: `linear-gradient(135.53deg, rgba(255, 216, 122, 0.1) 2.61%, rgba(246, 184, 37, 0.1) 96.58%)`,
        }}
      >
        <img
          src={ProfileUrl ? ProfileUrl : defaultUserImage}
          alt="User Profile"
          className="w-[3rem] h-[3rem] rounded-full object-cover"
        />
        <Box className="ml-4">
          <h1 className="text-[16px] md:text-[20px] font-bold">{greeting}</h1>
          <p
            className="text-[16px] md:text-[20px] font-medium text-yellow-500"
            style={{
              color: "linear-gradient(135.53deg, #FFD87A 2.61%, #F6B825 96.58%)",
            }}
          >
            {userLoginData?.first_name} {userLoginData?.last_name}
          </p>
        </Box>
      </Box>

      {/* Menu Items */}
      <Box className="mt-[15px] px-[35px] profileScrollbar"
        style={{
          maxHeight: "calc(100vh - 155px)",
          overflowY: "auto",
        }}
      >
        {menuItems(userData).map(({ path, icon, labelKey, extraTextKey }, index) => {
          const resolvedPath =
            typeof path === "function" ? path(userLoginData) : path;
          return (
            <NavItem
              key={index}
              to={resolvedPath}
              icon={icon}
              label={
                language[labelKey] +
                (extraTextKey ? ` ${language[extraTextKey]}` : "")
              }
              isActive={location.pathname === resolvedPath}
            />
          );
        })}
      </Box>
    </Box>
  );
}

export default MyAccount;