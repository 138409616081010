import React from "react";
import { Navigate, Outlet } from "react-router-dom";

// Constants
const ROUTES = {
  LOGIN: "/login",
  HOME: "/",
};

const TOKEN_KEYS = {
  ACCESS_TOKEN: "accessToken",
  AUTH_DEFAULT_TOKEN: "auth_default_token",
};

// Utility functions
const getToken = (key) => localStorage.getItem(key);

// HOC for route protection
const withRouteProtection = (ProtectedComponent, {
  tokenKey = TOKEN_KEYS.ACCESS_TOKEN,
  redirectPath = ROUTES.LOGIN,
  requireAuth = true,
}) => {
  const token = getToken(tokenKey);
  const shouldRedirect = requireAuth ? !token : token;
  const redirectTo = requireAuth ? redirectPath : ROUTES.HOME;

  return shouldRedirect ? <Navigate to={redirectTo} replace /> : <ProtectedComponent />;
};

// Route components
export const PrivateRoute = () => 
  withRouteProtection(Outlet, {
    requireAuth: true,
    redirectPath: ROUTES.LOGIN
  });

export const PublicRoute = () => 
  withRouteProtection(Outlet, {
    requireAuth: false,
    redirectPath: ROUTES.HOME
  });

export const MpinProfileRoute = () => 
  withRouteProtection(Outlet, {
    tokenKey: TOKEN_KEYS.AUTH_DEFAULT_TOKEN,
    requireAuth: true,
    redirectPath: ROUTES.HOME
  });