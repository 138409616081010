import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { decryptAES, encryptAES } from "../components/CommonLibrary";
import { toast } from "react-toastify";
import { apiService } from "../app/services/api";
import { Avatar, Box, Button, Typography } from "@mui/material";
import BackArrow from "../images/hisabdetails/LeftArrow.png";
import Loader from "../components/Loader/Loader";
import TrashIcon from "../images/sideBar/trash.png";
import EditIcon from "../images/hisabdetails/EditIcon.png";
import { setTransactionDetails } from "../app/Redux/reducer";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../components/CommonModal/CustomModal";
import { setFetchAllHisab } from "../app/Redux/reducer";

function ViewTransaction() {
  const { id } = useParams();
  let decryptedID;
  if (id) {
    decryptedID = decryptAES(id);
  }
  const urlObject = JSON.parse(decryptedID ? decryptedID : null);
  const navigate = useNavigate();
  const language = useSelector((state) => state.usersLoginData.allLanguage);  

  const number = urlObject?.id;
  const [hisabDetailsData, setHisabDetailsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const userData = useSelector(
    (state) => state.usersLoginData.transactionDetails
  );
  const userLoginData = useSelector((state) => state.usersLoginData.getUser);

  async function getDetails() {
    setIsLoading(true);
    try {
      const tokenUrl = localStorage.getItem("accessToken");
      const url =
        process.env.REACT_APP_API_BASE_URL +
        "/transaction/get_transaction_detail";
      const body = {
        transaction_id: number,
      };
      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);
      const res = await apiService(
        url,
        "POST",
        formData,
        tokenUrl
      );
      const decryptedResponse = decryptAES(res.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        toast.success(JSON.parse(decryptedResponse).message);
        const data = await JSON.parse(decryptedResponse);
        setHisabDetailsData(data);
        setIsLoading(false);
      } else {
        toast.error(JSON.parse(decryptedResponse).message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("error", error);
      setIsLoading(false);
    }
  }
  const deleteTransaction = async () => {
    setIsLoading(true);
    try {
      const tokenUrl = localStorage.getItem("accessToken");
      const url =
        process.env.REACT_APP_API_BASE_URL + "/transaction/delete_transaction";
      const body = {
        transaction_id: number,
      };
      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);
      const res = await apiService(
        url,
        "POST",
        formData,
        tokenUrl
      );
      const decryptedResponse = decryptAES(res.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        toast.success(JSON.parse(decryptedResponse).message);
        const data = await JSON.parse(decryptedResponse);
        // setHisabDetailsData(data);
        dispatch(setFetchAllHisab(true));
        navigate(-1);
        setIsLoading(false);
      } else {
        toast.error(JSON.parse(decryptedResponse).message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("error", error);
      setIsLoading(false);
    }
  };

  function handleEditTransaction() {
    const encryptedId = encryptAES(
      JSON.stringify({ transactionId: hisabDetailsData.transactions_data.id })
    );
    const encodedData = encodeURIComponent(encryptedId);
    navigate(`/EditTransaction/${encodedData}`);
    // navigate(
    //   `/EditTransaction/:transactionId=${
    //     hisabDetailsData.transactions_data.id
    //   }`
    // );
    dispatch(setTransactionDetails(hisabDetailsData));
  }

  const findModeTransactions = (item) => {
    let found = language.payment_mode_list[0].val1.find(
      (element) => element.id === item
    );
    return found?.title;
  };
  useEffect(() => {
    if (number) {
      getDetails();
    } else if (number === undefined) {
      navigate("/Error");
    }
  }, [number]);
  return (
    <>
      {isLoading ? (
        <div className="flex items-center !justify-center !w-[96%] !h-[100%]">
          <Loader width={40} height={40} color={"black"} />
        </div>
      ) : (
        <div className="mb-[10px] h-[100vh]">
          <div className="w-[100%]  flex flex-col gap-[4%] max-680:w-[100%]">
            {/* div for transaction header */}
            <div className="w-full pt-2 pb-2 pl-3 pr-3 flex justify-between max-680:w-[100%] border-b">
              <div className="flex items-center">
                <button
                  className=""
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <img alt="backImage" src={BackArrow}></img>
                </button>
                <h1 className=" text-[20px] font-medium ml-2 max-480:text-[16px]">
                  {language.view_Transaction}
                </h1>
              </div>
              {userLoginData?.id ===
                hisabDetailsData.transactions_data?.user_id && (
                <div className="flex gap-2">
                  <button className="" onClick={handleEditTransaction}>
                    <img
                      className=" w-[20px] h-[20px]"
                      alt="trash"
                      src={EditIcon}
                    ></img>
                  </button>
                  <button className="">
                    <img
                      className=" w-[20px] h-[20px]"
                      alt="trash"
                      src={TrashIcon}
                      onClick={() => {
                        setOpen(true);
                      }}
                    ></img>
                  </button>
                </div>
              )}
            </div>

            <div className="w-full mt-3 flex flex-col justify-center items-center gap-2 max-1097:!mt-[2.25rem]">
              <Avatar
                src={`${process.env.REACT_APP_API_BASE_URL}/uploads/user/${
                  hisabDetailsData.transactions_data?.user_id
                }/${hisabDetailsData.transactions_data?.profile_photo}`}
                className="!w-[80px] !h-[80px] max-1097:!w-[50px] max-1097:!h-[50px]"
              />
              <h1 className="text-[16px] font-bold">
                {hisabDetailsData?.transactions_data?.first_name +
                  " " +
                  hisabDetailsData?.transactions_data?.last_name}
              </h1>
              <h1 className="text-[14px]">
                {(() => {
                  const time =
                    hisabDetailsData?.transactions_data?.transaction_date;
                  if (time) {
                    const dateParts = time.split(" ");
                    const formattedDate = `${dateParts[0]} ${dateParts[1]} ${dateParts[2].slice(7)}`;
                    return formattedDate;
                  }
                  return "";
                })()}
              </h1>
              <h1 className="bg-[#F6B82547] text-[16px] font-semibold rounded-lg p-1 pl-3 pr-3 max-480:text-[12px]">
                {hisabDetailsData?.transactions_data?.hisab_name}
              </h1>
              <h1
                className={`${
                  hisabDetailsData.transactions_data?.transaction_type ===
                  "income"
                    ? "text-[#46A759]"
                    : "text-[#E5474D]"
                } font-bold text-[20px] max-480:text-[14px]`}
              >
                {hisabDetailsData.transactions_data?.transaction_type ===
                "income"
                  ? "+"
                  : "-"}
                ₹{hisabDetailsData?.transactions_data?.transaction_amount}
              </h1>
              <section className="flex justify-between w-[60%]  max-680:w-[90%] border-b border-[#ddd] pb-2">
                <h1 className="text-[18px] text-[#030303] font-medium">
                  {language.title}:
                </h1>
                <h1 className="text-[#848282] text-[18px]">
                  {hisabDetailsData?.transactions_data?.transaction_title}
                </h1>
              </section>

              <section className="flex justify-between w-[60%]  max-680:w-[90%] border-b border-[#ddd] pb-2">
                <h1 className="text-[18px] text-[#030303] font-medium">
                  {language.type}
                </h1>
                <h1 className="text-[#848282] text-[18px]">
                  {hisabDetailsData?.transactions_data?.transaction_type ===
                  "income"
                    ? language.income
                    : language.expense}
                </h1>
              </section>
              <section className="flex justify-between  w-[60%]  max-680:w-[90%]">
                <h1 className="text-[18px] text-[#030303] font-medium">
                  {language.payment_type}
                </h1>
                <h1 className="text-[#848282] text-[18px]">
                  {findModeTransactions(
                    hisabDetailsData?.transactions_data?.transaction_mode
                  )}
                </h1>
              </section>
            </div>
            <div className="w-[100%] flex flex-col items-center justify-center mt-[20px]">
              {/* <p className="text-[17px] text-[#848282] font-medium w-[60%] text-justify max-680:w-[90%]">
                {hisabDetailsData?.transactions_data?.transaction_detail}
              </p> */}
              <p
                className="text-[17px] text-[#848282] font-medium w-[60%] text-justify max-680:w-[90%]"
                dangerouslySetInnerHTML={{
                  __html:
                    hisabDetailsData?.transactions_data?.transaction_detail.replace(
                      /\n/g,
                      "<br />"
                    ),
                }}
              ></p>
            </div>
            <div className="w-full flex items-center justify-center">
              <section
                className={`flex gap-3 items-center justify-center max-960:!justify-start max-960:horizontal-scrollbar max-960:overflow-x-auto mt-4 whitespace-nowrap max-680:ml-2 mb-4 max-960:w-auto 
    ${
      hisabDetailsData.transactions_data?.transaction_images?.length <= 2
        ? "!justify-center"
        : `lg:${
            hisabDetailsData.transactions_data?.transaction_images?.length === 3
              ? "justify-center"
              : "justify-start"
          }`
    }`}
              >
                {hisabDetailsData.transactions_data?.transaction_images?.map(
                  (img) => (
                    <img
                      key={img.transaction_photo}
                      className="flex-shrink-0 w-[104px] h-[103px] border-solid border-[1px] border-[#F7B924] rounded-[5px] max-680:w-[80px] max-680:h-[100px] mr-2"
                      src={`${process.env.REACT_APP_API_BASE_URL}/uploads/transactions/hisab_${hisabDetailsData.transactions_data.hisab_id}/${img.transaction_photo}`}
                      alt="Transaction"
                    />
                  )
                )}
              </section>
            </div>
          </div>
        </div>
      )}
      <CustomModal
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        isCloseIcon={false}
        widthFor={{ xs: "90%", sm: "280px", md: "380px" }}
        height="180px"
      >
        <Box className="flex flex-col gap-6 max-768:gap-7">
          <Typography className="mt-5 font-semibold w-full text-center">
            {language.delete_TransactionMsg}
          </Typography>
          <Box className="flex justify-evenly max-768:gap-[15px]">
            <Button
              className="px-4 bg-gray-300 rounded w-25 h-[32px] text-black"
              onClick={() => setOpen(false)}
            >
              {language.no}
            </Button>
            <Button
              className="px-4 !bg-gradient-to-r from-[#FFD87A] via-[#F6B825] to-[#F6B825]  text-black rounded w-25 h-[32px]"
              onClick={() => deleteTransaction(number)}
            >
              {language.yes}
            </Button>
          </Box>
        </Box>
      </CustomModal>
    </>
  );
}
export default ViewTransaction;
