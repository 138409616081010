import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import minus from "../images/CustomerCare/Minus.png";
import plus from "../images/CustomerCare/Mask group.png";
import BackArrow from "../images/hisabdetails/LeftArrow.png";
import React, { useEffect, useState, useCallback, memo } from "react";

const FaqItem = memo(({ faq, isExpanded, onToggle }) => (
  <div 
    className={`max-1030:w-[92%] w-[96%] p-2 shadow-custom rounded-[5px] ${
      isExpanded ? "bg-[#FFFFFF]" : "bg-[#FFD87A]"
    }`}
  >
    <div
      onClick={onToggle}
      className="flex justify-between cursor-pointer items-center"
    >
      <h2 className="max-480:text-[16px] max-768:w-[93%] text-[16px] max-480:text-sm font-medium">
        {`${faq.id}. ${faq.question}`}
      </h2>
      <img
        src={isExpanded ? minus : plus}
        alt={isExpanded ? "collapse" : "expand"}
        className="w-[16px] h-[16px]"
        loading="lazy"
      />
    </div>
    {isExpanded && (
      <p className="mt-2 text-gray-700 text-sm max-480:text-xs">
        {faq.answer}
      </p>
    )}
  </div>
));

const Faq = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [faqLanguage, setFaqLanguage] = useState({ faqs: [] });
  const navigate = useNavigate();
  const allLanguage = useSelector((state) => state.usersLoginData.allLanguage);

  const handleToggle = useCallback((id) => {
    setExpandedIndex(prev => prev === id ? null : id);
  }, []);

  const fetchFaqData = useCallback(async () => {
    try {
      const selectedLanguage = localStorage.getItem("selectedLanguage");
      const response = await fetch(
        `${process.env.REACT_APP_FAQ_LANGUAGE}/${selectedLanguage}_faqs.json`
      );
      
      if (!response.ok) {
        throw new Error('Failed to fetch FAQ data');
      }
      
      const data = await response.json();
      setFaqLanguage(data);
    } catch (error) {
      console.error('Error fetching FAQ data:', error);
    }
  }, []);

  useEffect(() => {
    fetchFaqData();
  }, [fetchFaqData]);

  return (
    <div className="container mx-auto">
      <header className="flex items-center border-b">
        <button
          className="p-2 max-480:w-[46px]"
          onClick={() => navigate(-1)}
          aria-label="Go back"
        >
          <img alt="Back" src={BackArrow} width="30" height="30" />
        </button>
        <h1 className="text-[20px] font-[500] font-sans max-768:text-[18px]">
          {allLanguage.faq_text}
        </h1>
      </header>

      <main className="flex flex-col justify-center items-center w-full gap-4 mt-8">
        {faqLanguage.faqs?.map((faq, index) => (
          <FaqItem
            key={faq.id}
            faq={faq}
            isExpanded={expandedIndex === index}
            onToggle={() => handleToggle(index)}
          />
        ))}
      </main>
    </div>
  );
};

export default memo(Faq);