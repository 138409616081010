import React, { useState } from "react";
import Loader from "../components/Loader/ButtonLoader";
import inputFlag from "../images/loginpage/india.png";
import { Box, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { decryptAES, encryptAES } from "../components/CommonLibrary";
import { loginApi } from "../app/services/api";
// import Verifyotp from "./loginPage/Verifyotp";
import leftIcon from "../images/slider/bi_arrow-left.png";
import changeMobileNumberImage from "../images/myaccount/change-number-V1 1.png";
import ChangeVerifyNumber from "../components/ChangeMobileNumber/ChangeVerifyNumber";
import BackArrow from "../images/hisabdetails/LeftArrow.png";
import { useNavigate } from "react-router-dom";
import Header from "../UI/Header";


function ChangeMobileNumber() {
  const language = useSelector(
    (state) => state.usersLoginData.allLanguage
  );
    const navigate = useNavigate();
  const userLoginData = useSelector((state) => state.usersLoginData.getUser);
  const [mobileNumber, setMobileNumber] = useState(userLoginData.mobile_number);
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [userLoginId, setUserLoginId] = useState(userLoginData.id);
  const [userId, setUserId] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [time, setTime] = useState(60);
  const [isActive, setIsActive] = useState(false);

  const onHandleClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsBtnDisabled(true);
    const userLoginData = JSON.stringify({
      mobile_number: Number(mobileNumber),
      country_id: "91",
      language_code: "en",
      login_device_detail: "android",
      new_app: "Newapp",
      route: null,
      user_id: userLoginId,
    });

    try {
      const encryptedData = encryptAES(userLoginData);

      const formData = new FormData();
      formData.append("data", encryptedData);

      const url = process.env.REACT_APP_API_BASE_URL + "/users/generate_otp";

      const result = await loginApi(url, "POST", formData);
      const decryptedResponse = decryptAES(result.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        toast.success(JSON.parse(decryptedResponse).message);
        setIsLoading(false);
        setIsBtnDisabled(false);
        setVerifyOtp(true);
        setTime(60);
        setIsActive(true);
        setUserId(true);
      } else {
        setIsLoading(false);
        setIsBtnDisabled(false);
        toast.error(JSON.parse(decryptedResponse).message);
        setVerifyOtp(false);
        setUserId(false);
      }
    } catch (error) {
      console.log("Error during login:", error);
      setIsLoading(false);
      setIsBtnDisabled(false);
      setUserId(false);
    }
  };

  return (
    <div>
     <header className="flex gap-1 border-b" >
          <button
            className="pt-2 max-480:w-[46px] pl-2 flex justify-center items-center"
            onClick={() => navigate(-1)}
            aria-label="Go back"
          >
            <img alt="Back" src={BackArrow} width={24} height={24}/>
          </button>
          <Header title={language?.change_number_appbar} className="w-full p-2 "/>
        </header>
      
      <div>
        <div>
          {verifyOtp === true ? (
            <ChangeVerifyNumber
              mobileNumber={mobileNumber}
              setVerifyOtp={setVerifyOtp}
              time={time}
              isActive={isActive}
              setTime={setTime}
              setIsActive={setIsActive}
              userId={userId}
              userLoginId={userLoginId}
            />
          ) : (
            <div className="flex justify-center items-center mt-[20px] max-425:mt-[20px]">
              <div className="w-[65%] max-1024:w-[75%] max-425:!w-[90%] max-h-[80%] h-full bg-white  overflow-hidden px-[55px] max-425:!px-[30px] max-425:pt-[30px] pb-[40.33px] max-425:pb-[50px]">
                <div className="flex justify-center">
                  <img
                    src={changeMobileNumberImage}
                    alt="arrow left"
                    className="w-[300px] max-425:h-[150px] max-375:!h-full h-[249px]"
                  />
                </div>
                <div
                  className="my-[30.5px]"
                  style={{ borderTop: "1px solid #EEF0F2" }}
                />
                <div className="mx-[35px] max-425:mx-[0px]">
                  <form onSubmit={onHandleClick}>
                    <div>
                      <Box className="flex items-center w-[100%] h-[40.06px] rounded-md border-[#F1F1F1] border-[1px] p-3 !z-50">
                        <Box className="flex justify-center items-center !z-50">
                          <img
                            src={inputFlag}
                            alt="Ellipse Icon"
                            className="w-[31.21px] h-[31.21px]"
                          />

                          <Typography className="!ml-[4px] font-normal !text-[17px]">
                            +91
                          </Typography>
                          <Box
                            borderLeft="1px solid #EEF0F2"
                            width="2px"
                            height="40px"
                            margin="0px 9px 0px 9px"
                          />
                        </Box>
                        <TextField
                        autoFocus
                          id="outlined-basic"
                          sx={{
                            border: "none",
                            "& fieldset": { border: "none" },
                            padding: "0px 0px",
                            height:"40px",
                            "& .MuiInputBase-root": {
                              height:"40px",
                              padding: "0rem 0rem",
                            },
                            "& .MuiInputBase-input": {
                              fontSize:"14px",
                              padding: "0rem 0rem",
                            },
                            "& .MuiInputBase-input::placeholder": {
                              opacity: "10%",
                            },
                          }}
                          placeholder="Mobile Number"
                          variant="outlined"
                          size="small"
                          value={mobileNumber}
                          onChange={(e) =>
                            setMobileNumber(
                              e.target.value.replace(/[^0-9]/g, "").slice(0, 10)
                            )
                          }
                          // onKeyDown={handleKeyDown}
                          inputProps={{
                            maxLength: 10,
                            pattern: "[0-9]*",
                          }}
                          className="!z-50 !w-[83%]"
                        />
                      </Box>
                      <button
                        className={`${
                          mobileNumber?.length >= 10
                            ? "bg-gradient-to-r from-[#FFD87A] to-[#F6B825]"
                            : "bg-[#FFD87A] opacity-[35%] text-gray-400"
                        } font-medium text-[16px] w-[100%] h-[40px] rounded-md mt-3`}
                        disabled={
                          mobileNumber?.length >= 10 ? isBtnDisabled : true
                        }
                      >
                        {isLoading === true ? (
                          <Loader width={20} height={20} color={"black"} />
                        ) : (
                          language.update_Contact
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChangeMobileNumber;
