import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

function Header({ title }) {
  const navigate = useNavigate();
  
  const isProfilePage = title === "Profile" || title === "प्रोफ़ाइल" || title === "Current MPIN" ||  "Change Number" || title === "Change language"

  return (
    <Grid container className=" w-full pt-[2px]">
      {!isProfilePage && (
        <Grid 
          size={1}
          sx={{
            maxWidth: '30px'
          }}
          className="items-center justify-center flex pl-4"
        >
          <KeyboardBackspaceIcon 
            onClick={() => navigate(-1)}
            className="text-black cursor-pointer" 
          />
        </Grid>
      )}

      <Grid  size={11} className="pl-[8px]">
        <Typography
          variant="h2"
          className="py-2 text-[20px] font-[500] font-sans max-768:text-[18px] "
        >
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Header;