import { toast } from "react-toastify";
import OTPInput from "react-otp-input";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import Otp from "../components/OtpInput/Otp";
import { Box, Typography } from "@mui/material";
import { apiService } from "../app/services/api";
import Loader from "../components/Loader/ButtonLoader";
import newSetPin from "../images/loginpage/set-pin_1.png";
import CustomModal from "../components/CommonModal/CustomModal";
import React, { useCallback, useEffect, useState } from "react";
import { decryptAES, encryptAES } from "../components/CommonLibrary";

function NewSetMpin() {
  const language = useSelector((state) => state.usersLoginData.allLanguage);

  const [otp, setOtp] = useState("");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmMpin, setConfirmMpin] = useState("");
  const [dynamicWidth, setDynamicWidth] = useState("47px");
  const [dynamicHeight, setDynamicheight] = useState("40px");
  const [isSuccessMessage, setIsSuccessMessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [dynamicMarginRight, setDynamicMarginRight] = useState("30px");
    const navigate = useNavigate();
  
  function validateMpin() {
    if (otp?.length === 4 && confirmMpin?.length === 4) {
      if (otp == confirmMpin) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  useEffect(() => {
    updateMarginRight();
    window.addEventListener("resize", updateMarginRight);
    return () => window.removeEventListener("resize", updateMarginRight);
  }, [dynamicWidth, dynamicHeight, dynamicMarginRight]);

  const handleOnClickEnblePin = async () => {
    try {
      // const url = process.env.REACT_APP_API_BASE_URL + "/security/enable_pin";

      // const tokenUrl = localStorage.getItem("accessToken");

      const parameters = {
        enable_pin: 1,
      };

      const encryptedData = encryptAES(JSON.stringify(parameters));

      const formData = new FormData();
      formData.append("data", encryptedData);

      // const result = await enablePinApi(url, "POST", formData, tokenUrl);?

    } catch (error) {
      console.log("error", error);
    }
  };

  const handleOnClick = async (e) => {
    e.preventDefault();
    const ok = validateMpin();
    if (!ok) {
      toast.error(language.pin_Does_Not_Set);
      setIsButtonDisabled(false);
      return;
    }

    setIsLoading(true);
    setIsButtonDisabled(true);
    try {
      const url = process.env.REACT_APP_API_BASE_URL + "/security/set_pin";

      const tokenUrl = localStorage.getItem("accessToken");

      const parameters = {
        pin: confirmMpin,
      };

      const encryptedData = encryptAES(JSON.stringify(parameters));

      const formData = new FormData();
      formData.append("data", encryptedData);

      const result = await apiService(url, "POST", formData, tokenUrl);

      const decryptedResponse = decryptAES(result.data.response);

      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        handleOnClickEnblePin();
        toast.success(JSON.parse(decryptedResponse).message);
        navigate("/currentmpin")
        setIsLoading(false);
        setIsButtonDisabled(false);
        setOpen(true);
      } else {
        toast.error(JSON.parse(decryptedResponse).message);
        setIsLoading(false);
        setIsButtonDisabled(false);
      }
    } catch (error) {
      setIsLoading(false);
      setIsButtonDisabled(false);
    }
  };

  const updateMarginRight = () => {
    if (window.innerWidth <= 320) {
      setDynamicWidth("53px");
      setDynamicheight("53px");
      setDynamicMarginRight("10px");
    } else if (window.innerWidth <= 375) {
      setDynamicWidth("62px");
      setDynamicheight("63px");
      setDynamicMarginRight("18px");
    } else if (window.innerWidth <= 425) {
      setDynamicWidth("62px");
      setDynamicheight("63px");
      setDynamicMarginRight("20px");
    } else if (window.innerWidth <= 1024) {
      setDynamicWidth("78px");
      setDynamicheight("72px");
      setDynamicMarginRight("45px");
    } else {
      setDynamicWidth("47px");
      setDynamicheight("40px");
      setDynamicMarginRight("30px");
    }
  };

  return (
    <Box className="">
      <Typography className="py-2 border-b text-[20px] font-[500] font-sans max-768:text-[18px] pl-[18px]">{language.set_PIN}</Typography>

      <Box className="flex justify-center items-center mt-[30px] max-768:mt-[5px]">
        <Box className=" drop-shadow-xl bg-white p-5 rounded-[20px]  max-h-[60%] h-full pb-[30px]  overflow-hidden flex-col">
          <Typography className="text-1xl font-semibold "></Typography>
          <form onSubmit={handleOnClick}>
            <Box className="flex justify-center items-center flex-col ml-[34px] max-1024:ml-[40px] max-425:!ml-[20px] max-320:!mr-[10px]">
              <Box>
                <img src={newSetPin} alt="set pin" className="w-[100px] h-auto" />
              </Box>
              <Box>
                <Typography className="text-[#444444] text-[16px] font-medium mb-[11px]">
                  {language.set_PIN}
                </Typography>
                <Otp
                  setOtp={setOtp}
                  otp={otp}
                  inputsNum={4}
                  width={dynamicWidth}
                  height={dynamicHeight}
                  marginRight={dynamicMarginRight}
                  fontSize={18}
                  fontWeight={"600"}
                />
              </Box>

              <Box className="ml-5">
                <Typography className="text-[#444444] text-[16px] font-medium my-[11px]">
                  {language.confirm_PIN}
                </Typography>
                <OTPInput
                  value={confirmMpin}
                  onChange={setConfirmMpin}
                  numInputs={4} // 6
                  renderSeparator={false}
                  renderInput={(props) => (
                    <input
                      {...props}
                      type="tel"
                      pattern="[0-9]*"
                      maxLength={1}
                      style={{
                        width: dynamicWidth, // 45,
                        height: dynamicHeight, // 55,
                        textAlign: "center",
                        fontSize: "18px",
                        border: "1px solid #EEF0F2",
                        borderRadius: "4px",
                        outline: "none",
                        fontWeight: "600",
                        marginRight: dynamicMarginRight, //25
                        transition: "border-color 0.3s ease",
                      }}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box className="flex justify-center items-center w-full max-375:px-[20px]">
              <button
                className={`${otp.length === 4 && confirmMpin.length === 4
                    ? "bg-gradient-to-r from-[#FFD87A] to-[#F6B825]"
                    : "bg-[#FFD87A] opacity-[35%] text-gray-400"
                  } w-[275px] max-1024:w-[78%] max-375:!w-[100%] ml-[29px] h-[38.67px] rounded-[5px] text-[16px] font-medium mt-[17px]`}
                disabled={otp.length !== 4 || confirmMpin.length !== 4}
              >
                {isLoading === true ? (
                  <Loader width={20} height={20} color={"black"} />
                ) : (
                  language.set_PIN_appbar
                )}
              </button>
            </Box>
          </form>
          <Box className="flex justify-center items-center w-full h-[46.78px] mt-[13.61px] max-375:px-[20px]">
            <NavLink
              style={{ border: "2px solid #E2E8F0" }}
              className="text-black bg-white ml-[29px] font-medium text-[16px] w-[275px] max-1024:w-[78%] h-[38.67px] max-375:!w-[100%] px-4 py-1 rounded-md transition-colors text-center"
              to="/currentmpin"
            >
              {language.cancel}
            </NavLink>
          </Box>
        </Box>
      </Box>

      {/* <CustomModal
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        isCloseIcon={false}
        widthFor={{ xs: "90%", sm: "400px", md: "435px" }}
        height="180px"
        borderRadiusForBox="15px"
      >
        <div className="flex justify-center items-center flex-col">
          <Typography
            variant="h6"
            className="text-center font-semibold "
            sx={{
              fontSize: "1rem",
              "@media (max-width: 768px)": {
                fontSize: "12px",
              },
            }}
          >
            {isSuccessMessage}
          </Typography>

        </div>
        <NavLink
          className="bg-yellow-400 font-semibold w-[100%] px-4 py-3 rounded-md transition-colors text-center flex justify-center mt-6"
          to="/currentmpin"
        >
          Okay
        </NavLink>
      </CustomModal>  */}
    </Box>
  );
}

export default NewSetMpin;
