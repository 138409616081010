import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { decryptAES, encryptAES } from "../components/CommonLibrary";
import {
  apiService,
} from "../app/services/api";
import { toast } from "react-toastify";
// import { Button, Checkbox } from "@mui/material";
import CustomModal from "../components/CommonModal/CustomModal";
import Search from "../components/Search";
import debounce from "lodash.debounce";
import Loader from "../components/Loader/Loader";
import { Box, Checkbox } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchBar from "../components/Search";
import { format } from 'date-fns';

function DeletedHisab() {
  const [allHisabs, setAllHisabs] = useState([]);
  const [deletedHisab, setDeletedHisab] = useState([]);
  const [restoreModel, setRestoreModel] = useState(false);
  const [selectedHisabId, setSelectedHisabId] = useState(null);
  const [selectedHisabs, setSelectedHisabs] = useState([]);
  const [isModalView, setIsModalView] = useState("");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [deleteAll, setDeleteAll] = useState(false);
  const [isLoading, setLoading] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [query, setQuery] = useState("");
  const userData = useSelector(
    (state) => state.usersLoginData.loginUserDetails
  );
  const language = useSelector((state) => state.usersLoginData.allLanguage);  

  const itemsPerPage = 7;

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedHisabs([]);
    } else {
      setSelectedHisabs(deletedHisab.map((item) => item.hisab_id));
    }
    setSelectAll(!selectAll);
  };

  const handleSelectHisab = (hisabId) => {
    const updatedSelectedHisabs = selectedHisabs.includes(hisabId)
      ? selectedHisabs.filter((id) => id !== hisabId)
      : [...selectedHisabs, hisabId];
  
    setSelectedHisabs(updatedSelectedHisabs);
  
    if (updatedSelectedHisabs.length === deletedHisab.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  useEffect(() => {
    setPage(1);
    fetchTrashHisabList(query);
  }, [query]);

  async function fetchTrashHisabList(searchQuery = "", pageNo = 1) {
    setLoading(true);
    try {
      // const userToken = encryptAES(userData.access_token);
      const tokenUrl = localStorage.getItem("accessToken");
      const url =
        process.env.REACT_APP_API_BASE_URL + "/hisab/get_trash_hisab_list";
      const body = {
        user_id: userData.user_id,
        page_no: pageNo.toString(),
      };
      if (searchQuery) {
        body.search_by = searchQuery;
      }
      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);
      const res = await apiService(url, "POST", formData, tokenUrl);
      const decryptedResponse = decryptAES(res.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        setLoading(false);
        setErrorMessage("");
        toast.success(JSON.parse(decryptedResponse).message);
        const data = await JSON.parse(decryptedResponse);
        const hisabs = data.hisab_data || [];
        setAllHisabs(hisabs);
        setDeletedHisab(hisabs.slice(0, itemsPerPage));
        setHasMore(hisabs.length > itemsPerPage);
      } else {
        setLoading(false);
        setDeletedHisab([]);
        setAllHisabs([]);
        setHasMore(false);
        if (searchQuery) {
          setErrorMessage(JSON.parse(decryptedResponse).message);
        } else {
          setErrorMessage(JSON.parse(decryptedResponse).message);
        }
      }
    } catch (error) {
      console.error("error", error);
    }
  }

  const fetchMoreData = () => {
    const nextPage = page + 1;
    const startIndex = (nextPage - 1) * itemsPerPage;
    const endIndex = nextPage * itemsPerPage;
    const nextPageData = allHisabs.slice(startIndex, endIndex);
    if (nextPageData.length > 0) {
      setDeletedHisab((prev) => [...prev, ...nextPageData]);
      setPage(nextPage);
    }
    setHasMore(nextPageData.length === itemsPerPage);
  };

  const debounceSearch = debounce((searchQuery) => {
    setQuery(searchQuery.trim());
  }, 500);

  const restoreHisab = async () => {
    try {
      if (!selectedHisabId) {
        return;
      }
      const tokenUrl = localStorage.getItem("accessToken");
      const url = process.env.REACT_APP_API_BASE_URL + "/hisab/restore_hisab";
      const body = {
        hisab_id: selectedHisabId,
      };
      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);
      const res = await apiService(url, "POST", formData, tokenUrl);
      const decryptedResponse = decryptAES(res.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        const data = await JSON.parse(decryptedResponse);
        toast.success(JSON.parse(decryptedResponse).message);
        setRestoreModel(false)
        fetchTrashHisabList();
      } else {
        toast.error(JSON.parse(decryptedResponse).message);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const deleteHisab = async () => {
    try {
      if (!selectedHisabId && selectedHisabs.length === 0) {
        return;
      }
      const tokenUrl = localStorage.getItem("accessToken");
      const url =
        process.env.REACT_APP_API_BASE_URL + "/hisab/delete_hisab_in_trash";
      const body = {
        hisab_id: selectedHisabId ? [selectedHisabId] : selectedHisabs,
      };
      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);
      const res = await apiService(url, "POST", formData, tokenUrl);
      const decryptedResponse = decryptAES(res.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        toast.success(language.all_hisab_delete_sucess);
        const data = await JSON.parse(decryptedResponse);
        setDeletedHisab(
          deletedHisab.filter((item) => !selectedHisabs.includes(item.hisab_id))
        );
        setRestoreModel(false);
        fetchTrashHisabList();
        setSelectedHisabs([]);
        setSelectedHisabId(null);
        setSelectAll(false);
      } else {
        toast.error(JSON.parse(decryptedResponse).message);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const getOnlyDate = (dateString) => {
    return dateString.split(" ").slice(0, 2).join(" ");
  };

  return (
    <>
      <Box className="pl-3 pr-4 w-[100%]">
        <SearchBar onSearch={debounceSearch} placeholder="Search" />
      </Box>

      <div className="flex items-center justify-center flex-col mt-5 overflow-hidden">
        {deletedHisab.length > 0 && (
          <div className="w-[100%] flex border-1 justify-between  pl-[4px] pr-[17px]">
            <div className="flex items-center w-90% gap">
              <Checkbox sx={{
             color: "#F6B825",
              "&.Mui-checked": {
                color: "#F6B825",
              },
            }} 
        checked={selectAll} onChange={handleSelectAll} className="text-[16px]" />
              <p className="font-normal text-[16px]">{language.select_all}</p>
            </div>
            <button
              onClick={() => {
                setRestoreModel(true);
                setDeleteAll(true)
              }}
              className={`!bg-gradient-to-r text-[16px] from-[#FFD87A] via-[#F6B825] to-[#F6B825] text-black h-[35px] px-2 py-1 rounded font-normal ${selectedHisabs.length === 0 &&  "cursor-not-allowed blur-[1px] from-[#e9ce8e] via-[#f0d79d] to-[#ecc874]"}`}
              style={{
                background:
                selectedHisabs.length > 0
                    ? "linear-gradient(45deg, #EDB61E, #FFA500), linear-gradient(135.53deg, #FFD87A 2.61%, #F6B825 96.58%)"
                    : "linear-gradient(0deg, #EDB61E, #EDB61E), linear-gradient(135.53deg, #FFD87A 2.61%, #F6B825 96.58%)",
                color:"#000", // Apply a lighter/disabled color when inactive
                opacity: selectedHisabs.length > 0 ? 1 : 0.6, // Adjust opacity for disabled effect
              }}
              disabled={selectedHisabs.length === 0}
            >
              {language.delete_Hisab}
            </button>
          </div>
        )}

        {isLoading ? (
          <div className="flex h-[70vh] items-center justify-center">
          <Loader width={40} height={40} color={"black"} />

          </div>
        ) : (
          <div
            id="scrollableDiv"
            style={{
              height: "full",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
            className={deletedHisab.length < 3 ? "" : "custom-scrollbar"}
          >
            <InfiniteScroll
              className="pl-[12px] pr-[16px]"
              dataLength={deletedHisab.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={
                <div className="flex justify-center mt-1">
                  <Loader width={40} height={40} color={"black"} />
                </div>
              }
              // endMessage={<p className="text-center mt-5">No Data</p>}
              scrollableTarget="scrollableDiv"
              style={{ height: "unset", overflow: "unset" }}
            >
              {deletedHisab.length > 0 ? (
                deletedHisab.map((item, index) => (
                  <div className="w-[100%] flex border mt-4 rounded-[5px]">
                    <Checkbox
                    sx={{
                      color: "#F6B825",
                      "&.Mui-checked": {
                        color: "#F6B825",
                      },
                    }}
                      checked={selectedHisabs.includes(item.hisab_id)}
                      onChange={() => handleSelectHisab(item.hisab_id)}
                      className="hover:bg-transparent"
                    />
                    <div key={index} className="w-[98%] p-[7px] rounded-[8px]">
                      <div className="flex justify-between max-sm:mr-9">
                        <p className="font-medium text-[14px]">{item.hisab_name}</p>
                        <p className="font-medium text-[14px]">{item.balance}</p>
                      </div>

                      <div className="flex items-center gap-1 justify-between">
                        <p className="font-medium text-[14px]">
                          {getOnlyDate(item.hisab_deleted_date)}
                        </p>
                        <div className="flex justify-end items-center gap-1">
                          <button
                            onClick={() => {
                              setSelectedHisabId(item.hisab_id);
                              setRestoreModel(true);
                              setIsModalView(true);
                            }}
                            className="!text-[green] !font-medium text-[12px]"
                          >
                            {language.trash_restore}
                          </button>
                          <span>|</span>
                          <button
                            onClick={() => {
                              setSelectedHisabId(item.hisab_id);
                              setRestoreModel(true);
                              setIsModalView(false);
                            }}
                            className="!text-[red] !font-medium text-[12px]"
                          >
                             {language.trash_delete}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <h2 className="text-center">
                  {errorMessage || "No hisab found"}
                </h2>
              )}
            </InfiniteScroll>
          </div>
        )}
        <CustomModal
          open={restoreModel}
          handleClose={() => {
            setRestoreModel(false);
            setIsModalView("");
          }}
          isCloseIcon={false}
          widthFor={{ xs: "90%", sm: "280px", md: "350px" }}
          height="180px"
        >
          {
            deleteAll ? (
              <div className="flex flex-col gap-4 max-768:gap-7">
              <h2 className="mt-5 font-semibold w-full text-center">
               {language.ask_Clear}
              </h2>
              <div className="flex justify-evenly max-768:gap-[15px]">
                <button
                  className="px-4 bg-gray-300 rounded w-28 h-[34px]"
                  onClick={() => setRestoreModel(false)}
                >
                 {language.no}
                </button>
                <button
                  className="px-4 !bg-gradient-to-r from-[#FFD87A] via-[#F6B825] to-[#F6B825]  text-black rounded w-28 h-[34px]"
                  onClick={() => deleteHisab()}
                >
                  {language.yes}
                </button>
              </div>
            </div>
          ) : 
           isModalView ? (
            <div className="flex flex-col gap-4 max-768:gap-7">
              <h2 className="mt-5 font-semibold w-full text-center">
                {language.ask_Restore}
              </h2>
              <div className="flex justify-evenly max-768:gap-[15px]">
                <button
                  className="px-4 bg-gray-300 rounded w-28 h-[34px]"
                  onClick={() => setRestoreModel(false)}
                >
                  {language.no}
                </button>
                <button
                  className="px-4 !bg-gradient-to-r from-[#FFD87A] via-[#F6B825] to-[#F6B825]  text-black rounded w-28 h-[34px]"
                  onClick={() => restoreHisab()}
                >
                  {language.yes}
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-[31px] max-768:gap-7">
              <h2 className="mt-5 font-semibold w-full text-center">
               {language.delete_Hisab_Msg}
              </h2>
              <div className="flex justify-evenly max-768:gap-[15px]">
                <button
                  className="px-4 bg-gray-300 rounded w-28 h-[34px]"
                  onClick={() => setRestoreModel(false)}
                >
                  {language.no}
                </button>
                <button
                  className="px-4 !bg-gradient-to-r from-[#FFD87A] via-[#F6B825] to-[#F6B825]  text-black rounded w-28 h-[34px]"
                  onClick={() => deleteHisab()}
                >
                  {language.yes}
                </button>
              </div>
            </div>
          )}
        </CustomModal>
      </div>
    </>
  );
}
export default DeletedHisab;
