import { Box } from "@mui/material";
import ReactSlick from "../components/Slider/ReactSlick";

function LeftBar() {
    return (
        <Box
            style={{ backgroundImage: `url(/assets/login/Maskgroup.png)` }}
            className="h-screen bg-cover bg-center bg-no-repeat"
        >
            <ReactSlick />
        </Box>
    )
}

export default LeftBar;
