import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Typography } from "@mui/material";
import leftIcon from "../../images/slider/bi_arrow-left.png";
import rightIcon from "../../images/slider/bi_arrow-right.png";
import { StyledEngineProvider } from '@mui/material/styles';
import slid1Image from "../../images/loginpage/image.png";
import slid2Image from "../../images/slider/slid2.png";
import slid3Image from "../../images/slider/slid3.png";
import slid4Image from "../../images/slider/slid4.png";
import slid5Image from "../../images/slider/slid5.png";

function ReactSlick() {
  const slides = [
    {
      title: "Create Hisab",
      subtitle: "of your own Choice",
      description: (
        <>
          Now <span className="text-[#FFB300] font-bold">Create</span> all your
          Hisabs on Desi Hisab. You can Manage your Personal Expenses,
          Group Expense, Personal Borrowings, Dues, Debts, and any{" "}
          <span className="text-[#FFB300] font-bold">Financial Transactions</span>{" "}
          on Desi Hisab.
        </>
      ),
      image: slid1Image,
    },
    {
      title: "Invite Friends",
      subtitle: "to Manage Hisab",
      description: (
        <>
          You can add your{" "}
          <span className="text-[#FFB300] font-bold">Friends</span> from your{" "}
          <br /> Phonebook List to contribute with you <br />{" "}
          <span className="text-[#FFB300] font-bold">Instantly</span> in your Hisab.
        </>
      ),
      image: slid2Image,
    },
    {
      title: "Keep close eye",
      subtitle: "on your Spending",
      description: (
        <>
          Your Personal and Shared Hisab <br /> Expenditures can be{" "}
          <span className="text-[#FFB300] font-bold">Tracked</span> <br /> easily
          with Desi Hisab.
        </>
      ),
      image: slid3Image,
    },
    {
      title: "Export Hisab",
      subtitle: "is quite Easy job",
      description: (
        <>
          You can <span className="text-[#FFB300] font-bold">Export</span> a report
          of your Hisab <br /> including all your Income, Expense, <br />
          Contribution with Date & Time.
        </>
      ),
      image: slid4Image,
    },
    {
      title: "End To End",
      subtitle: "Encryption Security",
      description: (
        <>
          With end to end encryption all your <br /> Hisabs,{" "}
          <span className="text-[#FFB300] font-bold">Transaction</span> and Profile
          <br /> data are{" "}
          <span className="text-[#FFB300] font-bold">Secured</span>.
        </>
      ),
      image: slid5Image,
    },
  ];

  const CustomArrow = ({ type, onClick }) => {
    return (
      <div
        className={`absolute bottom-0 w-[30px] h-[30px] md:w-[40px] md:h-[40px] 
        rounded-full flex justify-center items-center 
        ${type === "prev" ? "left-2 md:left-4" : "right-2 md:right-4"} 
        cursor-pointer z-10 transition-all duration-300`}
        onClick={onClick}
        style={{
          background: "linear-gradient(0deg, #F6B825, #F6B825), linear-gradient(135.53deg, #FFD87A 2.61%, #F6B825 96.58%)"
        }}
      >
        <img
          src={type === "prev" ? leftIcon : rightIcon}
          alt={`${type === "prev" ? "Previous" : "Next"} Arrow`}
          className="w-[15px] h-[15px] md:w-[25px] md:h-[25px]"
        />
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomArrow type="prev" />,
    nextArrow: <CustomArrow type="next" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: true
        }
      }
    ]
  };

  return (
    <Slider {...settings} className="bottom-0  slide-postion">
      {slides.map((slide, index) => (
        <Box
          key={index}
          className="flex flex-col items-center text-center"
        >
          <Box className="flex flex-col h-14 justify-end">
            <Typography className="text-[#FFB300] xl:!text-[30px] lg:!text-[20px] 
               font-bold transition-all duration-300">
              {slide.title}
            </Typography>
            <Typography className="text-black lg:text-[14px] xl:text-[20px] 
              font-medium transition-all duration-300 !mt-0">
              {slide.subtitle}
            </Typography>
          </Box >

          <img
            src={slide.image}
            alt="Illustration"
            className="mx-auto 
    object-contain transition-all duration-300 xl:w-[400px] lg:w-[260px]"
          />
          <Typography
            variant="span"
            className="text-[#282828] mt-[20px] block xl:!text-[16px] lg:!text-[14px] text-sm md:text-base lg:text-xl 
    font-normal  mx-auto transition-all 
    duration-300 break-words 
   max-1097:!w-[356px] lg:!leading-4 xl:!leading-5 md:!max-w-[341px]  sm:h-auto"
          >
            {slide.description}
          </Typography>

        </Box>
      ))}
    </Slider>
  );
}

export default ReactSlick;
