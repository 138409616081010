import OTPInput from "react-otp-input";

function Otp({
  otp,
  setOtp,
  inputsNum,
  width,
  height,
  marginRight,
  fontSize,
  fontWeight,
  onKeyDown,
}) {
  const handleInputKeyDown = (e) => {
    const inputs = e.target.parentElement.getElementsByTagName("input");
    const currentIndex = Array.from(inputs).indexOf(e.target);

    if (e.key === "Backspace") {
      e.preventDefault();
      const newOtp = otp.slice(0, currentIndex) + otp.slice(currentIndex + 1);
      setOtp(newOtp);
      
      if (currentIndex > 0) {
        setTimeout(() => {
          inputs[currentIndex - 1].focus();
        }, 0);
      }
    }
    
    // Handle arrow keys
    else if (e.key === "ArrowLeft") {
      e.preventDefault();
      if (currentIndex > 0) {
        inputs[currentIndex - 1].focus();
      }
    }
    else if (e.key === "ArrowRight") {
      e.preventDefault();
      if (currentIndex < inputsNum - 1) {
        inputs[currentIndex + 1].focus();
      }
    }

    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  return (
    <div>
      <OTPInput
        value={otp}
        onChange={setOtp}
        numInputs={inputsNum}
        isInputNum={true}
        shouldAutoFocus={true}
        renderSeparator={false}
        renderInput={(props, index) => (
          <input
            {...props}
            type="tel"
            pattern="[0-9]*"
            maxLength={1}
            onKeyDown={handleInputKeyDown}
            style={{
              width: `${width}`,
              height: `${height}`,
              textAlign: "center",
              fontSize: `${fontSize}px`,
              border: "1px solid #EEF0F2",
              borderRadius: "4px",
              outline: "none",
              fontWeight: `${fontWeight}`,
              marginRight: index === inputsNum - 1 ? "0px" : `${marginRight}`,
              transition: "border-color 0.3s ease",
            }}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
            }}
          />
        )}
      />
    </div>
  );
}

export default Otp;