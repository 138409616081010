import { toast } from "react-toastify";
import Loader from "../components/Loader/Loader";
import React, { useEffect, useState } from "react";
import { Avatar, Button, Grid } from "@mui/material";
import { apiService } from "../app/services/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { decryptAES, encryptAES } from "../components/CommonLibrary";
import MockUP from "../UI/MockUP";
import { useSelector } from "react-redux";
import plus from "../images/hisabdetails/plus.png"

function TransactionDetails() {
  const { id } = useParams();
  let decryptedID;
  if (id){
     decryptedID = decryptAES(id);
  }
  const urlObject = JSON.parse(decryptedID ? decryptedID : null);
  const navigate = useNavigate();
  const number = urlObject?.id;
  const [isLoading, setIsLoading] = useState(false);
  const [hisabDetailsData, setHisabDetailsData] = useState([]);
  const language = useSelector((state) => state.usersLoginData.allLanguage);
  const [hisabStatus , setHisabStatus] = useState("");
  const userData = useSelector((state) => state.usersLoginData.loginUserDetails);
  const [permissionToAdd ,setPermissionToAdd ] = useState(false);


  useEffect(() => {
    if (number) {
      getDetails();
      getHisabDetails();
    }
  //   else {
  //     navigate("/AllHisab/:id");
  //   }
  }, [number]);


   async function getHisabDetails() {
      setIsLoading(true);
      try {
        const tokenUrl = localStorage.getItem("accessToken");
        const url =
          process.env.REACT_APP_API_BASE_URL + "/hisab/get_hisab_detail";
        const body = {
          hisab_id: number,
          page_no: 1,
        };
        const encryptedData = encryptAES(JSON.stringify(body));
        const formData = new FormData();
        formData.append("data", encryptedData);
        const res = await apiService(url, "POST", formData, tokenUrl);
        const decryptedResponse = decryptAES(res.data.response);
        if (
          JSON.parse(decryptedResponse).status === 200 ||
          JSON.parse(decryptedResponse).status === "200"
        ) {
          toast.success(JSON.parse(decryptedResponse).message);
          setIsLoading(false);
          const data = await JSON.parse(decryptedResponse);
          setHisabStatus(data?.hisab_data?.hisab_status)
          
        } else {
          toast.error(JSON.parse(decryptedResponse).message);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("error", error);
        setIsLoading(false);
      }
    }
  async function getDetails() {
    setIsLoading(true);
    try {
      const tokenUrl = localStorage.getItem("accessToken");
      const url =
        process.env.REACT_APP_API_BASE_URL + "/transaction/list_transaction";
      const body = {
        hisab_id: number,
        page_no: 1,
      };
      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);
      const res = await apiService(url, "POST", formData, tokenUrl);
      const decryptedResponse = decryptAES(res.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        toast.success(JSON.parse(decryptedResponse).message);
        const data = await JSON.parse(decryptedResponse);
        setHisabDetailsData(data);
        setIsLoading(false);
      } else {
        toast.error(JSON.parse(decryptedResponse).message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("error", error);
      setIsLoading(false);
    }
  }

  const exportHisab = async (id) => {
    setIsLoading(true);
    try {
      const tokenUrl = localStorage.getItem("accessToken");
      const url =
        process.env.REACT_APP_API_BASE_URL + "/transaction/get_pdf_transaction";
      const body = {
        hisab_id: id,
      };
      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);
      const res = await apiService(url, "POST", formData, tokenUrl);
      const decryptedResponse = decryptAES(res.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        toast.success(JSON.parse(decryptedResponse).message);
        setIsLoading(false);
        const data = await JSON.parse(decryptedResponse);
        const pdfUrl = process.env.REACT_APP_API_BASE_URL + "/" + data.pdf_link;
        window.open(pdfUrl, "_blank");
      } else {
        // toast.error(JSON.parse(decryptedResponse).message);
      }
    } catch (error) {
      console.error("error", error);
    }
};

  const handleNavigate = (number) =>{
    const encryptedNumber = encryptAES(JSON.stringify({id : number}));
    const encodedDataNumber = encodeURIComponent(encryptedNumber);
    navigate(`/Details/${encodedDataNumber}`);
  }
  const findModeTransactions = (item) => {
    let found = language.payment_mode_list[0].val1.find(
      (element) => element.id === item?.transaction_mode
    );
    return found?.title;
  };

  useEffect(() => {
    const participant = hisabDetailsData.participant_user?.find(item => item.id == userData.user_id);
    if (participant && participant.permission === 'edit') {
      setPermissionToAdd(true);
    }
    return () => {
      setPermissionToAdd(false);
    };
  }, [hisabDetailsData, userData.user_id]);

  return (
    <>
      <div className="!w-[100%] h-[100%] flex !max-h-[100vh] overflow-y-hidden">
        {id && id.length > 4 && hisabDetailsData ? (
          <div className="w-[100%] overflow-y-hidden">
            {isLoading ? (
              <div className="flex items-center !justify-center !w-[100%] !h-[100vh]">
                <Loader width={40} height={40} color={"black"} />
              </div>
            ) : (
              <div className="box-border flex flex-col items-center justify-center !gap-2">
                  {/* Header Section */}
                  <Grid container className="border-b py-2 ">
                    <Grid item xs={6} className="flex items-center">
                      <span className="text-[20px] font-[500] font-sans max-768:text-[18px] pl-[18px]">{hisabDetailsData.hisab_name}</span>
                    </Grid>
                    <Grid item xs={6} md={6} className="flex justify-end pt-[8px] pl-3 md:pl-0 pr-[18px]">
                    {/* <Grid item xs={6} md={3} className="flex justify-center mr-1">
                        <button
                          className={`!bg-[#46A759] w-[100%] mt-[3px] md:!mt-0 !text-[#FFFFFF] text-[10px] h-[30px] pl-[3px] pr-[3px] rounded-[3px] !capitalize md:text-[13px] md:h-[34px] max-w-[120px]  ${hisabStatus==="complete" || !permissionToAdd ?"blur-[0.5px] opacity-[0.4]":"filter-none"}`}
                          onClick={() => {
                            const encryptedData = encryptAES(
                              JSON.stringify({
                                id: hisabDetailsData?.transactions_data[0]
                                  ?.hisab_id,
                                
                              })
                            );
                            const encryptedData1 = encryptAES(
                              JSON.stringify({
                                hisabType: "Income",
                              })
                            );
                            const encodedData = encodeURIComponent(encryptedData);
                            const encodedData1 = encodeURIComponent(encryptedData1);
                            navigate(`/AddIncome/${encodedData}/${encodedData1}`);
                          }}
                          disabled={hisabStatus==="complete" ? true:false || !permissionToAdd}
                        >
                          {language.add_Income}
                        </button>
                      </Grid>
                      <Grid item xs={6} md={3} className="max-768:mt-[3px]">
                        <button
                          className={`!bg-[#E5474D] w-[100%] !text-[#FFFFFF] text-[9px] h-[30px] pl-[3px] pr-[3px] rounded-[3px] !capitalize md:text-[13px] md:h-[34px] max-w-[120px] ${hisabStatus==="complete" || !permissionToAdd ?"blur-[0.5  px] opacity-[0.4]":"filter-none"}`}
                          onClick={() => {
                            const encryptedData = encryptAES(
                              JSON.stringify({
                                id: hisabDetailsData?.transactions_data[0]
                                  ?.hisab_id,
                              })
                            );
                            const encryptedData1 = encryptAES(
                              JSON.stringify({
                                hisabType: "Expense",
                              })
                            );
                            const encodedData = encodeURIComponent(encryptedData);
                            const encodedData1 = encodeURIComponent(encryptedData1);
                            navigate(`/AddIncome/${encodedData}/${encodedData1}`);
                          }}
                          disabled={hisabStatus==="complete" ? true:false || !permissionToAdd}
                        >
                         {language.add_Expenses}
                        </button>

                      </Grid> */}
                    </Grid>
                  </Grid>
                {/* Income/Expense/Balance Section */}
                <Grid
                  container
                  spacing={2}
                  className="!w-[96%] bg-[#f6f5e8] my-1 rounded-[8px] max-1030:h-[auto] p-2 !ml-0"
                  style={{
                    background:
                      "linear-gradient(135.53deg, rgba(255, 216, 122, 0.1) 100.61%, rgba(246, 184, 37, 0.1) 0.58%)",
                  }}
                >
                  {/* Left 6 Columns */}
                  <Grid item xs={12} md={6} className="flex flex-col gap-4 !pt-1 !pl-1">
                    <Grid container spacing={1}>
                      <Grid item xs={4} md={4}>
                        <section className="flex flex-col items-center">
                          <p className="text-[16px] text-[#8A8D93] font-normal max-480:text-[12px]">
                          {language.income}
                          </p>
                          <p className="text-[#46A759] text-[12px] font-bold max-480:text-[12px]">
                            {/* <CurrencyRupeeIcon className="h-2 w-2" /> */}
                            ₹
                            {hisabDetailsData.total_income}
                          </p>
                        </section>
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <section className="flex flex-col items-center justify-center">
                          <p className="text-[16px] text-[#8A8D93] max-480:text-[12px]">
                          {language.expense}
                          </p>
                          <p className="text-[#E5474D] text-[12px] font-bold max-480:text-[12px]">
                            {/* <CurrencyRupeeIcon className="h-2 w-2" /> */}
                            ₹
                            {hisabDetailsData.total_expense}
                          </p>
                        </section>
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <section className="flex flex-col items-center justify-center">
                          <p className="text-[16px] text-[#8A8D93] max-480:text-[12px]">
                          {language.balance}
                          </p>
                          <p className="text-[#424242] text-[12px] font-bold max-480:!text-[12px]">
                            {hisabDetailsData?.total_amount ? (
                              <>
                                <span className="inline-block mr-1">{hisabDetailsData.total_amount.charAt(0)}</span>
                                <span>{hisabDetailsData.total_amount.slice(1)}</span>
                              </>
                            ) : (
                              '₹0.00'
                            )}
                          </p>
                        </section>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Right 6 Columns */}
                  <Grid item xs={12} md={6} className="flex flex-col justify-center gap-4 !pt-1 !pl-1">
                    <Grid container spacing={1}>
                      <Grid item xs={12} className="!flex !items-center justify-end !pt-1 max-768:justify-center">
                        <button onClick={() => exportHisab(number)} className="!capitalize !bg-gradient-to-r h-[30px] w-[100%] px-2 rounded-[3px] from-[#FFD87A] via-[#F6B825] to-[#F6B825] !text-[14px] ! !text-[#424242] max-480:!text-[12px] md:!h-[34px] md:!w-[106px] md:!text-[14px] max-768:w-[80%] max-768:mt-3 max-768:mb-1">
                        {language.export_Hisab}
                        </button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>


                {/* Recent Transactions Section */}
                <div className="w-full items-center  pb-9 transactionscrollbar">
                  <section className="flex justify-between items-center px-[17px] max-480:p-3">
                    <h2 className="text-[14px] font-bold md:text-[16px]">
                    {language.recent_Transactions}
                    </h2>
                    <div className="flex items-center justify-center">
                      <Button onClick={
                        ()=>{
                          const encryptedId = encryptAES(JSON.stringify({ id: number }));
                          const encodedData = encodeURIComponent(encryptedId);
                          navigate(`/ViewAllTransaction/${encodedData}`)
                          
                        }} className="!text-[#F5B820] hover:bg-transparent text-[12px] !text-[500] !w-[65px] !h-[23px] !p-0 !capitalize max-480:!text-[12px]">
                         {language.view_all_rewards}
                      </Button>
                      <p className="!text-[#F5B820]">|</p>
                      <Button className="!text-[#F5B820] hover:bg-transparent text-[12px] !capitalize !w-[65px] !h-[23px] !p-0 max-480:!text-[12px]" onClick={()=> handleNavigate(number)}>
                      {language.hisab_details}
                      </Button>
                    </div>
                  </section>

                  {/* Transactions List */}
                  <section className="flex flex-col items-center w-full gap-3 mt-2 mb-5">
                    {hisabDetailsData &&
                      hisabDetailsData.transactions_data?.map((item) => (
                        <div className="w-[100%] flex flex-col items-center cursor-pointer" onClick={()=>{
                          const encryptedId = encryptAES(JSON.stringify({id : item?.id}));
                          const encryptedNumber = encryptAES(JSON.stringify({id : number}));
                          if(item.id  && encryptedId){
                            const encodedData = encodeURIComponent(encryptedId);
                            const encodedDataNumber = encodeURIComponent(encryptedNumber);
                            {item.transaction_title === "Opening Balance" ? 
                            navigate(`/Details/${encodedDataNumber}`) :
                            navigate(`/ViewTransaction/${encodedData}`)
                            }
                          }
                        }}>
                          <div
                            key={item.id}
                            className="flex w-[96%] gap-1 border-[#ddd] border items-center pl-2 pr-2 rounded-lg py-3"
                          >
                            <Avatar
                              className="!h-[25px] !w-[25px] text-[14px] md:!h-[40px] md:!w-[40px]"
                              alt="Remy Sharp"
                              src={`${process.env.REACT_APP_API_BASE_URL}/uploads/user/${item.user_id}/${item.profile_photo}`}
                            />
                            <div className="flex items-center flex-col w-[100%]">
                              <section className="flex justify-between w-[98%] mb-2">
                                <p className="font-medium text-[14px] max-480:text-[12px]">
                                  {item.transaction_title}
                                </p>
                                <p
                                  className={`${item.transaction_type === "income"
                                      ? "text-[#46A759]"
                                      : "text-[#E5474D]"
                                    } font-bold text-[14px] max-480:text-[10px]`}
                                >
                                  {item.transaction_type === "income" ? "+" : "-"}{" "}
                                  {item.transaction_amount}
                                </p>
                              </section>
                              <section className="flex justify-between w-[98%] items-center">
                                <div className="flex gap-2 items-center">
                                  <p className="bg-[#F6B82547] text-[12px] px-[6px] rounded-[2px] p-[3px] max-480:text-[10px]">
                                    {item?.first_name} {item?.last_name}
                                  </p>
                                  {item.transaction_title !== "Opening Balance" && <p className="bg-[#92CDFE47] text-[12px] px-[6px] rounded-[2px] p-[3px] max-480:text-[10px]">
                                    {findModeTransactions(item)}
                                  </p>}
                                </div>
                                <p className="text-[12px] text-[#B1B1B1] max-480:text-[10px]">
                                  {item.transaction_date}
                                </p>
                              </section>
                            </div>
                          </div>
                        </div>
                      ))}
                  </section>
                </div>
                {hisabDetailsData.user_permission === "edit" ? (
                  <Button
                    className="fixed right-[15px] bottom-[3px] max-768:right-[3px]"
                    onClick={() => {
                      const encryptedData = encryptAES(
                        JSON.stringify({
                          id: hisabDetailsData?.transactions_data[0]?.hisab_id,
                        })
                      );
                      const encryptedData1 = encryptAES(
                        JSON.stringify({
                          hisabType: "Expense",
                        })
                      );
                      const encodedData = encodeURIComponent(encryptedData);
                      const encodedData1 = encodeURIComponent(encryptedData1);
                      navigate(`/AddIncome/${encodedData}/${encodedData1}`);
                    }}
                  >
                    <img
                      src={plus}
                      alt="plus_image"
                      className="w-[50px] max-424:w-[40px]"
                    />
                  </Button>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        ) : (
          <MockUP/>
        )}
      </div>
    </>
  );
}
export default TransactionDetails;
