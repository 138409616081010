import React, { useEffect, useState } from "react";
import { apiService } from "../app/services/api";
import { decryptAES, encryptAES } from "../components/CommonLibrary";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../components/Loader/Loader";
import { Avatar } from "@mui/material";
import { setNotificationLength } from "../app/Redux/reducer";

const formatDate = {
  getFormattedDate: () => {
    const d = new Date();
    return d.toISOString().split("T")[0];
  },
};
const formatCustomDate = (dateString) => {
  const months = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };

  const [date] = dateString.split(" ");
  const splitMonth = dateString.split(",")[0];
  const splitYear = dateString.split(",")[1];
  const NotiMonth = splitMonth.split(" ")[1];
  const NotificationYear = splitYear.split(" ")[0];
  const [day, month, year] = date.replace(",", "").split(" ");

  return `20${NotificationYear}-${months[NotiMonth]}-${day}`;
};

export default function Notification() {
  const [allNotification, setAllNotification] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [systemPath, setsystemPath] = useState("");
  const [userThumbnailPath, setUserThumbnailPath] = useState("");
  const [notifications, setNotifications] = useState({
    today: [],
    yesterday: [],
    thisWeek: [],
    older: [],
  });
  const language = useSelector((state) => state.usersLoginData.allLanguage);  

  const dispatch = useDispatch();

  useEffect(() => {
    const newNotifications = {
      today: [],
      yesterday: [],
      thisWeek: [],
      older: [],
    };
    allNotification.map((item) => {
      const date = item.created_at.split(" ").slice(0, 2).join(" ");
      const createdAt = formatCustomDate(date);
      const todayDate = formatDate.getFormattedDate();
      let date1 = new Date(createdAt);
      let date2 = new Date(todayDate);
      let Difference_In_Time = date2.getTime() - date1.getTime();
      let Difference_In_Days = Math.round(
        Difference_In_Time / (1000 * 3600 * 24)
      );
      if (Difference_In_Days === 0) {
        newNotifications.today.push(item);
      } else if (Difference_In_Days <= 1) {
        newNotifications.yesterday.push(item);
      } else if (Difference_In_Days <= 7) {
        newNotifications.thisWeek.push(item);
      } else {
        newNotifications.older.push(item);
      }
    });
    setNotifications((prevNotifications) => ({
      ...prevNotifications,
      ...newNotifications,
    }));
  }, [allNotification]);

  const userId = useSelector(
    (state) => state.usersLoginData.loginUserDetails.user_id
  );
  const tokenUrl = localStorage.getItem("accessToken");
  useEffect(() => {
    fetchNotification();
  }, []);

  const fetchNotification = async () => {
    setIsLoading(true);
    try {
      const url =
        process.env.REACT_APP_API_BASE_URL + "/notification/get_notifications";
      const body = {
        user_id: userId,
        page_no: 1,
      };
      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);
      const res = await apiService(url, "POST", formData, tokenUrl);
      const decryptedResponse = decryptAES(res.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        toast.success(JSON.parse(decryptedResponse).message);
        const data = await JSON.parse(decryptedResponse);
        dispatch(setNotificationLength(data?.notifications_data?.length))
        setsystemPath(data.system_path);
        setUserThumbnailPath(data.user_thumbnail_path);
        setAllNotification(data.notifications_data);
        setIsLoading(false);
      } else {
        toast.error(JSON.parse(decryptedResponse).message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  return (
    <div className="w-[100%] h-[100vh] overflow-auto notification-scrollbar">
      {isLoading ? (
        <div className="flex items-center !justify-center !w-[96%] !h-[70vh]">
          <Loader width={40} height={40} color={"black"} />
        </div>
      ) : allNotification.length > 0 ? (
        // Render the content when there are notifications
        <div className="w-[98%] flex flex-col justify-center items-center pl-[5px] gap-4">
          {/* <h2 className="text-[20px] font-medium w-[96%] text-left">
            Notification
          </h2> */}
          {[
            { title: language.today, data: notifications.today },
            { title: language.yesterday, data: notifications.yesterday },
            { title: language.this_week, data: notifications.thisWeek },
            { title: language.older, data: notifications.older },
          ].map(
            ({ title, data }) =>
              data.length > 0 && (
                <section className="w-[96%]" key={title}>
                  <h1 className="text-[16px] w-full font-bold text-left ">
                    {title}
                  </h1>
                  <div
                    className="border-[1px] border-solid border-[#F5F5F5] bg-white rounded-[5px] mt-[5px]"
                    style={{ boxShadow: "0px 1px 4px 1px #00000014" }}
                  >
                    {data.map((item) => {
                      let NAME, HISAB, start_Message, last_Message;
                      let FinalNotification;
                      if (item.sender_type === "user") {
                        NAME = item.message_with_value.NAME;
                        HISAB = item.message_with_value.HISAB;
                        start_Message = item.message_with_key
                          .split("{NAME}")[1]
                          ?.split("{HISAB}")[0]
                          ?.trim();
                        last_Message =
                          item.message_with_key.split(`{HISAB}`)[1];
                        FinalNotification = (
                          <>
                            <strong>{item.message_with_value.NAME}</strong>{" "}
                            {start_Message}{" "}
                            <strong>{item.message_with_value.HISAB}</strong>
                            <strong>{last_Message}</strong>
                          </>
                        );
                      }
                      return (
                        <div
                          className="flex items-center justify-start p-2 gap-[10px]"
                          key={item.id}
                        >
                          <Avatar
                            src={`${process.env.REACT_APP_API_BASE_URL}/${item.sender_type === "user" ? `uploads/user/${item.sender_id}` : systemPath}/${item.profile_photo}`}
                          />
                          <div>
                            <h2 className="text-[14px] mt-2">
                              {item.sender_type === "user"
                                ? FinalNotification
                                : item.message}
                            </h2>
                            <h3 className="text-[12px] font-normal">
                              {item.created_at.split(" ")[2] +
                                " " +
                                item.created_at.split(" ")[3]}
                            </h3>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </section>
              )
          )}
        </div>
      ) : (
        <div className="flex items-center justify-center text-[16px] h-[100%]">No notifications available</div>
      )}
    </div>
  );
}
