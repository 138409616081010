import NotesImage from "../../images/sideBar/Paper.png";
import TrashImage from "../../images/sideBar/trash.png";
import CustomerCareImage from "../../images/sideBar/support1.png";
import QrCodeImage from "../../images/sideBar/qr-code.png";
import profileicon from "../../images/sideBar/profile1.png";

const icons = ({language, encodedData}) => [
  {
    id: 1,
    image: NotesImage,
    path: window.innerWidth >991 ?  `/AllHisab/${encodedData}` : '/AllHisab/:id=',
    label: language?.filter_name_1,
  },
  {
    id: 2,
    image: TrashImage,
    path:  window.innerWidth >991 ?  `/DeletedHisab/${encodedData}` : '/DeletedHisab/:id=',
    label: language?.trash_Hisab,
  },
  {
    id: 3,
    image: CustomerCareImage,
    path: window.innerWidth >991 ?  `/HelpCenter/${encodedData}` : '/HelpCenter/:id=',
    label: language?.support,
    subPaths: ["/HelpCenter/:id=", "/ReportIssue", "/FAQ"]
  },
  // {
  //   id: 4,
  //   image: QrCodeImage,
  //   path: "/Referal",
  //   label: language?.invite_Desi_Hisab,
  // },
  {
    id: 5,
    path:  window.innerWidth >991 ?  `/account/${encodedData}` : '/account/:id=',
    image: profileicon,
    label: language?.my_account,
    subPaths: ["/account", "/profile","/currentmpin", "/newpinset", "/numberchange", "/language", "/deleteaccount"]
  },
];
// window.innerWidth >768 ? "/profile" :
export default icons;
