import React, { useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import BackArrow from "../images/hisabdetails/LeftArrow.png";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import { decryptAES, encryptAES } from "../components/CommonLibrary";
import { apiService } from "../app/services/api";
import Loader from "../components/Loader/ButtonLoader";
import CustomModal from "../components/CommonModal/CustomModal";
import AddImageIcon from "../images/Income/AddImageIcon.png";
import CloseIcvon from "../images/Income/close-square.png";



const ReportIssue = () => {
  const userLoginData = useSelector((state) => state.usersLoginData.getUser);
  const [category, setCategory] = useState("Issue");
  const fileInputRef = useRef();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessMessage, setIsSuccessMessage] = useState("");
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const language = useSelector((state) => state.usersLoginData.allLanguage);  


  const [formState, setFormState] = useState({
    actualImages: [],
    deletedImages: [],
    fileError: "",
    imageError: "",
  });

  const {
    actualImages,
    deletedImages,
  } = formState;

  const updateFormState = (updates) => {
    setFormState((prev) => ({ ...prev, ...updates }));
  };
  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleReport = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsBtnDisabled(true);
    const parameters = {
      user_id: userLoginData?.id,
      name: userLoginData?.first_name,
      mobile_number: userLoginData?.mobile_number,
      category: category,
      subject: subject,
      message: message,
      user_from: "Website",
    };
    try {
      const url = process.env.REACT_APP_API_BASE_URL + "/support/add_support";
      const tokenUrl = localStorage.getItem("accessToken");
      const encryptedData = encryptAES(JSON.stringify(parameters));
      const formData = new FormData();
      formData.append("data", encryptedData);
      formState.actualImages.forEach((file) =>
        formData.append("images[]", file)
      );
      const result = await apiService(url, "POST", formData, tokenUrl);
      const decryptedResponse = decryptAES(result.data.response);
      if (
        JSON.parse(decryptedResponse).status === "200" ||
        JSON.parse(decryptedResponse).status === 200
      ) {
        setIsSuccessMessage(JSON.parse(decryptedResponse).message);
        setIsLoading(false);
        setIsBtnDisabled(false);
        setOpen(true);
        // navigate(-1);
        setCategory("");
        setSubject("");
        setMessage("");
      } else {
        setIsLoading(false);
        setIsBtnDisabled(false);
        setIsSuccessMessage(JSON.parse(decryptedResponse).message);
      }
    } catch (error) {
      setIsBtnDisabled(false);
      console.error("error", error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleReport(e);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["image/png", "image/jpg", "image/jpeg"];
      if (!allowedTypes.includes(file.type)) {
        updateFormState({
          fileError: "Please upload a valid image (png, jpg, jpeg).",
        });
        return;
      } else if (file.size >= 5000000) {
        updateFormState({ fileError: "Please upload an image less than 5 MB" });
        return;
      }
      updateFormState({
        actualImages: [...actualImages, file],
        fileError: "",
      });
    }
  };
  const handleRemoveImage = (image) => {
    const updatedImages = actualImages.filter((img) => img !== image);
    updateFormState({
      actualImages: updatedImages,
      ...(image.id && {
        deletedImages: [...deletedImages, image.id],
      }),
    });
  };

  return (
    <div>
      <div className="flex border-b items-center">
        <button
          className="p-2 max-480:w-[46px]"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img alt="backImage" src={BackArrow}></img>
        </button>
        <h1 className="text-[20px] font-[500] font-sans max-768:text-[18px]">
         {language.report_issue}
        </h1>
      </div>

      <div className="w-[100%] flex flex-col justify-center items-center mt-3">
        <div className="w-[87%]">
          <FormControl fullWidth required>
            <InputLabel
              id="category-label"
              sx={{
                color: "gray",
                "& .MuiInputLabel-asterisk": { color: "red", fontSize: "16px" },
              }}
            >
              {language.form_categeory}
            </InputLabel>
            <Select
              labelId="category-label"
              id="category"
              value={category}
              label="Category"
              // className="text-[14px]"
              onChange={(e) => setCategory(e.target.value)}
            >
              <MenuItem value="Issue" className="text-[14px]">{language.issue}</MenuItem>
              <MenuItem value="Suggestion" className="text-[14px]">{language.suggestion}</MenuItem>
            </Select>
          </FormControl>

          <Box sx={{ position: "relative", mt: 2 }}>
            <TextField
              id="subject"
              label={language.form_subject}
              variant="outlined"
              fullWidth
              required
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              onKeyDown={handleKeyDown}
              inputProps={{
                maxLength: 100,
              }}
              sx={{
                "& .MuiInputLabel-root": { color: "gray" },
                "& .MuiInputLabel-asterisk": { color: "red", fontSize: "16px" },
                "& .MuiInputBase-input": { width: "92%" },
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
                fontSize: "12px",
                color: "gray",
              }}
            >
              {subject?.length}/100
            </Box>
          </Box>

          <Box sx={{ position: "relative", mt: 2 }}>
            <TextField
              id="message"
              label={language.form_message}
              variant="outlined"
              fullWidth
              required
              multiline
              rows={6}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={handleKeyDown}
              inputProps={{
                maxLength: 250,
              }}
              sx={{
                "& .MuiInputLabel-root": { color: "gray" },
                "& .MuiInputLabel-asterisk": { color: "red", fontSize: "16px" },
                "& .MuiOutlinedInput-root": {
                  // height: "100px", // Set the height to 100px
                },
              }}
            />

            <Box
              sx={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
                fontSize: "12px",
                color: "gray",
              }}
            >
              {message?.length}/250
            </Box>
          </Box>
          <div className="w-[100%] max-680:w-[98%] max-680:h-[50px] rounded-sm flex items-center border hover:border-black border-grove bg-[white] mt-3 py-[8px]">
                <label
                  htmlFor="file-upload"
                  className="btn-custom bg-gray-200 ml-2 rounded-[1.375rem] text-[14px] p-2 h-[2.6rem] flex items-center cursor-pointer"
                >
                  Upload Image
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={handleFileChange}
                  className="hidden" 
                  ref={fileInputRef}
                  disabled={actualImages.length >= 5}
                />
              </div>

              <div
              className={`w-full !gap-[4%] overflow-auto flex justify-start flex-nowrap mt-3 mb-3 border-solid border-[2px] border-[#ddd] rounded-md p-3 bg-[white] ${actualImages.length > 0 ? "" : "hidden"}`}
            >
              <div
                className={` cursor-pointer flex items-center justify-center flex-shrink-0 w-[100px] h-[100px] border-dashed border-[#F5B820] border-[2px] ${actualImages.length > 4 ? "hidden" : ""}`}
                onClick={handleImageClick}
              >
                <img
                  src={AddImageIcon}
                  className="w-[30%] h-[30%]"
                  alt="add icon"
                ></img>
              </div>
              {actualImages.map((item) => (
                <div className="!relative flex-shrink-0 mr-[20px]">
                  <img
                    className="w-[100px] h-[100px] rounded"
                    src={
                      // item?.support_images
                      //   ? `${process.env.REACT_APP_API_BASE_URL}/uploads/supports/${item.support_images}`
                      //   : 
                        item ? URL.createObjectURL(item): ""
                    }
                    alt={`preview-${item}`}
                  />
                  <Button
                    className="!absolute !top-0 !right-0 !z-10 !w-[20px] !justify-end"
                    onClick={() => {
                      handleRemoveImage(item);
                    }}
                  >
                    <img
                      src={CloseIcvon}
                      alt="close"
                      className="w-[28px] h-[28px]"
                    />
                  </Button>
                </div>
              ))}
            </div>
          <div className="">
            <Button
              className={`w-[100%] !mt-[20px] h-[58px] rounded-[5px] text-[18px] font-medium ${
                subject?.trim()?.length > 0 && message?.trim()?.length > 0
                  ? "bg-gradient-to-r from-[#FFD87A] to-[#F6B825] !text-black !font-semibold"
                  : "!bg-[#e2b54b] opacity-[75%] !text-black !font-semibold"
              }`}
              disabled={
                subject?.trim()?.length > 0 && message?.trim()?.length > 0
                  ? isBtnDisabled
                  : true
              }
              onClick={handleReport}
            >
              {isLoading === true ? (
                <Loader width={20} height={20} color={"black"} />
              ) : (
                language.submit
              )}
            </Button>
          </div>
        </div>

        <CustomModal
          open={open}
          handleClose={() => {
            setOpen(false);
          }}
          isCloseIcon={false}
          width="280px"
          height="250px"
        >
          <div className="flex justify-center items-center flex-col h-full">
            {/* <p className="text-center font-semibold text-3xl">Desi Hisab</p> */}
            <p className="text-center text-[20px] pb-16">{isSuccessMessage}</p>
            <NavLink
              className="bg-yellow-400 font-semibold  text-[14px] h-[40px] w-[100%] py-2 rounded-md transition-colors text-center"
              to="/"
            >
              Okay
            </NavLink>
          </div>
        </CustomModal>
      </div>
    </div>
  );
};

export default ReportIssue;
