import React, { useEffect, useState } from "react";
import Header from "../Header/header";
import { useLocation } from "react-router-dom";
import AllHisab from "../../pages/AllHisab";
import DeletedHisab from "../../pages/DeletedHisab";
import MyAccount from "../../pages/MyAccount";
import CustomerCare from "../../pages/CustomerCare";
import Notification from "../../pages/Notification";

function HisabDashboard() {
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const myAccountPaths = [
    "/account",
    "/profile",
    "/currentmpin",
    "/newpinset",
    "/numberchange",
    "/deleteaccount",
    "/language"
  ];

  const customerCarePaths = [
   "/HelpCenter",
   "/ReportIssue",
   "/FAQ",
   "/ContactUs",
   "/supportTickets"
  ]  
  
  const customerCarePage = customerCarePaths.includes(location.pathname);
  const isMyAccountPage = myAccountPaths.includes(location.pathname);

  const containerClassName = `max-991:!w-[100%] ${
    isMyAccountPage ? "" : "flex"
  } flex-col  relative`;

  let content;
  if (location.pathname.includes("DeletedHisab")) {
    content = <DeletedHisab />;
  } else if (isMyAccountPage || location.pathname.includes("account")) {
    content = <MyAccount />;
  }else if (customerCarePage || location.pathname.includes("HelpCenter")) {
    content = <CustomerCare />;
  }else if (location.pathname.includes("Notification")){
    content = <Notification />;
  }else {
    content = <AllHisab />;
  }

  return (
    <div className={containerClassName}>
      {windowWidth > 991 && <Header />}
      {content}
    </div>
  );
}

export default HisabDashboard;
