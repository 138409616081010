import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import deleteImage from "../images/myaccount/delete-account1.png";
import { apiService } from "../app/services/api";
import { decryptAES, encryptAES } from "../components/CommonLibrary";
import Loader from "../components/Loader/ButtonLoader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeUserData } from "../app/Redux/reducer";
import BackArrow from "../images/hisabdetails/LeftArrow.png";
import Header from "../UI/Header";

function DeleteAccount() {
  const language = useSelector((state) => state.usersLoginData.allLanguage);
  const [selectedReason, setSelectedReason] = useState("");
  const [isDescribe, setIsDescribe] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const maxLength = 250;

  const handleChangeDescribe = (e) => {
    const newValue = e.target.value;
    if (newValue.length <= maxLength) {
      setIsDescribe(newValue);
    }
  };

  const handleChange = (event) => {
    setSelectedReason(event.target.value);
  };

  const onHandleClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsBtnDisabled(true);
    try {
      const data = language?.deleteaccount_que?.flatMap((item) => {
        return item.questions.map((question) => {
          return {
            type: question.type,
            question: question?.question,
            options: question?.options ,
            answer: question.type === 'radio' ?[selectedReason] : [isDescribe]
          };
        });
      });
      const parameters = {
        reasons: data,
      };
      const encryptedData = encryptAES(JSON.stringify(parameters));
      const formData = new FormData();
      formData.append("data", encryptedData);

      const url = process.env.REACT_APP_API_BASE_URL + "/users/delete_user";
      const tokenUrl = localStorage.getItem("accessToken");
      const languageCode = "en";

      const result = await apiService(
        url,
        "POST",
        formData,
        tokenUrl,
        languageCode
      );

      const decryptedResponse = decryptAES(result.data.response);
      if (
        JSON.parse(decryptedResponse).status === "200" ||
        JSON.parse(decryptedResponse).status === 200
      ) {
        toast.success(JSON.parse(decryptedResponse).message);
        setIsBtnDisabled(false);
        setIsLoading(false);
        localStorage.removeItem("accessToken");
        dispatch(removeUserData());
        navigate("/login");
      } else {
        setIsBtnDisabled(false);
        setIsLoading(false);
        toast.error(JSON.parse(decryptedResponse).message);
      }
    } catch (error) {
      console.error("Error during login:", error);
      setIsLoading(false);
      setIsBtnDisabled(false);
    }
  };

  return (
    <Box>
       <header className="flex gap-1 border-b " >
          <button
            className="pt-2 max-480:w-[46px] pl-2 flex justify-center items-center"
            onClick={() => navigate(-1)}
            aria-label="Go back"
          >
            <img alt="Back" src={BackArrow} width={24} height={24} />
          </button>
          <Header title={language?.delete_acc_appbar} className="w-full p-2"/>
        </header>

      <Box>
        <Box className="mt-4">
          <Box className="max-425:mt-[20px]">
            <Box className="w-[65%] drop-shadow-xl py-10 m-auto rounded-[20px] bg-white max-1024:w-[75%] max-425:!w-[90%] max-h-[80%] h-full overflow-hidden px-[55px] max-425:!px-[30px] max-425:pb-[50px]">
              <Box className="flex justify-center">
                <img
                  src={deleteImage}
                  alt="arrow left"
                  className="w-[200px] h-[200px]"
                />
              </Box>
              <Box className=" max-425:mx-[0px]">
                <form onSubmit={onHandleClick}>
                  <Box>
                    <Typography className="font-medium text-[14px]">
                      {language.deleteaccount_que[0].questions[0].question}
                    </Typography>

                    <Box className="mt-[8px]">
                      <FormControl component="fieldset">
                        <RadioGroup value={selectedReason} onChange={handleChange}>
                         {language.deleteaccount_que[0].questions[0].options.map((item)=>(
                           <FormControlLabel
                           className="text-[12px] radiobutton"
                           value={item}
                           control={
                             <Radio
                               sx={{
                                 height: "30px",
                                 width: "30px",
                                 padding: "0",
                               }}
                             />
                           }
                           label={item}
                           sx={{
                             "& .MuiFormControlLabel-label": {
                               fontSize: "12px",  
                               lineHeight: "1.5", 
                               padding: "0",      
                             },
                           }}
                         />
                         ))}
                          
                        </RadioGroup>
                      </FormControl>

                    </Box>

                    <Box style={{ border: "1px solid #EEF0F2" }} className="mt-[8px]" />
                    <Typography className="mt-[10px] text-[16px] font-medium">{language.deleteaccount_que[0].questions[1].question}</Typography>
                    <TextField
                      className="mt-[4px]"
                      id="description"
                      label={<span className="">{language.description}</span>}
                      variant="outlined"
                      fullWidth
                      // required
                      value={isDescribe}
                      onChange={handleChangeDescribe}
                      inputProps={{
                        maxLength: maxLength,
                      }}
                      multiline
                      rows={4}
                      sx={{
                        marginTop: "20px",
                        "& .MuiInputBase-input": {
                          height: "90px !important"
                        },
                        "& .MuiInputLabel-root": {
                          color: "gray",
                        },
                        "& .MuiInputLabel-asterisk": {
                          color: "red",
                          fontSize: "20px",
                        },
                      }}
                      helperText={`${isDescribe.length}/${maxLength}`}
                      FormHelperTextProps={{
                        style: {
                          position: "absolute",
                          right: "0",
                          color: "gray",
                          fontSize: "15px",
                          marginTop: "0",
                        },
                      }}
                    />
                  </Box>
                  <button
                    className={`${selectedReason?.trim()?.length > 0 &&
                        isDescribe?.trim()?.length > 0
                        ? "bg-gradient-to-r from-[#FF9FA3] to-[#E5474D] text-[#FFFFFF]"
                        : "bg-[#FF9FA3] opacity-[35%] text-gray-500"
                      } mt-[23px] w-[100%] h-[52px] font-medium text-[16px] rounded-[5px]`}
                    disabled={
                      selectedReason?.trim()?.length > 0 &&
                        isDescribe?.trim()?.length > 0
                        ? isBtnDisabled
                        : true
                    }
                  >
                    {isLoading === true ? (
                      <Loader width={25} height={25} color={"black"} />
                    ) : (
                      language.delete_Account
                    )}
                  </button>
                </form>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default DeleteAccount;
