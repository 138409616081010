import React from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock';
import ErrorIcon from '../images/ErrorPage/Error404Image.png'

export default function ErrorPage() {
    const navigate = useNavigate();
    
    return (
        <Box>
            <div className="w-[100%] flex justify-center items-center h-[100vh] bg-[#f0f2f5]">
                <div className="flex flex-col items-center justify-center">
                    <img src={ErrorIcon} className="h-[auto] w-[400px]" alt="mockup" />
                    <h1 className="text-[22px] text-[grey] font-normal text-center select-none">
                        Desi hisab Web
                    </h1>
                    <Button className="bg-gradient-to-r from-[#FFD87A] via-[#F6B825] to-[#F6B825] text-black text-[12px]" onClick={() => { navigate("/AllHisab/:id") }}>Go Back to Home</Button>
                    <h1 className="text-[10px] text-center mt-8"><LockIcon className="text-[12px]" /> <span>Your hisabs are end-to-end encrypted</span></h1>
                </div>
            </div>
        </Box>
    )
}