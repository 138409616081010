import { debounce } from "lodash";
import { toast } from "react-toastify";
import Grid from '@mui/material/Grid2';
import SearchBar from "../components/Search";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import { ITEMS_PER_PAGE } from "../utils/helper";
import { apiService } from "../app/services/api";
import ReplyIcon from "@mui/icons-material/Reply";
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import React, { useCallback, useEffect, useState } from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { decryptAES, encryptAES } from "../components/CommonLibrary";
import { setAutoOpenHisab, setFetchAllHisab, setFirstId, setTransactionId } from "../app/Redux/reducer";
import { Avatar, AvatarGroup, Box, ClickAwayListener, Paper, Popper, Typography } from "@mui/material";
import locked from "../images/hisabdetails/locked.png";
import ContactModal from "../components/ContactModal/ContactModal";

function AllHisab() {
  const { id } = useParams();
  let decryptedID;
  if (id){
     decryptedID = decryptAES(id);
  }
  const urlObject = JSON.parse(decryptedID ? decryptedID : null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState("all");
  const [filterHisab, setFilterHisab] = useState("all");
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setLoading] = useState("");
  const [allHisabs, setAllHisabs] = useState([]);
  const [userHisab, setUserHisab] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedHisabId, setSelectedHisabId] = useState(null);
  const [addParticipantOpen , setAddParticipantOpen] = useState(false);
  const userData = useSelector((state) => state.usersLoginData.loginUserDetails);
  const autoOpenHisab = useSelector(state => state.usersLoginData.autoOpenHisab);
  const fetchAllHisab = useSelector((state) => state.usersLoginData.fetchAllHisab);
  const GlobalselectedHisabId = useSelector(state => state.usersLoginData.selectedHisabId);
  const language = useSelector((state) => state.usersLoginData.allLanguage);
  const onClickId = useSelector((state)=> state.usersLoginData.transactionId)
  const [filterTune, setFilterTube] = useState("all");
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleTuneClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    setPage(1);
    getData(query, filter, filterHisab);
  }, [query, filter, filterHisab]);

  useEffect(() => {
    if (fetchAllHisab) {
      setPage(1);
      getData("", filter);
      dispatch(setFetchAllHisab(false));
    }
  }, [fetchAllHisab]);

  useEffect(() => {
    const storedHisabId = localStorage.getItem("selectedHisabId");
    if (storedHisabId !== null) {
      setSelectedHisabId(Number(storedHisabId));
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth > 768 && autoOpenHisab && userHisab.length > 0) {
      const hisabToOpen = userHisab[selectedHisabId || GlobalselectedHisabId];
      if (hisabToOpen) {
        handleOpenHisab(hisabToOpen.hisab_id, selectedHisabId || GlobalselectedHisabId);
        dispatch(setAutoOpenHisab(false));
      }
    }
  }, [autoOpenHisab, userHisab, selectedHisabId, GlobalselectedHisabId, dispatch]);

  const getData = async (
    searchQuery = "",
    selectedFilter = filter,
    selectHisab_filter = filterHisab,
    pageNo = 1
  ) => {
    setLoading(true);
    setErrorMessage("");
    try {
      const usertoken = localStorage.getItem("accessToken");
      const url = process.env.REACT_APP_API_BASE_URL + "/hisab/get_list";
      const userMpin = {
        user_id: userData.user_id,
        page_no: pageNo.toString(),
        filter_by: selectedFilter || "all",
        filter: selectHisab_filter ,
      };
      if (searchQuery) {
        userMpin.search_by = searchQuery;
      }
      const encryptedData = encryptAES(JSON.stringify(userMpin));

      const formData = new FormData();
      formData.append("data", encryptedData);
      const result = await apiService(url, "POST", formData, usertoken);
      const decryptedResponse = decryptAES(result.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        setLoading(false);
        toast.success(JSON.parse(decryptedResponse).message);
        // console.log("decryptedResponse+++", JSON.parse(decryptedResponse));
        const data = await JSON.parse(decryptedResponse);
        dispatch(setFirstId(data?.hisab_data[0]?.hisab_id))
        const hisabs = data.hisab_data || [];
        setAllHisabs(hisabs);
        setUserHisab(hisabs.slice(0, ITEMS_PER_PAGE));
        setHasMore(hisabs.length > ITEMS_PER_PAGE);
      } else {
        setLoading(false);
        setUserHisab([]);
        setAllHisabs([]);
        setHasMore(false);
        if (searchQuery || selectedFilter) {
          setErrorMessage(JSON.parse(decryptedResponse).message);
        } else {
          toast.error(JSON.parse(decryptedResponse).message);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };

  // function to calculate next data for scrolling
  const fetchMoreData = useCallback(() => {
    const nextPage = page + 1;
    const startIndex = (nextPage - 1) * ITEMS_PER_PAGE;
    const endIndex = nextPage * ITEMS_PER_PAGE;
    const nextPageData = allHisabs.slice(startIndex, endIndex);
    if (nextPageData.length > 0) {
      setUserHisab((prev) => [...prev, ...nextPageData]);
      setPage(nextPage);
    }
    setHasMore(nextPageData.length === ITEMS_PER_PAGE);
  }, [hasMore, page, userHisab]);

  const debouncedSearch = debounce((searchQuery) => {
    setQuery(searchQuery.trim());
  }, 500);

  const handleOpenHisab = (hisab_id, index) => {
    dispatch(setFirstId(hisab_id))
    setSelectedHisabId(index);
    localStorage.setItem("selectedHisabId", index);
    const encryptedId = encryptAES(JSON.stringify({ id: hisab_id }));
    const encodedData = encodeURIComponent(encryptedId);
    navigate(`/AllHisab/${encodedData}`);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const convertToCrores = (value) => {
    const cleanedValue = value?.toString()
      .replace(/,/g, '')
      .replace(/\.(\d*?)0+$/, '');
    const crore = 10000000;
    const inCrores = cleanedValue / crore;
    return inCrores.toFixed(2);
  };
  function handelAddParticipant(){
    setAddParticipantOpen(true)
  }

  return (
    <div className="overflow-hidden">
      <Grid container className="px-[15px] pt-3 md:pt-0">
        <Grid size={11}>
          <SearchBar onSearch={debouncedSearch} />
        </Grid>
        <Grid size={1} className="pl-1 flex justify-end">
          {open ? (
            <CloseIcon
              className="flex justify-center items-center h-9 w-5 md:w-5 cursor-pointer"
              onClick={handleClose}
            />
          ) : (
            <TuneIcon
              onClick={handleTuneClick}
              className="cursor-pointer flex justify-center items-center h-9 w-5 md:w-5"
            />
          )}
        </Grid>
      </Grid>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        className={`${window.innerWidth >= 1524 ? "w-[29.4%] !left-[4rem]" : window.innerWidth <= 768 ? "!w-[89%] !left-[13px] !top-[7.8rem]" : window.innerWidth >= 1280 ? "!left-[4.3rem] w-[29.3%] " :  "!left-[3.4rem] w-[29.3%] "} bottom-0 top-24 `}
        style={{
          bottom: "0px !important",
          top: "",
        }}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Paper {...TransitionProps} className="p-4">
              <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item size={{ xs: 12, sm: 4, md: 4 }}>
                  <button
                    className={`p-[3px] w-full md:p-2 lg:h-[30px] rounded-[21px] lg:flex lg:justify-center lg:items-center lg:text-[11px] ${filter === "all" ? "border-[#FFD87A] border bg-[#FFF1D1] text-black" : "bg-gray-200 text-black"}`}
                    onClick={() => {
                      setFilter("all");
                      debouncedSearch(query, "all");
                      setOpen(false); // Close the Popper after selecting filter
                    }}
                  >
                    <span className="w-[61px] h-[19px] lg:h-[30px] font-normal md:font-medium text-[12px] md:text-[12px] lg:flex lg:justify-center lg:items-center lg:text-[11px]">
                      {language.filter_name_1}
                    </span>
                  </button>
                </Grid>

                <Grid item size={{ xs: 12, sm: 4, md: 4 }}>
                  <button
                    className={`lg:flex lg:justify-center lg:items-center lg:h-[30px] p-[8px] md:p-2 w-full rounded-[21px] ${filter === "shared_by_me" ? "border-[#FFD87A] border bg-[#FFF1D1] text-black" : "bg-gray-200 text-black"}`}
                    onClick={() => {
                      setFilter("shared_by_me");
                      debouncedSearch(query, "shared_by_me");
                      setOpen(false); // Close the Popper after selecting filter
                    }}
                  >
                    <span className="lg:flex lg:justify-center lg:items-center lg:h-[30px] w-[96px] font-normal md:font-medium text-[12px] md:text-[12px] lg:text-[11px]">
                      {language.filter_name_3}
                    </span>
                  </button>
                </Grid>

                <Grid item size={{ xs: 12, sm: 4, md: 4 }}>
                  <button
                    className={`lg:flex lg:justify-center lg:items-center lg:h-[30px] p-[8px] md:p-2 w-full rounded-[21px] ${filter === "shared_with_me" ? "border-[#FFD87A] border bg-[#FFF1D1] text-black" : "bg-gray-200 text-black"}`}
                    onClick={() => {
                      setFilter("shared_with_me");
                      debouncedSearch(query, "shared_with_me");
                      setOpen(false); // Close the Popper after selecting filter
                    }}
                  >
                    <span className="w-[107px] h-[19px] font-normal md:font-medium text-[12px] md:text-[12px] lg:text-[10px]">
                      {language.filter_name_2}
                    </span>
                  </button>
                </Grid>
              </Grid>
            </Paper>
          </ClickAwayListener>
        )}
      </Popper>

      <Box className=" mt-[6px]">
        <Grid
          container
          className="px-[14px] max-425:pr-[27px]"
          spacing={{ xs: 1, sm: 2, md: 2 }}
        >
          <Grid item size={{ xs: 4, sm: 4, md: 4 }} className="">
            <button
              className={`xs:p-[8px] w-full md:p-2 lg:h-[30px] rounded-[21px] lg:flex lg:justify-center lg:items-center lg:text-[12px] ${filterHisab === "all" ? "border-[#FFD87A] border bg-[#FFF1D1] text-black" : "bg-gray-200 text-black"}`}
              onClick={() => {
                setFilterHisab("all");
                // debouncedSearch(query, "All");
                setOpen(false); // Close the Popper after selecting filter
              }}
            >
              <span className="w-[61px] h-[19px] lg:h-[30px] font-normal md:font-medium text-[12px] md:text-[12px] lg:flex lg:justify-center lg:items-center lg:text-[12px]">
                {language.hisab_filter_list[0].name}
              </span>
            </button>
          </Grid>

          <Grid item size={{ xs: 4, sm: 4, md: 4 }} className="">
            <button
              className={`lg:flex lg:justify-center lg:items-center lg:h-[30px] xs:p-[8px] md:p-2 w-[100%] rounded-[21px] ${filterHisab === "active" ? "border-[#FFD87A] border bg-[#FFF1D1] text-black" : "bg-gray-200 text-black"}`}
              onClick={() => {
                setFilterHisab("active");
                // debouncedSearch(query, "Active");
                setOpen(false); // Close the Popper after selecting filter
              }}
            >
              <span className="lg:flex lg:justify-center lg:items-center lg:h-[30px] w-[96px] font-normal md:font-medium text-[12px] md:text-[12px] lg:text-[12px]">
                {language.hisab_filter_list[1].name}
              </span>
            </button>
          </Grid>

          <Grid item size={{ xs: 4, sm: 4, md: 4 }}>
            <button
              className={`lg:flex lg:justify-center w-[100%] lg:items-center lg:h-[30px] xs:p-[8px] md:p-2 rounded-[21px] ${filterHisab === "complete" ? "border-[#FFD87A] border bg-[#FFF1D1] text-black" : "bg-gray-200 text-black"}`}
              onClick={() => {
                setFilterHisab("complete");
                // debouncedSearch(query, "Completed");
                setOpen(false); // Close the Popper after selecting filter
              }}
            >
              <span className="font-normal md:font-medium text-[11px] md:text-[12px] lg:text-[12px]">
                {language.hisab_filter_list[2].name}
              </span>
            </button>
          </Grid>
        </Grid>
      </Box>
      {isLoading ? (
        <div className="flex justify-center items-center w-full mt-48">
          <Loader width={40} height={40} color={"black"} />
        </div>
      ) : (
        <div
          id="scrollableDiv"
          style={{
            height: userHisab.length < 3 ? "auto" : "calc(100vh - 170px)",
            overflow: userHisab.length < 3 ? "unset" : "auto",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: "16px",
          }}
          className={userHisab.length < 3 ? "" : "custom-scrollbar"}
        >
          <InfiniteScroll
            dataLength={userHisab.length}
            next={fetchMoreData}
            hasMore={hasMore}
            // loader={
            //     <div className="flex justify-center mt-1">
            //       <Loader width={40} height={40} color={"black"} />
            //     </div>
            // }
            scrollableTarget="scrollableDiv"
            style={{ height: "unset", overflow: "unset" }}
          >
            <div className="flex items-center justify-center flex-col w-full gap-2  pl-3 pr-3">
              {userHisab.length > 0 ? (
                userHisab.map((item, index) => (
                  <div
                  title="Click here to view transaction details"
                    key={index}
                    className={`w-full cursor-pointer border mt-3 rounded-md hover:bg-gray-50 ${
                      onClickId === item?.hisab_id && window.innerWidth >768
                        ? " bg-[#FAFAFA] hover:bg-[#FAFAFA] border-[2px] border-[#FFD87A] rounded-md"
                        : "border-[#ddd]"
                    }`}
                    style={{
                      padding: "0",
                      margin: "0",
                      // boxShadow: "0 0 4px rgba(0, 0, 0, 0.2)",
                    }}
                    onClick={() => {
                      handleOpenHisab(item.hisab_id, index); 
                      dispatch(setTransactionId(item.hisab_id))}}
                  >
                    <div className="flex justify-between pl-[0.8rem] pr-[0.8rem] py-2">
                      <Box className="flex items-center gap-1">
                        {item.hisab_status === "complete" ? (
                          <img
                            src={locked}
                            alt="loacked_icon"
                            className="h-[12px]"
                          />
                        ) : (
                          ""
                        )}
                        <p className="font-medium max-640:text-[12px] text-[14px] truncate">
                          {item.hisab_name.length > 20
                            ? `${item.hisab_name.slice(0, 20)}...`
                            : item.hisab_name}
                        </p>
                      </Box>
                      <p className="font-medium max-640:text-[12px] text-[14px]">
                        {item.balance}
                      </p>
                    </div>

                    <div className="flex items-center justify-between object-contain pl-[0.8rem] pr-[0.8rem] pb-2">
                      <div className="flex justify-between items-center w-fit h-[30px] bg-[#f6f6f6d4] pl-[5px] pr-[5px] pt-[2px] rounded-full">
                        <Box className="flex items-center w-auto h-auto">
                          {item.participant_user.length > 0 && (
                            <AvatarGroup
                              max={5}
                              spacing="small"
                              sx={{
                                "& .MuiAvatar-root": {
                                  width: 20,
                                  height: 20,
                                  fontSize: "0.75rem",
                                },
                              }}
                            >
                              {item.participant_user.map((participant) => (
                                <Avatar
                                  key={participant.id}
                                  alt={participant.name}
                                  src={`${process.env.REACT_APP_API_BASE_URL}/uploads/user/${participant.id}/${participant.photo}`}
                                />
                              ))}
                            </AvatarGroup>
                          )}
                        </Box>
                         {item.user_id === userData.user_id ||  item.user_type === "Admin" ? 
                        (<button className="flex items-center gap-1 text-gray-500 hover:text-gray-700"     onClick={handelAddParticipant}>
                          <ReplyIcon className="transform scale-x-[-1] text-[#d1cfcf] w-[15px] h-[15px] max-sm:text-xs" />
                          <span className="text-[10px] text-[#B1B1B1]">
                            {language.share}
                          </span>
                        </button> ) : ('')
                         }
                      </div>
                      <Box className="flex justify-end gap-[4px] text-xs items-center">
                        <Typography className="text-[#46A759] max-640:text-[12px] font-medium text-[12px]">
                          {window.innerWidth > 480 ? language.income_Rupee_Price : " "}{" "}
                          ₹
                          {item.income?.length >= 13
                            ? `${convertToCrores(item.income)} cr`
                            : item.income}
                        </Typography>{" "}
                        <span className="text-gray-400 md:block font-medium text-[12px] max-640:text-[12px]">
                          |
                        </span>
                        <Typography className="text-[#E5474D] font-medium max-640:text-[12px] text-[12px]">
                          {window.innerWidth > 480 ? language.expense_Rupee_Price : ""}{" "}
                          ₹
                          {item.expense?.length >= 13
                            ? `${convertToCrores(item.expense)} cr`
                            : item.expense}
                        </Typography>
                      </Box>
                    </div>
                  </div>
                ))
              ) : (
                <h2 className="text-center text-[14px]">
                  {errorMessage || language.hisab_not_found}
                </h2>
              )}
            </div>
          </InfiniteScroll>
        </div>
      )}
      <ContactModal open={addParticipantOpen} setAddParticipantOpen={setAddParticipantOpen}/>
    </div>
  );
}
export default AllHisab;
