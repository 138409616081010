import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { decryptAES, encryptAES } from "../components/CommonLibrary";
import { apiService } from "../app/services/api";
import { toast } from "react-toastify";
import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import Loader from "../components/Loader/Loader";
import BackArrow from "../images/hisabdetails/LeftArrow.png";
import AddImageIcon from "../images/Income/AddImageIcon.png";
import CloseIcvon from "../images/Income/close-square.png";
import attached from "../images/Support/Attached (2).png";
import SendIcon from "@mui/icons-material/Send";

const SupportChat = () => {
  const { id } = useParams();
  let decryptedID;
  if (id) {
    decryptedID = decryptAES(id);
  }
  const urlObject = JSON.parse(decryptedID ? decryptedID : null);
  const number = urlObject?.id;

  const [details, setDetails] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const fileInputRef = useRef();
  const language = useSelector((state) => state.usersLoginData.allLanguage);
  const userLoginData = useSelector((state) => state.usersLoginData.getUser);

  const [formState, setFormState] = useState({
    actualImages: [],
    deletedImages: [],
    fileError: "",
    imageError: "",
  });

  const { actualImages, deletedImages } = formState;

  const updateFormState = (updates) => {
    setFormState((prev) => ({ ...prev, ...updates }));
  };
  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    const fetchSupportDetails = async () => {
      await supportDetails();
    };
    fetchSupportDetails();
  }, []);

  async function supportDetails() {
    setIsLoading(true);
    try {
      const tokenUrl = localStorage.getItem("accessToken");
      const url =
        process.env.REACT_APP_API_BASE_URL + "/support/support_detail";
      const body = {
        support_id: number,
      };
      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);
      const res = await apiService(url, "POST", formData, tokenUrl);
      const decryptedResponse = decryptAES(res.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        toast.success(JSON.parse(decryptedResponse).message);
        const data = await JSON.parse(decryptedResponse);
        setDetails(data);
        setIsLoading(false);
      } else {
        toast.error(JSON.parse(decryptedResponse).message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("error", error);
    }
  }

  async function reportReply() {
    try {
      const tokenUrl = localStorage.getItem("accessToken");
      const url =
        process.env.REACT_APP_API_BASE_URL + "/support/add_support_reply";
      const body = {
        support_id: number,
        user_id: userLoginData?.id,
        message: message,
      };
      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);
      formState.actualImages.forEach((file) =>
        formData.append("images[]", file)
      );
      const res = await apiService(url, "POST", formData, tokenUrl);
      const decryptedResponse = decryptAES(res.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        setMessage("");
        toast.success(JSON.parse(decryptedResponse).message);
        const data = await JSON.parse(decryptedResponse);
        const newMessage = {
          id: Date.now(),
          user_id: userLoginData?.id,
          message: message,
          created_at: new Date().toISOString(),
          support_replies_images: formState.actualImages,
        };

        const formattedDate = new Date(newMessage.created_at).toLocaleString(
          "en-US",
          {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }
        );
        newMessage.created_at = formattedDate;
        setDetails((prevDetails) => {
          const updatedReplies = [...prevDetails.support_replies, newMessage];
          return { ...prevDetails, support_replies: updatedReplies };
        });
        setIsLoading(false);
      } else {
        toast.error(JSON.parse(decryptedResponse).message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("error", error);
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["image/png", "image/jpg", "image/jpeg"];
      if (!allowedTypes.includes(file.type)) {
        updateFormState({
          fileError: "Please upload a valid image (png, jpg, jpeg).",
        });
        return;
      } else if (file.size >= 5000000) {
        updateFormState({ fileError: "Please upload an image less than 5 MB" });
        return;
      }
      updateFormState({
        actualImages: [...actualImages, file],
        fileError: "",
      });
    }
  };

  const handleRemoveImage = (image) => {
    const updatedImages = actualImages.filter((img) => img !== image);
    updateFormState({
      actualImages: updatedImages,
      ...(image.id && {
        deletedImages: [...deletedImages, image.id],
      }),
    });
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return "Today";
    } else if (date.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    } else {
      return `${date.getDate()} ${date.toLocaleString("default", { month: "short" })} ${date.getFullYear()}`;
    }
  };

  const groupMessagesByDate = (messages) => {
    return messages.reduce((acc, message) => {
      const messageDate = formatDate(message.created_at);
      if (!acc[messageDate]) {
        acc[messageDate] = [];
      }
      acc[messageDate].push(message);
      return acc;
    }, {});
  };

  const groupedMessages = groupMessagesByDate(details.support_replies || []);

  return (
    <>
      <div className="flex border-b items-center">
        <button
          className="p-2 max-480:w-[46px]"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img alt="backImage" src={BackArrow}></img>
        </button>
        <h1 className="text-[20px] font-[500] font-sans max-768:text-[18px]">
          {details.support_data?.subject}
        </h1>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center w-full mt-48">
          <Loader />
        </div>
      ) : (
        <>
          <Box className="w-full mt-[20px]">
            <Box sx={{ minHeight: "calc(100vh - 152px)" }}>
              <section className="flex justify-between items-center px-[17px] max-480:p-3 mb-3">
                <h2 className="text-[14px] font-bold md:text-[16px]">
                  {language.ticket}
                  <span
                    className={`${
                      details.support_data?.category === "Issue"
                        ? "text-red-500"
                        : details.support_data?.category === "Suggestion"
                          ? "text-[#FFD87A]"
                          : "text-gray-500"
                    }`}
                  >
                    {details.support_data?.category}
                  </span>
                </h2>
                {details.support_data?.category === "Issue" ? (
                  <div className="flex items-center justify-center">
                    {language.status}
                    <span
                      className={`${
                        details.support_data?.status === "Pending"
                          ? "text-gray-500"
                          : details.support_data?.status === "In Progress" ||
                              details.support_data?.status === "Data Requested"
                            ? "text-[#FEAF55]"
                            : details.support_data?.status === "Close"
                              ? "text-green-500"
                              : "text-gray-500"
                      }`}
                    >
                      {details.support_data?.status}
                    </span>
                  </div>
                ) : (
                  " "
                )}
              </section>

              <Box className="flex w-[96%]  gap-1 border-[#F5F5F5] border items-center m-[10px] rounded-lg  flex-col">
                <Box className="flex justify-between w-[98%] p-[5px]">
                  <Typography className="font-medium text-[16px] max-480:text-[12px]">
                    {details.support_data?.name}
                  </Typography>
                  <p className="text-[12px] px-[6px] font-semibold rounded-[2px] p-[3px] max-480:text-[10px] text-[#B1B1B1]">
                    {details.support_data?.created_at}
                  </p>
                </Box>

                <section className="flex justify-between w-[98%] p-[5px]">
                  <Typography className="font-medium text-[14px] max-480:text-[12px]">
                    {details.support_data?.message}
                  </Typography>
                </section>

                <div className="w-full flex items-center justify-center">
                  <section
                    className={`!w-[55%] flex gap-3 items-center horizontal-scrollbar overflow-x-auto mt-4 whitespace-nowrap max-680:ml-2 mb-4 max-768:w-[70%] 
    ${
      details.support_data?.support_images?.length <= 2
        ? "!justify-center"
        : `lg:${
            details.support_data?.support_images?.length === 3
              ? "justify-center"
              : "justify-start"
          }`
    }`}
                  >
                    {details.support_data?.support_images?.map((img) => (
                      <img
                        key={img.image}
                        className="flex-shrink-0 w-[104px] h-[103px] border-solid border-[1px] border-[#F7B924] rounded-[5px] max-680:w-[80px] max-680:h-[100px] mr-2"
                        src={`${process.env.REACT_APP_AWS_LINK}/uploads/supports/${img.image}`}
                        alt="support"
                      />
                    ))}
                  </section>
                </div>
              </Box>

              <Box className="px-3">
                {Object.keys(groupedMessages).map((dateGroup) => (
                  <Box key={dateGroup}>
                    {/* Date Header */}
                    <Box sx={{ textAlign: "center", marginY: 2 }}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "#555",
                          fontWeight: "bold",
                        }}
                      >
                        {dateGroup}
                      </Typography>
                    </Box>

                    {groupedMessages[dateGroup].map((item) => (
                      <Box
                        key={item.id}
                        sx={{
                          display: "flex",
                          justifyContent:
                            item.user_id === userLoginData?.id
                              ? "flex-end"
                              : "flex-start",
                          marginBottom: 2,
                        }}
                      >
                        <Box
                          className="chat-message"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor:
                              item.user_id === userLoginData?.id
                                ? "#FEE7C6"
                                : "#f1f1f1",
                            padding: 2,
                            borderRadius: "8px",
                            width: "fit-content",
                            maxWidth: "70%",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              color:
                                item.user_id === userLoginData?.id
                                  ? "#000"
                                  : "#555",
                              marginBottom: "4px",
                            }}
                          >
                            {item.user_id === userLoginData?.id
                              ? details.support_data.name
                              : "Desi Hisab Support"}
                          </Typography>

                          {item.support_replies_images.length > 0 && (
                            <Box
                              sx={{
                                marginTop: "8px",
                                display: "flex",
                                width: "fit-content",
                              }}
                            >
                              <img
                                src={`${process.env.REACT_APP_AWS_LINK}/uploads/supports/${item.support_replies_images[0].image}`}
                                alt="support reply"
                                style={{
                                  borderRadius: "8px",
                                  display: "block",
                                  marginTop: "8px",
                                  maxWidth: "100%",
                                  maxHeight: "200px",
                                }}
                              />
                            </Box>
                          )}

                          <Typography
                            sx={{
                              fontSize: "16px",
                              color: "#333",
                              marginTop: "8px",
                              wordBreak: "break-all",
                            }}
                          >
                            {item.message}
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "#888",
                              marginTop: "4px",
                            }}
                            className="flex justify-end"
                          >
                            {item.created_at.split(" ").slice(-2).join(" ")}{" "}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box>
            </Box>

            <Box className="px-3 sticky bottom-0">
              <div
                className={`w-full !gap-[4%] overflow-auto flex sticky bottom-0 justify-start flex-nowrap  border-solid border-[2px]  border-[#ddd] rounded-md p-3 bg-[white] ${actualImages.length > 0 ? "" : "hidden"}`}
              >
                <div
                  className={`cursor-pointer flex items-center justify-center flex-shrink-0 w-[100px] h-[100px] border-dashed border-[#F5B820] border-[2px] ${actualImages.length > 4 ? "hidden" : ""}`}
                  onClick={handleImageClick}
                >
                  <img
                    src={AddImageIcon}
                    className="w-[30%] h-[30%]"
                    alt="add icon"
                  />
                </div>
                {actualImages.map((item) => (
                  <div key={item} className="!relative flex-shrink-0 mr-[20px]">
                    <img
                      className="w-[100px] h-[100px] rounded"
                      src={
                        item?.support_images
                          ? `${process.env.REACT_APP_API_BASE_URL}/uploads/supports/${item.support_images}`
                          : item
                            ? URL.createObjectURL(item)
                            : ""
                      }
                      alt={`preview-${item}`}
                    />
                    <Button
                      className="!absolute !top-0 !right-0 !z-10 !w-[20px] !justify-end"
                      onClick={() => handleRemoveImage(item)}
                    >
                      <img
                        src={CloseIcvon}
                        alt="close"
                        className="w-[28px] h-[28px]"
                      />
                    </Button>
                  </div>
                ))}
              </div>

              {details.support_data?.category === "Issue" ? (
                <div className="w-full flex items-center gap-6 max-768:gap-1 bg-gradient-to-r from-[#FEE7C6] via-[#f5c34594] to-[#FEE7C6] pt-[10px] pb-[8px] px-6 max-768:px-[8px] max-768:py-[6px] rounded-lg shadow-lg">
                  <div className="flex-shrink-0 cursor-pointer w-[60px] h-[60px] max-768:w-[20px] max-768:h-[20px] rounded-full flex justify-center items-center transition-all transform hover:scale-105">
                    <img
                      src={attached}
                      alt="Attach"
                      className="w-[30px] h-[30px] transition-transform duration-300 ease-in-out"
                      onClick={() => fileInputRef.current.click()}
                    />
                    <input
                      id="file-upload"
                      type="file"
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={handleFileChange}
                      className="hidden"
                      ref={fileInputRef}
                      disabled={actualImages.length >= 5}
                    />
                  </div>

                  <div className="w-full flex-1">
                    <textarea
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      className="w-full h-[60px] max-768:h-[40px] max-768:p-[7px] p-4 border-2 border-gray-300 rounded-[1.75rem] shadow-lg focus:outline-none focus:ring-2 focus:ring-[#F5B820] placeholder:text-gray-500 transition-all duration-300 ease-in-out"
                      placeholder="Type your reply..."
                    />
                  </div>

                  <div>
                    <Button
                      onClick={reportReply}
                      disabled={isLoading || !message.trim()}
                      className="h-[60px] w-[60px] max-768:w-[20px] max-768:h-[16px] px-6 rounded-full text-white font-semibold shadow-lg transform transition-all duration-300 ease-in-out hover:bg-[#F5B820] focus:outline-none disabled:bg-gray-400 disabled:cursor-not-allowed p-[17px]"
                    >
                      <SendIcon />
                    </Button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default SupportChat;
