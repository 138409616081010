import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { decryptAES, encryptAES } from "../components/CommonLibrary";
import {
  apiService,
} from "../app/services/api";
import Loader from "../components/Loader/Loader";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import blackAdd from "../images/DetailsSection/Group 2031 (3).png";
import ParticipantCard from "./ParticipantCard";
import { setFetchAllHisab } from "../app/Redux/reducer";
import CustomModal from "../components/CommonModal/CustomModal";
import Model from "../components/CommonModal/Model";
import MockUP from "../UI/MockUP";
import BackArrow from "../images/hisabdetails/LeftArrow.png";
import ContactModal from "../components/ContactModal/ContactModal";
import { format } from 'date-fns';
import plus from "../images/hisabdetails/plus.png"


const Details = () => {
  const { id } = useParams();
  let decryptedID;
  if (id) {
    decryptedID = decryptAES(id);
  }
  const urlObject = JSON.parse(decryptedID ? decryptedID : null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const number = urlObject?.id;
  const [details, setDetails] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedHisabId, setSelectedHisabId] = useState(null);
  const [isModalView, setIsModalView] = useState("");
  const [open, setOpen] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [participant_id, setParticipant_id] = useState([]);
  const [hisabName, setHisabName] = useState("");
  const [balance, setBalance] = useState(0);
  const [participant, setParticipant] = useState([]);
  const [isModel, setIsModel] = useState(false);
  const [adminId, setAdminId] = useState(false);
  const language = useSelector((state) => state.usersLoginData.allLanguage);
  const [addParticipantOpen , setAddParticipantOpen] = useState(false);
  const [buttonDisabled , setButtonDisabled] = useState(false);
  const [selectedEditParticipants, setSelectedEditParticipants] = useState([]);
  const [selectedViewParticipants, setSelectedViewParticipants] = useState([]);
  const isSelected = selectedEditParticipants.length > 0 || selectedViewParticipants.length > 0;

  const userData = useSelector(
    (state) => state.usersLoginData.loginUserDetails
  );

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allEditParticipants = participant
        .filter((item) => item?.id !== userData.user_id)
        .filter((item) => item?.permission === "edit")
        .map((item) => item?.id);
      const allViewParticipants = participant
        .filter((item) => item?.id !== userData.user_id)
        .filter((item) => item?.permission === "view")
        .map((item) => item?.id);
      setSelectedEditParticipants(allEditParticipants);
      setSelectedViewParticipants(allViewParticipants);
    } else {
      setSelectedEditParticipants([]);
      setSelectedViewParticipants([]);
    }
  };


  useEffect(() => {
    if (number) {
      fetchDetails();
    } else {
      navigate("/Error");
    }
  }, [number]);

  async function fetchDetails() {
    setIsLoading(true);
    try {
      const tokenUrl = localStorage.getItem("accessToken");
      const url =
        process.env.REACT_APP_API_BASE_URL + "/hisab/get_hisab_detail";
      const body = {
        hisab_id: number,
        page_no: 1,
      };
      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);
      const res = await apiService(url, "POST", formData, tokenUrl);
      const decryptedResponse = decryptAES(res.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        toast.success(JSON.parse(decryptedResponse).message);
        setIsLoading(false);
        const data = await JSON.parse(decryptedResponse);
        setParticipant(data?.hisab_data?.participant_user);
        const cleanedBalance = data?.hisab_data?.balance
          ? data?.hisab_data?.balance.replace(/[^\d.-]/g, "")
          : "";
        setBalance(cleanedBalance);
        setHisabName( "Copy of " + data?.hisab_data?.hisab_name);
        console.log("dddd",data)
        setDetails(data);
      } else {
        toast.error(JSON.parse(decryptedResponse).message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("error", error);
      setIsLoading(false);
    }
  }

  const deleteHisabWithDetails = async (id) => {
    try {
      setButtonDisabled(true)
      const tokenUrl = localStorage.getItem("accessToken");
      const url = process.env.REACT_APP_API_BASE_URL + "/hisab/delete_hisab";
      const body = {
        hisab_id: id,
      };
      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);
      const res = await apiService(url, "POST", formData, tokenUrl);
      const decryptedResponse = decryptAES(res.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        toast.success(language.delete_Hisab);
        const data = await JSON.parse(decryptedResponse);
        navigate('/AllHisab/:id=')
        dispatch(setFetchAllHisab(true));
        setOpen(false);
        setButtonDisabled(false)
      } else {
        //   toast.error(JSON.parse(decryptedResponse).message);
        setButtonDisabled(false)
      }
    } catch (error) {
      console.error("error", error);
      setButtonDisabled(false)
    }
  };

  const exportHisab = async (id) => {
    setIsLoading(true);
    try {
      const tokenUrl = localStorage.getItem("accessToken");
      const url =
        process.env.REACT_APP_API_BASE_URL + "/transaction/get_pdf_transaction";
      const body = {
        hisab_id: id,
      };
      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);
      const res = await apiService(url, "POST", formData, tokenUrl);
      const decryptedResponse = decryptAES(res.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        toast.success(JSON.parse(decryptedResponse).message);
        setIsLoading(false);
        const data = await JSON.parse(decryptedResponse);
        const pdfUrl = process.env.REACT_APP_API_BASE_URL + "/" + data.pdf_link;
        window.open(pdfUrl, "_blank");
      } else {
        //   toast.error(JSON.parse(decryptedResponse).message);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const markHisabCompleted = async () => {
    try {
      const tokenUrl = localStorage.getItem("accessToken");
      const url =
        process.env.REACT_APP_API_BASE_URL + "/hisab/status_update_hisab";
      const body = {
        hisab_id: number,
        type: "complete",
      };
      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);
      const res = await apiService(url, "POST", formData, tokenUrl);
      const decryptedResponse = decryptAES(res.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        toast.success(JSON.parse(decryptedResponse).message);
        const data = await JSON.parse(decryptedResponse);
        fetchDetails();
        dispatch(setFetchAllHisab(true));
        setOpen(false);
      } else {
        toast.error(JSON.parse(decryptedResponse).message);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const exitHisab = async (id) => {
    try {
      const tokenUrl = localStorage.getItem("accessToken");
      const url = process.env.REACT_APP_API_BASE_URL + "/hisab/exit_hisab";
      const body = {
        hisab_id: id,
        user_id: userData.user_id,
      };
      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);
      const res = await apiService(url, "POST", formData, tokenUrl);
      const decryptedResponse = decryptAES(res.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        toast.success(JSON.parse(decryptedResponse).message);
        const data = await JSON.parse(decryptedResponse);
        navigate(-2);
        dispatch(setFetchAllHisab(true));
        setOpen(false);
      } else {
        toast.error(JSON.parse(decryptedResponse).message);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleCopyHisab = async () => {
    try {
      const tokenUrl = localStorage.getItem("accessToken");
      const url = process.env.REACT_APP_API_BASE_URL + "/hisab/add_hisab";
      const copiedHisabName = `${hisabName}`;
      const formattedBalance =
        balance % 1 === 0 ? Math.floor(balance) : balance;
      const body = {
        user_id: userData.user_id,
        hisab_name: copiedHisabName,
        hisab_opening_balance: formattedBalance.toString(),
        edit_participant_user_id: selectedEditParticipants.join(','),
        view_participant_user_id: selectedViewParticipants.join(','),
        copy_transaction: "1",
      };
      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);
      const res = await apiService(url, "POST", formData, tokenUrl);
      const decryptedResponse = decryptAES(res.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        toast.success(JSON.parse(decryptedResponse).message);
        const data = await JSON.parse(decryptedResponse);
        navigate(-1);
        dispatch(setFetchAllHisab(true));
        setOpenModel(false);
      } else {
        toast.error(JSON.parse(decryptedResponse).message);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleRestoreHisab = async () => {
    try {
      const tokenUrl = localStorage.getItem("accessToken");
      const url = process.env.REACT_APP_API_BASE_URL + "/hisab/restore_hisab";
      const body = {
        hisab_id: number,
      };
      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);
      const res = await apiService(url, "POST", formData, tokenUrl);
      const decryptedResponse = decryptAES(res.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        toast.success(JSON.parse(decryptedResponse).message);
        const data = await JSON.parse(decryptedResponse);
        fetchDetails();
        dispatch(setFetchAllHisab(true));
        setOpenModel(false);
      } else {
        //   toast.error(JSON.parse(decryptedResponse).message);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    if (details.hisab_data?.participant_user && adminId !== true) {
      let i = 0;
      while (i < details.hisab_data.participant_user.length) {
        const item = details.hisab_data.participant_user[i];
        if (item.type === "Admin" && item.id == userData.user_id) {
          setAdminId(true); 
          break;
        }
        i++; 
      }
    }
  }, [details, userData, adminId]); 

  const options = [
    {
      id: 7,
      text: language.copy_hisab_text,
      className: "text-black",
      onClick: () => {
        setOpenModel(true);
        setIsModel(true);
      },
    },
    {
      id: 1,
      text: language.export_Hisab,
      className: "text-black",
      onClick: () => exportHisab(number),
    },
    {
      id: 2,
      text: language.edit_Hisab,
      className: "text-black",
      onClick: () => {
        const encryptedData = encryptAES(
          JSON.stringify({
            id:number
          })
        );
        const encodedData = encodeURIComponent(encryptedData);
        navigate(`/EditHisab/${encodedData}`)
      },
    },
    {
      id: 3,
      text: language.mark_hisab,
      className: "text-[#46A759]",
      onClick: () => {
        setOpen(true);
        setIsModalView(false);
      },
    },
    {
      id: 4,
      text: language.delete_Hisab,
      className: "text-[#E5474D]",
      onClick: () => {
        setOpen(true);
        setIsModalView(true);
      },
    },
    {
      id: 5,
      text: language.report_Hisab,
      className: "text-[#F5B820]",
      onClick: () => {
        const encryptedData = encryptAES(
          JSON.stringify({
            id:number
          })
        );
        const encodedData = encodeURIComponent(encryptedData);
        navigate(`/ReportHisab/${encodedData}`)
      },
    },
    {
      id: 6,
      text: language.exit_Hisab,
      className: "text-[#E5474D]",
      onClick: () => {
        setOpen(true);
      },
    },
   
    {
      id: 8,
      text: language.mark_recover,
      className: "text-[#E5474D]",
      onClick: () => {
        setOpenModel(true);
        setIsModel(false);
      },
    },
  ];

  const userType = details?.hisab_data?.user_type;
  const hisabStatus = details?.hisab_data?.hisab_status;

  const filteredOptions = options
    .map((option) => {
      if (userType !== "Admin" && option.text === "Edit Hisab") {
        return null;
      }
      if (userType === "Admin" && option.text === language.edit_Hisab) {
        return {
          ...option,
          disabled: hisabStatus === "complete",
        };
      }
      if (
        userType === "Admin" &&
        details.hisab_data.user_id === userData.user_id
      ) {
        if (
          option.text === language.copy_hisab_text ||
          option.text === language.export_Hisab ||
          option.text === language.edit_Hisab ||
          (hisabStatus === "complete"
            ? option.text === language.mark_recover
            : option.text === language.mark_hisab) ||
          option.text === language.delete_Hisab
        ) {
          return option;
        }
      } else {
        if (
          option.text === language.export_Hisab ||
          option.text === language.exit_Hisab ||
          option.text === language.report_Hisab
        ) {
          return option;
        }
      }
      return null;
    })
    .filter(Boolean);

  const participantsExcludingUser = participant.filter(
    (item) => String(item.id) !== String(userData.user_id)
  );
  function handelAddParticipant(){
    setAddParticipantOpen(true)
  }

  return (
    <>
      <div className="!w-[100%] h-[100%] flex !max-h-[100vh] overflow-auto">
        {isLoading ? (
          <div className="flex items-center !justify-center !w-[100%] !h-[100vh]">
            <Loader width={40} height={40} color={"black"} />
          </div>
        ) : id && id.length > 4 && details ? (
          <div className="w-[100%]">
            <div className="box-border flex flex-col items-center justify-center !gap-5 overflow-x-hidden">
              {/* Header Section */}
              <div className="flex items-center p-2 w-[100%] max-480:p-2 mt-[3px] border-b gap-[8px]">
                <button
                  className="py-2 max-480:w-[40px]"
                  onClick={() => navigate(-1)}
                  aria-label="Go back"
                >
                  <img alt="Back" src={BackArrow} width={22} height={22} />
                </button>
                <h2 className="text-[20px] font-[500] font-sans max-768:text-[18px]">
                  {details.hisab_data.hisab_name}
                </h2>
              </div>

              
     
              {/* Income/Expense/Balance Section */}
              <Grid
                container
                spacing={2}
                className="!w-[95%] bg-[#f6f5e8] mt-1 rounded-[8px] max-1030:h-[auto] p-2 !ml-0"
                style={{
                  background:
                    "linear-gradient(135.53deg, rgba(255, 216, 122, 0.1) 100.61%, rgba(246, 184, 37, 0.1) 0.58%)",
                }}
              >
                {/* Left 6 Columns */}
                <Grid
                  item
                  xs={12}
                  md={6}
                  className="flex flex-col gap-4 !pt-1 !pl-1"
                >
                  <Grid container spacing={1}>
                    <Grid item xs={4} md={4}>
                      <section className="flex flex-col items-center">
                        <p className="text-[14px] text-[#8A8D93] font-normal max-480:text-[12px]">
                          {language.income}
                        </p>
                        <p className="text-[#46A759] text-[12px] font-bold max-480:text-[12px]">
                        ₹
                          {details.hisab_data.income}
                        </p>
                      </section>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <section className="flex flex-col items-center justify-center">
                        <p className="text-[14px] text-[#8A8D93] max-480:text-[12px]">
                          {language.expense}
                        </p>
                        <p className="text-[#E5474D] text-[10px] font-bold max-480:text-[10px]">
                        ₹
                          {details.hisab_data.expense}
                        </p>
                      </section>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <section className="flex flex-col items-center justify-center">
                        <p className="text-[14px] text-[#8A8D93] max-480:text-[12px]">
                          {language.balance}
                        </p>
                        <p className="text-[#424242] text-[10px] font-bold max-480:!text-[10px]">
                          {details?.hisab_data?.balance ? (
                            <>
                              <span className="inline-block mr-1">
                                {details.hisab_data.balance.charAt(0)}
                              </span>
                              <span>{details.hisab_data.balance.slice(1)}</span>
                            </>
                          ) : (
                            "₹0.00"
                          )}
                        </p>
                      </section>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Right 6 Columns */}
                <Grid
                  item
                  xs={12}
                  md={6}
                  className="flex flex-col gap-4 !pt-1 !pl-1"
                >
                  <Grid container spacing={1}>
                    <Grid
                      xs={12}
                      className="!flex !items-center justify-end !pt-1 max-768:justify-center"
                    >
                      <button
                        onClick={() => exportHisab(number)}
                        className="!capitalize !bg-gradient-to-r h-[34px] w-[100%] px-2 rounded-[3px] from-[#FFD87A] via-[#F6B825] to-[#F6B825] !text-[14px] ! !text-[#424242] max-480:!text-[12px] md:!h-[35px] md:!w-[100px] md:!text-[14px] max-768:w-[80%] max-768:mt-3 max-768:mb-1"
                      >
                        {language.export_Hisab}
                      </button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Recent Transactions Section */}
              <div className="w-full items-center">
                <section className="flex justify-between items-center px-4 max-480:p-3 mr-[3px] ml-[6px]">
                  <h2 className="text-[12px] font-bold md:text-[14px]">
                    {details.hisab_data.hisab_participation_count}{" "}
                    {language.participants}
                  </h2>
                  {details &&
                    details.hisab_data.participant_user?.map((item) => {
                      if (
                        item.type === "Admin" &&
                        item.id == userData.user_id
                      ) {
                        return (
                          <div
                            className="flex items-center justify-center !bg-gradient-to-r from-[#FFD87A] via-[#F6B825] to-[#F6B825] rounded-xl"
                            key={item.id}
                          >
                            <Button
                              className="!text-[500] !w-[65px] !h-[23px] !text-black !p-0 !capitalize max-480:!text-[9px] text-[9px] gap-1 !flex !items-center"
                              onClick={handelAddParticipant}
                            >
                              <img
                                src={blackAdd}
                                alt="add_icon"
                                className="h-[15px] w-[15px]"
                              />
                              Add
                            </Button>
                          </div>
                        );
                      }
                      return;
                    })}
                </section>

                <section className="flex flex-col items-center w-full mt-4 mb-5 gap-2 details-scrollbar overflow-x-hidden">
                  {details.hisab_data.participant_user?.map((item) => (
                    <ParticipantCard
                      key={item.id}
                      participant={item}
                      adminId={adminId}
                      hisabStartDate={details.hisab_data.hisab_start_date}
                    />
                  ))}
                </section>
                <Box className="w-[100%] px-[23px]">
                  <Typography className="font-semibold text-[14px] break-words">
                    {language.hisab_Description}
                  </Typography>

                  <Typography className="mt-1 text-[12px] break-words">
                    {details.hisab_data.hisab_description}
                  </Typography>
                </Box>

                <Box className="flex flex-col justify-center items-center gap-1 border-[#F5F5F5] border-2 ml-[23px] mr-[19px] rounded-lg mt-2">
                  {filteredOptions.map((option) => (
                    <div key={option.id} className="w-[100%]">
                      <Typography
                        className={`p-2 font-semibold text-[12px] cursor-pointer ${
                          option.disabled
                            ? "cursor-none text-gray-400"
                            : option.className
                        }`}
                        onClick={!option.disabled ? option.onClick : undefined}
                      >
                        {option.text}
                      </Typography>
                      <hr />
                    </div>
                  ))}
                </Box>
                {details.hisab_data.user_permission === "edit" ? (
                  <Button
                    className="fixed right-[15px] bottom-[3px] max-768:right-[3px]"
                    onClick={() => {
                      const encryptedData = encryptAES(
                        JSON.stringify({
                          id: details?.hisab_data?.hisab_id,
                        })
                      );
                      const encryptedData1 = encryptAES(
                        JSON.stringify({
                          hisabType: "Expense",
                        })
                      );
                      const encodedData = encodeURIComponent(encryptedData);
                      const encodedData1 = encodeURIComponent(encryptedData1);
                      navigate(`/AddIncome/${encodedData}/${encodedData1}`);
                    }}
                  >
                    <img
                      src={plus}
                      alt="plus_image"
                      className="w-[50px] max-424:w-[40px]"
                    />
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="p-4 pt-2 pl-6 pb-4">
              <h1 className="text-[#8A8D93] text-[12px] max-480:text-[10px]">{language.created_By} {details?.hisab_data?.owner_name}</h1>
              <section className="flex">
                <h1 className="text-[#8A8D93] text-[12px] pr-1 max-480:text-[10px]"> {language.created} {format( new Date(details?.hisab_data?.hisab_created_date), 'dd MMMM, yyyy')}  </h1>
                <h1 className="text-[#8A8D93] text-[12px] max-480:text-[10px]"> | {language.hisab_Start_On} {format( new Date(details?.hisab_data?.hisab_start_date), 'dd MMMM, yyyy')} </h1>
              </section>
            </div>
          </div>
        ) : (
          <div className="w-[100%] flex items-center justify-center h-[100vh]">
            {/* <MockUP/> */}
          </div>
        )}
        <CustomModal
          open={open}
          handleClose={() => {
            setOpen(false);
          }}
          isCloseIcon={false}
          widthFor={{ xs: "85%", sm: "280px", md: "380px" }}
          className="h-fit"
        >
          {isModalView ? (
            <Box className="flex flex-col gap-6 max-768:gap-7 h-fit">
              <Typography className="mt-3 font-semibold w-full text-center lg:mb-4">
                {language.delete_Hisab_Msg}
              </Typography>
              <Box className="flex justify-evenly max-768:gap-[15px]">
                <Button
                  style={{ border: "1px solid black" }}
                  className="px-4 rounded w-28 h-[32px] text-black capitalize"
                  onClick={() => setOpen(false)}
                >
                  {language.no}
                </Button>
                <Button
                  className="px-4 !bg-gradient-to-r from-[#FFD87A] via-[#F6B825] to-[#F6B825]  text-black rounded w-28 h-[32px] capitalize"
                  onClick={() => deleteHisabWithDetails(number)}
                  disabled={buttonDisabled}
                >
                  {language.yes}
                </Button>
              </Box>
            </Box>
          ) : isModalView === false ? (
            <Box className="flex flex-col gap-2">
              <Typography className="font-semibold w-full text-center mb-4">
                {language.mark_complete}
              </Typography>
              <Typography className="w-full text-center text-[12px]">
                {language.mark_complete_subtitle}
              </Typography>
              <Box className="flex justify-evenly max-768:gap-[15px]">
                <Button
                  style={{ border: "1px solid black" }}
                  className="px-4 rounded w-28 h-[32px] text-black"
                  onClick={() => setOpen(false)}
                >
                  {language.no}
                </Button>
                <button
                  className="px-4 !bg-gradient-to-r from-[#FFD87A] via-[#F6B825] to-[#F6B825]  text-black rounded  w-28 h-[32px]"
                  onClick={() => markHisabCompleted(number)}
                >
                  {language.yes}
                </button>
              </Box>
            </Box>
          ) : (
            <Box className="flex flex-col gap-6 max-768:gap-7">
              <Typography className="mt-3 font-semibold w-full text-center">
                {language.exit_Hisab_Msg}
              </Typography>
              <Box className="flex justify-evenly max-768:gap-[15px]">
                <Button
                  style={{ border: "1px solid black" }}
                  className="px-4 rounded w-28 h-[32px] text-black"
                  onClick={() => setOpen(false)}
                >
                  {language.no}
                </Button>
                <Button
                  className="px-4 !bg-gradient-to-r from-[#FFD87A] via-[#F6B825] to-[#F6B825] text-black rounded w-28 h-[32px]"
                  onClick={() => exitHisab(number)}
                >
                  {language.yes}
                </Button>
              </Box>
            </Box>
          )}
        </CustomModal>

        <Model
          open={openModel}
          handleClose={() => {
            setOpenModel(false);
          }}
          isCloseIcon={false}
          widthFor={{ xs: "90%", sm: "280px", md: "380px" }}
          // height={participantsExcludingUser.length > 0 ? "260px" : "200px"}
        >
          {isModel === true ? (
            <Box className="h-[auto] flex flex-col !gap-4 sm:gap-2 lg:gap-4 max-w-[95%] sm:max-w-[90%] md:max-w-[80%] mx-auto">
              <Typography className="font-semibold text-center pt-2">
                {language.copy_hisab_text}
              </Typography>

              <Box className="flex gap-1 mb-1 sm:mb-2">
                <TextField
                  label={language.hisab_Name}
                  inputProps={{ maxLength: 30 }}
                  value={hisabName}
                  onChange={(e) => setHisabName(e.target.value)}
                  fullWidth
                  size="small"
                  className="text-xs sm:text-sm"
                />
              </Box>

              {/* Opening Balance TextField */}
              <Box className="flex gap-1 mb-1 sm:mb-2">
                <TextField
                  label={language.opening_Balance}
                  value={balance}
                  onChange={(e) => setBalance(e.target.value)}
                  fullWidth
                  size="small"
                  className="text-xs sm:text-sm"
                  type="text"
                  height="0.4375em"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                />
              </Box>

              {participantsExcludingUser.length > 0 && (
                <Box className="flex items-center gap-1">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isSelected}
                        onChange={handleSelectAll}
                        color="primary"
                        font-size="10px"
                        size="small"
                      />
                    }
                    label={language.copy_hisab_sharetext}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                      },
                    }}
                    className="text-xs sm:text-sm"
                  />
                </Box>
              )}

              {/* Buttons */}
              <Box className="flex justify-evenly max-768:gap-[15px] pb-2">
                <Button
                  style={{ border: "1px solid black" }}
                  className="px-4 rounded w-28 h-[32px] text-black text-xs sm:text-sm"
                  onClick={()=> setOpenModel(false)}
                >
                  {language.no}
                </Button>
                <Button
                  className="px-4 !bg-gradient-to-r from-[#FFD87A] via-[#F6B825] to-[#F6B825] text-black rounded w-28 h-[32px] text-xs sm:text-sm"
                  onClick={handleCopyHisab}
                >
                  {language.yes}
                </Button>
              </Box>
            </Box>
          ) : (
            <Box className="flex flex-col gap-4 max-768:gap-7">
              <Typography className="mt-2 font-semibold w-full text-center">
                {language.ask_Restore}
              </Typography>
              <Box className="flex justify-evenly max-768:gap-[15px] mb-2">
                <Button
                  style={{ border: "1px solid black" }}
                  className="px-4 rounded w-28 h-[32px] text-black"
                  onClick={() => setOpenModel(false)}
                >
                  {language.no}
                </Button>
                <Button
                  className="px-4 !bg-gradient-to-r from-[#FFD87A] via-[#F6B825] to-[#F6B825] text-black rounded w-28 h-[32px]"
                  onClick={() => handleRestoreHisab(number)}
                >
                  {language.yes}
                </Button>
              </Box>
            </Box>
          )}
        </Model>
      </div>
      <ContactModal open={addParticipantOpen} setAddParticipantOpen={setAddParticipantOpen}/>
    </>
  );
};

export default Details;
