import Grid from '@mui/material/Grid2';
import { useLocation } from "react-router-dom";
import { apiService } from "../app/services/api";
import MenuIcon from '@mui/icons-material/Menu';
import Header from "../components/Header/header";
import React, { useEffect, useState } from "react";
import SideBar from "../components/SideBar/Sidebar";
import { Box, Drawer, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import HisabDetails from "../components/HisabDetails/HisabDetails";
import { decryptAES, encryptAES } from "../components/CommonLibrary";
import HisabDashboard from "../components/HisabDashboard/HisabDashboard";
import { setFetchAllHisab, setGetUser, setUserMobileNumber } from "../app/Redux/reducer";

function Home() {
  // Hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const fetchAllHisab = useSelector(state => state.usersLoginData.fetchAllHisab);
  const userLoginData = useSelector((state) => state.usersLoginData.loginUserDetails);

  // States
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Others
  const path = ["/AllHisab/:id=", "/DeletedHisab/:id=", "/Notification/:id=", "/", '/account/:id=', "/HelpCenter/:id="];
  const responsive = path.includes(location.pathname);

  // Handler
  const getUser = async () => {
    const parameters = { user_id: userLoginData?.user_id };
    
    try {
      const url = process.env.REACT_APP_API_BASE_URL + "/users/get_user";
      const tokenUrl = localStorage.getItem("accessToken");
      const encryptedData = encryptAES(JSON.stringify(parameters));
      const languageCode = "en";
      const formData = new FormData();
      formData.append("data", encryptedData);
      const result = await apiService(
        url,
        "POST",
        formData,
        tokenUrl,
        languageCode
      );

      const decryptedResponse = decryptAES(result.data.response);

      if (
        JSON.parse(decryptedResponse).status === "200" ||
        JSON.parse(decryptedResponse).status === 200
      ) {
        dispatch(setGetUser(JSON.parse(decryptedResponse).users_data));
        dispatch(setUserMobileNumber(JSON.parse(decryptedResponse).users_data.mobile_number));
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  // Effects
  useEffect(() => {
    getUser()
    if (fetchAllHisab) {
      getUser()
      dispatch(setFetchAllHisab(false))
    }
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [location, fetchAllHisab]);

  return (
    <>  
      {windowWidth > 991 ? (
        <Grid
          container
          sx={{
            minHeight: '100vh',
          
          }}
        >
          <Grid
            item
            size={0.5}
            sx={{
              height: '100vh',
              // borderRight: '1px solid #eee',
              position: 'sticky',
              top: 0,
              maxWidth: '60px'
            }}
            className="md:overflow-hidden overflow-auto"
          >
            <SideBar setDrawerOpen={setDrawerOpen} />
          </Grid>

          <Grid
            item
            size={4}
            sx={{
              height: '100vh',
              overflow: 'hidden'
            }}
            className="border"
          >
            <HisabDashboard />
          </Grid>

          <Grid
            item
            size={7.5}
            className="homepageScrollbar"
            sx={{
              height: '100vh',
              overflowY: 'auto'
            }}
          >
            <HisabDetails />
          </Grid>
        </Grid>
      ) :
        <Box className="w-[100%] relative">
          <Box className="flex bg-[#FAFAFA]">
            <IconButton onClick={() => setDrawerOpen(true)} className=" top-0 left-0 z-20">
              <MenuIcon />
            </IconButton>
            <Header />
          </Box>
          <Box className="flex !w-[100%]">
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
            >
              <SideBar setDrawerOpen={setDrawerOpen} />
            </Drawer>

            {responsive && <HisabDashboard />}
            {!responsive && <HisabDetails />}
          </Box>
        </Box>}
    </>
  );
}

export default Home;
