import { useSelector } from "react-redux";
import Otp from "../components/OtpInput/Otp";
import { Box, Typography } from "@mui/material";
import Loader from "../components/Loader/ButtonLoader";
import { apiService } from "../app/services/api";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { decryptAES, encryptAES } from "../components/CommonLibrary";
import Header from "../UI/Header";
import BackArrow from "../images/hisabdetails/LeftArrow.png";

function CurrentMpin() {
  const language = useSelector((state) => state.usersLoginData.allLanguage);

  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [dynamicWidth, setDynamicWidth] = useState("47px");
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [dynamicHeight, setDynamicheight] = useState("50px");
  const [dynamicMarginRight, setDynamicMarginRight] = useState("30px");

  useEffect(() => {
    updateMarginRight();
    window.addEventListener("resize", updateMarginRight);
    return () => window.removeEventListener("resize", updateMarginRight);
  }, [dynamicWidth, dynamicHeight, dynamicMarginRight]);

  const handleOnClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsBtnDisabled(true);
    try {
      const url = process.env.REACT_APP_API_BASE_URL + "/security/verify_pin";
      const userMpin = {
        pin: otp,
      };
      const tokenUrl = localStorage.getItem("accessToken");
      const encryptedData = encryptAES(JSON.stringify(userMpin));

      const formData = new FormData();
      formData.append("data", encryptedData);

      const result = await apiService(url, "POST", formData, tokenUrl);

      const decryptedResponse = decryptAES(result.data.response);

      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        setIsLoading(false);
        setIsBtnDisabled(false);
        setErrorMessage("");
        navigate("/newpinset");
      } else {
        setIsLoading(false);
        setIsBtnDisabled(false);
        setErrorMessage(JSON.parse(decryptedResponse).message);
      }
    } catch (error) {
      console.error("Error during OTP submission:", error);
      setIsLoading(false);
      setIsBtnDisabled(false);
      setErrorMessage("");
    }
  };

  const updateMarginRight = () => {
    if (window.innerWidth <= 320) {
      setDynamicWidth("43px");
      setDynamicheight("55px");
      setDynamicMarginRight("18px");
    } else if (window.innerWidth <= 375) {
      setDynamicWidth("55px");
      setDynamicheight("60px");
      setDynamicMarginRight("18px");
    } else if (window.innerWidth <= 425) {
      setDynamicWidth("62px");
      setDynamicheight("65px");
      setDynamicMarginRight("18px");
    } else if (window.innerWidth <= 1024) {
      setDynamicMarginRight("55px");
    } else {
      setDynamicWidth("47px");
      setDynamicheight("50px");
      setDynamicMarginRight("30px");
    }
  };

  return (
    <Box>
     <header className="flex gap-1 border-b " >
          <button
            className="max-480:w-[46px] p-2 flex justify-center items-center"
            onClick={() => navigate(-1)}
            aria-label="Go back"
          >
            <img alt="Back" src={BackArrow} width={24} height={24} />
          </button>
          <Header title={language?.current_pin} className="w-full p-2 "/>
        </header>
      <Box className="flex justify-center items-center h-[70vh]">
        <Box className="max-1024:w-[580px] h-[248px] max-425:!w-[90%] flex flex-col justify-center pr-[20px] pl-[28px] max-425:px-[40px] max-375:!px-[30px]  overflow-hidden z-30">
          <Typography className="text-[20px] font-bold max-375:!text-[16px]">
            {language.current_pin}
          </Typography>
          <form onSubmit={handleOnClick}>
            <Box className="mt-[12px] flex justify-center items-center max-1024:ml-[45px] max-425:!ml-[15px]">
              <Otp
                setOtp={(e) => {
                  setOtp(e);
                  setErrorMessage("");
                }}
                otp={otp}
                inputsNum={4}
                width={dynamicWidth}
                height={dynamicHeight}
                marginRight={dynamicMarginRight}
                fontSize={18}
                fontWeight={"600"}
              />
            </Box>
            <Typography className="text-red-600 font-semibold mt-2 text-[12px]">
              {errorMessage}
            </Typography>
          
            <Box className="flex items-center mt-7">
            <button
              className={`${
                otp.length >= 4
                  ? "bg-gradient-to-r from-[#FFD87A] to-[#F6B825]"
                  : "bg-[#FFD87A] opacity-[35%] text-gray-400"
              } w-[100%] h-[45.67px] rounded-[5px] text-[14px] px-[8px] font-medium`}
              disabled={otp.length >= 4 ? isBtnDisabled : true}
            >
              {isLoading === true ? (
                <Loader width={20} height={20} color={"black"} />
              ) : (
                language.confirm_PIN
              )}
            </button>
            <NavLink
              style={{ border: "1px solid #F6B825" }}
              className="text-black ml-6 bg-white font-medium text-[16px] w-[100%] h-[45.67px] px-4 py-2 rounded-md transition-colors text-center"
              to="/account"
            >
              {language.cancel}
            </NavLink>
          </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
}

export default CurrentMpin;
