import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import Faq from "../images/CustomerCare/Group 45263.png";
import contactUs from "../images/CustomerCare/Group 45264.png";
import reportIssue from "../images/CustomerCare/Group 45262.png";
import HelpCenterImage from "../images/CustomerCare/monkey-vector-for-support1 1 (5).png";
import support from "../images/hisabdetails/supportTickets.png"

const CustomerCare = () => {
  const location = useLocation();
  const language = useSelector((state) => state.usersLoginData.allLanguage);

  return (
    <div className="w-[100%] flex flex-col pt-8  items-center customerCareScroll">
      <img src={HelpCenterImage} alt="Help Center Image" className="h-[235px] w-auto" />
      <div className="text-center my-3">
        <h4 className="text-[18px] font-bold">{language.suppor_ttitle}</h4>
        <p className="text-[#B1B1B1]">{language.support_subtitle}</p>
      </div>
      <div className="w-[79%] flex flex-col max-768:w-[87%] max-1024:w-[87%] gap-[15px]">
        <NavLink to="/ReportIssue">
          <div
            className="flex shadow-custom items-center h-[70px] !rounded-[6px]"
            style={{
              border:
                location.pathname === "/ReportIssue" || location.pathname === "/HelpCenter"
                  ? "1px solid #F6B825"
                  : "1px solid #F5F5F5",
              borderRadius: "10px",
              background:`${location.pathname === "/ReportIssue" || location.pathname === "/HelpCenter" ? "linear-gradient(135.53deg, rgba(255, 216, 122, 0.1) 2.61%, rgba(246, 184, 37, 0.1) 96.58%)" : ''}`,
            }}
          >
            <img
              src={reportIssue}
              alt="report_Image"
              className="w-[43.48px] h-[43.48px] m-3"
            />
            <div className="flex flex-col">
              <p className="text-[18px] font-medium ml-[16px]">{language.report_issue}</p>
              <p className="text-[12px] text-gray-500 ml-[16px]">
                {language.support_issues}
              </p>
            </div>
          </div>
        </NavLink>
        <NavLink to="/supportTickets">
          <div
            className="flex shadow-custom items-center h-[70px] !rounded-[6px]"
            style={{
              border:
                location.pathname === "/supportTickets"
                  ? "1px solid #F6B825"
                  : "1px solid #F5F5F5",
              borderRadius: "10px",
              background:`${location.pathname === "/supportTickets" ? "linear-gradient(135.53deg, rgba(255, 216, 122, 0.1) 2.61%, rgba(246, 184, 37, 0.1) 96.58%)" : ''}`,
            }}
          >
            <img
              src={support}
              alt="report_Image"
              className="w-[43.48px] h-[43.48px] m-3"
            />
            <div className="flex flex-col">
              <p className="text-[18px] font-medium ml-[16px]">{language.reported_issue}</p>
              <p className="text-[12px] text-gray-500 ml-[16px]">
                {language.reported_issue_subtitle}
              </p>
            </div>
          </div>
        </NavLink>
        <NavLink to="/FAQ">
          <div
            className="flex shadow-custom items-center h-[70px] !rounded-[6px]"
            style={{
              border:
                location.pathname === "/FAQ"
                  ? "1px solid #F6B825"
                  : "1px solid #F5F5F5",
              borderRadius: "10px",
              background:`${location.pathname === "/FAQ" ? "linear-gradient(135.53deg, rgba(255, 216, 122, 0.1) 2.61%, rgba(246, 184, 37, 0.1) 96.58%)" : ''}`,
            }}
          >
            <img
              src={Faq}
              alt="FAQ_Image"
              className="w-[43.48px] h-[43.48px] m-3"
            />
            <div className="flex flex-col">
              <p className="text-[18px] font-medium ml-[16px]">{language.faq_text}</p>
              <p className="text-[12px] text-gray-500 ml-[16px]">
                {language.support_faq}
              </p>
            </div>
          </div>
        </NavLink>

        <NavLink to="/ContactUs">
          <div
            className="flex shadow-custom items-center h-[70px] !rounded-[6px]"
            style={{
              border:
                location.pathname === "/ContactUs"
                  ? "1px solid #F6B825"
                  : "1px solid #F5F5F5",
              borderRadius: "10px",
              background:`${location.pathname === "/ContactUs"? "linear-gradient(135.53deg, rgba(255, 216, 122, 0.1) 2.61%, rgba(246, 184, 37, 0.1) 96.58%)" : ''}`,
            }}
          >
            <img
              src={contactUs}
              alt="contactUs_image"
              className="w-[43.48px] h-[43.48px] m-3"
            />
            <div className="flex flex-col">
              <p className="text-[18px] font-medium ml-[16px]">{language.contact_us}</p>
              <p className="text-[12px] text-gray-500 ml-[16px]">
                {language.contact_us_msg}
              </p>
            </div>
          </div>
        </NavLink>
      </div>
    </div>
  );
};

export default CustomerCare;
