import { useLocation } from "react-router-dom";

const HisabPdf = () => {
    const location = useLocation();
    const pdfUrl = location.state?.pdfUrl;
  
    if (!pdfUrl) {
      return <h1>No PDF available</h1>;
    }
  
    return (
      <div className="pdf-viewer h-[100%]">
        <iframe
          src={pdfUrl}
          title="Hisab PDF Viewer"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      </div>
    );
};

export default HisabPdf;
