import slid1Image from "../images/loginpage/image.png";
import LockIcon from '@mui/icons-material/Lock';

function MockUP() {
    return (
        <>
            <div className="w-[100%] flex justify-center items-center h-[100vh] bg-[#FAFAFA]">
                <div className="flex flex-col items-center">
                    <img src={slid1Image} className="" alt="mockup" />
                    <h1 className="text-[28px] ml-8 text-[grey] font-normal text-center select-none">
                        Desi Hisab Web
                    </h1>
                    <div className="text-[12px] w-[500px] text-center select-none">
                        Now Create all your Hisabs on Desi Hisab.<br />
                        You can Manage your Personal Expenses,
                        Group Expenses, <br />
                        Personal Borrowings, Dues, Debts, and any{" "}
                        Financial Transactions
                        on Desi Hisab.
                    </div>
                    <h1 className="text-[10px] text-center mt-12"><LockIcon className="text-[10px]" /> <span>Your hisabs are end-to-end encrypted</span></h1>
                </div>
            </div>
        </>
    )
}

export default MockUP;
