import Header from "../UI/Header";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { apiService } from "../app/services/api";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/Loader/ButtonLoader";
import { useDispatch, useSelector } from "react-redux";
import { setFetchAllHisab } from "../app/Redux/reducer";
import share from "../images/hisabdetails/ShareIcon.png";
import { decryptAES, encryptAES } from "../components/CommonLibrary";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import CloseIcon from "../images/Income/close-square.png";
import {
  Avatar,
  Box,
  Button,
  Fade,
  InputAdornment,
  Popper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ContactModal from "../components/ContactModal/ContactModal";

function AddHisab() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isInput, setIsInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hisabNameInput, setHisabNameInput] = useState("");
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [addParticipantOpen, setAddParticipantOpen] = useState(false);
  const [viewParticipantsFromAdd, setViewParticipantsFromAdd] = useState(
    new Set()
  );
  const [editParticipantsFromAdd, setEditParticipantsFromAdd] = useState(
    new Set()
  );

  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];
  const [fetchDetails, setFetchDetails] = useState("");
  const { id } = useParams();
  let decryptedID;
  if (id) {
    decryptedID = decryptAES(id);
  }
  const urlObject = JSON.parse(decryptedID ? decryptedID : null);
  const number = urlObject?.id;
  const language = useSelector((state) => state.usersLoginData.allLanguage);
  const FirstIdAllHisab = useSelector((state) => state.usersLoginData?.firstId);
  const encryptedId = encryptAES(JSON.stringify({ id: FirstIdAllHisab }));
  const encodedData = encodeURIComponent(encryptedId);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const userLoginData = useSelector((state) => state.usersLoginData.getUser);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: number
      ? {
          HisabName: fetchDetails?.hisab_data?.hisab_name,
          HisabDescription: fetchDetails?.hisab_data?.hisab_description,
          Amount: fetchDetails?.hisab_data?.hisab_opening_balance,
          start_date: fetchDetails?.hisab_data?.hisab_start_date,
          Date: formattedToday,
        }
      : {
          Amount: "",
          Date: formattedToday,
        },
  });
  const tokenUrl = localStorage.getItem("accessToken");
  const userData = useSelector(
    (state) => state.usersLoginData.loginUserDetails
  );

  useEffect(() => {
    if (number) {
      getHisabDetails();
    } else if (number === undefined) {
      reset({
        HisabName: "",
        HisabDescription: "",
        Amount: "",
        start_date: "",
        Date: formattedToday,
      });
      setHisabNameInput("");
    }
  }, [number]);

  async function getHisabDetails() {
    setIsLoading(true);
    try {
      const tokenUrl = localStorage.getItem("accessToken");
      const url =
        process.env.REACT_APP_API_BASE_URL + "/hisab/get_hisab_detail";
      const body = {
        hisab_id: number,
        page_no: 1,
      };
      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);
      const res = await apiService(url, "POST", formData, tokenUrl);
      const decryptedResponse = decryptAES(res.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        toast.success(JSON.parse(decryptedResponse).message);
        setIsLoading(false);
        const data = await JSON.parse(decryptedResponse);
        const hisabStartDate = data.hisab_data?.hisab_start_date;
        const parsedDate = new Date(hisabStartDate);
         //const formattedDate = parsedDate.toISOString().split("T")[0];
        const formattedDate = parsedDate.toLocaleDateString('en-CA');

        setFetchDetails({
          ...data,
          hisab_data: {
            ...data.hisab_data,
            hisab_start_date: formattedDate,
          },
        });
      } else {
        toast.error(JSON.parse(decryptedResponse).message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("error", error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (fetchDetails && fetchDetails.hisab_data) {
      const amount = fetchDetails.hisab_data.hisab_opening_balance.replace(/,/g, '').replace(/\.00$/, '');
      const sanitizedAmount = amount
        ? parseFloat(amount).toFixed(
            2
          ) === "0.00"
          ? "0"
          : parseFloat(amount)
              .toString()
              .replace(/\.00$/, "")
        : "";
      setValue("HisabName", fetchDetails.hisab_data.hisab_name);
      setValue("HisabDescription", fetchDetails.hisab_data.hisab_description);
      setValue("Amount", fetchDetails.hisab_data.hisab_opening_balance);
      setValue("Date", fetchDetails.hisab_data.hisab_start_date);
    }
    setHisabNameInput(fetchDetails?.hisab_data?.hisab_name);
  }, [fetchDetails, setValue]);

  const onAddHisab = async (data) => {
    const rawValue = data.Amount.replace(/,/g, "");
    setIsLoading(true);
    setIsBtnDisabled(true);
    try {
      const url = number
        ? process.env.REACT_APP_API_BASE_URL + "/hisab/edit_hisab"
        : process.env.REACT_APP_API_BASE_URL + "/hisab/add_hisab";
      const body = {
        user_id: userData.user_id.toString(),
        hisab_id: number,
        hisab_name: data.HisabName,
        hisab_description: data.HisabDescription,
        hisab_opening_balance: rawValue ? rawValue : 0,
        hisab_start_date: data.Date,
        edit_participant_user_id: null,
        view_participant_user_id: null,
      };
      if (true) {
        const edit = Array.from(editParticipantsFromAdd)
          .map((participant) => `${participant?.user_id ? participant?.user_id : participant?.id}`)
          .join(", ");
        const view = Array.from(viewParticipantsFromAdd)
          .map((participant) => `${participant?.user_id ? participant?.user_id : participant?.id}`)
          .join(", ");
        body.edit_participant_user_id = edit;
        body.view_participant_user_id = view;
      }
      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);
      const res = await apiService(url, "POST", formData, tokenUrl);
      const decryptedResponse = decryptAES(res.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        setIsLoading(false);
        setIsBtnDisabled(false);
        toast.success(JSON.parse(decryptedResponse).message);
        const data = await JSON.parse(decryptedResponse);
        dispatch(setFetchAllHisab(true));
        navigate(`/AllHisab/${encodedData}`);
      } else {
        setIsLoading(false);
        setIsBtnDisabled(false);
        toast.error(JSON.parse(decryptedResponse).message);
      }
    } catch (error) {
      setIsLoading(false);
      setIsBtnDisabled(false);
      console.log(error);
    }
  };

  const handlePopperClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };
  
  useEffect(() => {
    const editParticipants = fetchDetails?.hisab_data?.participant_user?.filter(
      (item) => item.permission === "edit" && item?.id != userLoginData?.id
    );
    const editUserIds = editParticipants?.map((item) => item);
    setEditParticipantsFromAdd(new Set(editUserIds));

    const viewParticipants = fetchDetails?.hisab_data?.participant_user?.filter(
      (item) => item.permission === "view" && item?.id != userLoginData?.id
    );
    const viewUserIds = viewParticipants?.map((item) => item);
    setViewParticipantsFromAdd(new Set(viewUserIds));

  }, [fetchDetails]);

  return (
    <Box className="w-full">
      <Header title={number ? language.edit_Hisab : language.add_Hisab} />
      <Box
        component="form"
        className="flex flex-col items-center h-[60%] w-full md:gap-[12px] mt-[18px]"
        onSubmit={handleSubmit(onAddHisab)}
      >
        <TextField
          label={
            <span>
              {language.hisab_Name} <span className="text-red-500">*</span>
            </span>
          }
          variant="outlined"
          className="w-[90%] bg-white"
          inputProps={{ maxLength: 30 }}
          InputLabelProps={number ? { shrink: true } : undefined}
          {...register("HisabName", {
            required: "HisabName is required",
            maxLength: {
              value: 30,
              message: "Hisab Name cannot be longer than 30 characters",
            },
            onChange: (e) => {
              setHisabNameInput(e.target.value);
              setIsInput(e.target.value);
            },
          })}
          error={!!errors.HisabName}
          helperText={errors.HisabName?.message}
        />

        <TextField
          label={language.hisab_Description}
          variant="outlined"
          multiline
          rows={4}
          className="!w-[90%] mt-2  bg-white"
          inputProps={{ maxLength: 150 }}
          InputLabelProps={number ? { shrink: true } : undefined}
          sx={{
            "& .MuiInputBase-root": {
              height: "100px",
              alignItems: "flex-start",
            },
            "& .MuiOutlinedInput-root": {
              padding: "14px",
            },
          }}
          {...register("HisabDescription", {
            maxLength: {
              value: 150,
              message: "Hisab Description cannot be longer than 100 characters",
            },
            onChange: (e) => setIsInput(e.target.value),
          })}
          error={!!errors.HisabDescription}
          helperText={errors.HisabDescription?.message}
        />

        <Box className="flex !mt-4 w-[90%] max-680:flex-col gap-5">
          <TextField
            label={language.opening_Balance}
            variant="outlined"
            type="text"
            className="w-[50%] bg-white max-680:w-full"
            inputProps={{
              inputMode: "numeric",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">₹</InputAdornment>
              ),
            }}
            {...register("Amount", {
              required: false,
              validate: {
                // Validation for numeric values
                numeric: (value) => {
                  const numericValue = value.replace(/,/g, "");
                  return (
                    !numericValue ||
                    /^-?\d+$/.test(numericValue) ||
                    "Please enter numbers only"
                  );
                },
                // Validation for max 10 digits
                maxDigits: (value) => {
                  const numericValue = value.replace(/,/g, "");
                  const numericLength = numericValue.replace(/-/g, "").length;
                  return (
                    !numericValue ||
                    numericLength <= 10 ||
                    "Amount cannot be greater than 10 digits"
                  );
                },
              },
              onChange: (e) => {
                // Replace non-numeric characters except "-"
                let value = e.target.value.replace(/[^0-9-]/g, "");

                // Ensure only one "-" at the beginning
                if (value.indexOf("-") !== value.lastIndexOf("-")) {
                  value = value.replace(/--+/g, "-");
                }

                // Remove "-" for validation and formatting
                const isNegative = value.startsWith("-");
                let numericValue = value.replace(/-/g, "");

                // Limit numeric digits to 10
                if (numericValue.length > 10) {
                  numericValue = numericValue.slice(0, 10);
                }

                // Handle empty input and formatting
                if (numericValue === "") {
                  e.target.value = isNegative ? "-" : "";
                } else {
                  const formattedValue = new Intl.NumberFormat("en-IN").format(
                    parseInt(numericValue, 10)
                  );
                  e.target.value = isNegative
                    ? `-${formattedValue}`
                    : formattedValue;
                }
              },
            })}
            error={!!errors.Amount}
            helperText={errors.Amount?.message}
          />
          <TextField
            variant="outlined"
            type="date"
            className="w-[50%] max-680:w-full"
            label={
              <span>
                {language.hisab_Start_Date}{" "}
                <span className="text-red-500">*</span>
              </span>
            }
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: formattedToday,
            }}
            // Use sx prop to customize the input display
            sx={{
              '& input[type="date"]::-webkit-calendar-picker-indicator': {
                cursor: "pointer",
              },
              "& input": {
                "&::-webkit-datetime-edit-fields-wrapper": {
                  padding: "8px",
                },
              },
            }}
            {...register("Date")}
          />
        </Box>

        <Box className="w-[90%] flex items-center justify-between mt-4">
          <Button
            className="bg-[#F6F6F6] text-[10px] md:text-[14px] text-[#B1B1B1] rounded-[20px] flex items-center justify-center md:h-[38px] md:w-[133px]"
            type="button"
            onClick={() => {
              setAddParticipantOpen(true);
            }}
          >
            <img src={share} alt="share" className="mr-2" /> {language.share}
          </Button>
          <Typography className="flex text-[#B1B1B1] text-[12px] items-center md:text-[14px]">
            {language.add_Participants}
            <HelpOutlineOutlinedIcon
              alt="question"
              className="ml-2 h-[14px] w-[14px] md:h-[18px] md:w-[18px]"
              onClick={handlePopperClick}
            />
            <Popper id={id} open={open} anchorEl={anchorEl} transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Box
                    sx={{
                      border: 1,
                      p: 1,
                      bgcolor: "background.paper",
                      fontSize: "12px",
                      borderRadius: "8px",
                      maxWidth: "200px",
                      marginRight: "25px",
                    }}
                  >
                    {language.create_hisab_participant_popup}
                  </Box>
                </Fade>
              )}
            </Popper>
          </Typography>
        </Box>
        <Box className="w-[90%] mt-2">
          {viewParticipantsFromAdd.size > 0 && (
            <div className="w-full">
              <h1 className="text-[12px] text-[#848282]">View Hisab</h1>
              <div className="flex pl-3 max-w-[98%] overflow-auto horizontal-scrollbar">
                {[...viewParticipantsFromAdd]?.map((item) => (
                  <div className="!relative flex-shrink-0 mr-[20px] flex flex-col items-center">
                    <Avatar
                      className="w-[40px] h-[40px] "
                      src={`${process.env.REACT_APP_AWS_LINK}/uploads/user/${item?.user_id}/${item?.profile_photo}`}
                      alt={`preview-${item}`}
                    />
                    <button
                      className="!absolute !top-0 !right-0 !z-10 !w-[20px] !justify-end"
                      onClick={() => {
                        setViewParticipantsFromAdd((prevState) => {
                          const newSet = new Set(prevState);
                          if (newSet.has(item)) {
                            newSet.delete(item);
                          }
                          return newSet;
                        });
                      }}
                    >
                      <img
                        src={CloseIcon}
                        alt="close"
                        className="w-[18px] h-[18px]"
                      />
                    </button>
                    <Typography className="text-[12px] text-center">
                      {item.name}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
          )}
          {editParticipantsFromAdd.size > 0 && (
            <div className="w-full mt-2">
              <h1 className="text-[12px] text-[#848282]">Edit Hisab</h1>
              <div className="flex pl-3 max-w-[98%] overflow-auto horizontal-scrollbar">
                {[...editParticipantsFromAdd].map((item) => (
                  <div className="!relative flex-shrink-0 mr-[20px] flex flex-col items-center">
                    <Avatar
                      className="w-[40px] h-[40px] "
                      src={`${process.env.REACT_APP_AWS_LINK}/uploads/user/${item?.user_id}/${item?.profile_photo}`}
                      alt={`preview-${item}`}
                    />
                    <button
                      className="!absolute !top-0 !right-0 !z-10 !w-[20px] !justify-end"
                      onClick={() => {
                        setEditParticipantsFromAdd((prevState) => {
                          const newSet = new Set(prevState);
                          if (newSet.has(item)) {
                            newSet.delete(item);
                          }
                          return newSet;
                        });
                      }}
                    >
                      <img
                        src={CloseIcon}
                        alt="close"
                        className="w-[18px] h-[18px]"
                      />
                    </button>
                    <Typography className="text-[12px] text-center">
                      {item.name}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Box>

        <Box className="flex mt-3 w-[90%] gap-[5%]">
          <Button
            type="submit"
            className={`w-[50%] !capitalize md:!h-[48px] md:!text-[16px] !bg-gradient-to-r from-[#FFD87A] via-[#F6B825] to-[#F6B825] 
            ${hisabNameInput?.trim().length > 0 ? "filter-none" : "blur-[1px] opacity-[0.4]"} !text-black !font-semibold`}
            disabled={!hisabNameInput?.trim() || isBtnDisabled}
          >
            {isLoading ? (
              <Loader width={20} height={20} color="black" />
            ) : number ? (
              language.update
            ) : (
              language.create
            )}
          </Button>

          <Button
            type="button"
            className="w-[50%] !capitalize md:!h-[48px] md:!text-[16px] !border !border-solid !border-[#E2E8F0] !text-black !font-semibold"
            onClick={() => {
              setValue("HisabName", "");
              setValue("HisabDescription", "");
              setValue("Amount", "");
              navigate(-1);
            }}
          >
            {language.cancel}
          </Button>
        </Box>
      </Box>
      <ContactModal
        open={addParticipantOpen}
        setAddParticipantOpen={setAddParticipantOpen}
        AddHisab={true}
        viewParticipantsFromAdd={viewParticipantsFromAdd}
        setViewParticipantsFromAdd={setViewParticipantsFromAdd}
        setEditParticipantsFromAdd={setEditParticipantsFromAdd}
        editParticipantsFromAdd={editParticipantsFromAdd}
      />
    </Box>
  );
}

export default AddHisab;
