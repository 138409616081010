import { Box, Modal } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

function CustomModal(props) {
  const { open, handleClose, children, widthFor, height, isCloseIcon, borderRadiusForBox } = props;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: borderRadiusForBox ? borderRadiusForBox : "10px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
    background: "#fff", //:'#232325 ',
    pt: 3,
    px: 4,
    pb: 3,
  };
  return (
    <>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{ ...style, width: widthFor ? widthFor : { xs: '90%', sm: '480px' }, maxHeight: 500, height: height }}
          className="overflow-auto"
        >
          {isCloseIcon === true ? (
            <CloseIcon
              onClick={() => {
                handleClose();
              }}
              className="float-right block cursor-pointer"
            />
          ) : null}

          <>{children}</>
        </Box>
      </Modal>
    </>
  );
}

export default CustomModal;
