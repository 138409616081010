import React, { useEffect } from "react";

const ContactUs = () => {
  useEffect(() => {
    const supportUrl = `${process.env.REACT_APP_SUPPORT_LINK}`;
    window.open(supportUrl, '_blank');
  }, []);

  return null;
};

export default ContactUs;
