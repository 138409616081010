import Header from "../UI/Header";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../components/Loader/ButtonLoader";
import { TextField, Typography } from "@mui/material";
import { apiService } from "../app/services/api";
import CustomModal from "../components/CommonModal/CustomModal";
import defaultUserImage from "../images/loginpage/profile-user.png";
import { decryptAES, encryptAES } from "../components/CommonLibrary";
import { setGetUser, setUserMobileNumber } from "../app/Redux/reducer";
import BackArrow from "../images/hisabdetails/LeftArrow.png";

function MyProfile() {
  const userLoginData = useSelector((state) => state.usersLoginData.getUser);
  const language = useSelector((state) => state.usersLoginData.allLanguage);

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [newProfilePhoto , setNewProfilePhoto] = useState("")
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [isSuccessMessage, setIsSuccessMessage] = useState("");
  const [lastName, setLastName] = useState(userLoginData?.last_name);
  const [firstName, setFirstName] = useState(userLoginData?.first_name);
  const dispatch = useDispatch()
  const navigate = useNavigate();
  
 useEffect(() => {
   setProfilePhoto(userLoginData?.profile_photo);
 }, [userLoginData]);

  // Handle profile update submission
  const handleOnClick = async (event) => {
    event.preventDefault();
    setIsBtnDisabled(true);
    setIsLoading(true);

    const parameters = {
      user_id: userLoginData?.id,
      language_code: "en",
      first_name: firstName,
      last_name: lastName,
      referral_code: userLoginData?.referral_code,
    };

    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/users/update_profile`;
      const tokenUrl = localStorage.getItem("accessToken");
      const encryptedData = encryptAES(JSON.stringify(parameters));
      const formData = new FormData();
      formData.append("data", encryptedData);
      if (newProfilePhoto) {
        formData.append("profile_photo", newProfilePhoto);
      }
      const result = await apiService(
        url,
        "POST",
        formData,
        tokenUrl,
        "en"
      );

      const decryptedResponse = JSON.parse(decryptAES(result.data.response));
      if (decryptedResponse.status === "200") {
        getUser();
        toast.success(decryptedResponse.message)
      } else {
        toast.error(decryptedResponse.message);
      }
    } catch (error) {
      console.error("Profile update error:", error);
    } finally {
      setIsBtnDisabled(false);
      setIsLoading(false);
    }
  };

  const getUser = async () => {
     const parameters = { user_id: userLoginData?.id};
     try {
       const url = process.env.REACT_APP_API_BASE_URL + "/users/get_user";
       const tokenUrl = localStorage.getItem("accessToken");
       const encryptedData = encryptAES(JSON.stringify(parameters));
       const languageCode = "en";
       const formData = new FormData();
       formData.append("data", encryptedData);
       const result = await apiService(
         url,
         "POST",
         formData,
         tokenUrl,
         languageCode
       );
 
       const decryptedResponse = decryptAES(result.data.response);
 
       if (
         JSON.parse(decryptedResponse).status === "200" ||
         JSON.parse(decryptedResponse).status === 200
       ) {
         dispatch(setGetUser(JSON.parse(decryptedResponse).users_data));
         dispatch(setUserMobileNumber(JSON.parse(decryptedResponse).users_data.mobile_number));
       }
     } catch (error) {
       console.log("error", error);
     }
   };

  // Handle profile photo selection
  const handleProfilePhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      setNewProfilePhoto(file)
    }
  };
  const isFormValid = firstName?.trim() && lastName?.trim();

  return (
    <div>
    <header className="flex gap-1 border-b ">
          <button
            className="pt-2 max-480:w-[46px] p-2 flex justify-center items-center"
            onClick={() => navigate(-1)}
            aria-label="Go back"
          >
            <img alt="Back" src={BackArrow} width={24} height={24} />
          </button>
      <Header title={language?.profile} className="w-full p-2"/>
        </header>
      <div className="drop-shadow-xl mt-3 ">
        <div className="w-[50%] mx-auto max-425:w-[90%] bg-white px-[24px] py-[24px]">
          <form onSubmit={handleOnClick}>
            {/* Profile Photo */}
            <div className="flex justify-center items-center">
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                className="hidden"
                onChange={handleProfilePhotoChange}
              />
              <label htmlFor="fileInput">
                <img
                  src={
                    newProfilePhoto
                      ? URL.createObjectURL(newProfilePhoto)
                      : `${process.env.REACT_APP_API_BASE_URL}/uploads/user/${
                          userLoginData?.id
                        }/${userLoginData?.profile_photo}`
                  }
                  alt="User profile"
                  className="w-[90px] h-[90px] object-cover cursor-pointer rounded-full"
                />
              </label>
            </div>

            {/* Divider */}
            <div className="my-8 border-t border-gray-200" />

            {/* Input Fields */}
            <div>
              <TextField
                autoFocus
                id="first-name"
                label={
                  <span className="">
                    {language.first_Name}<span className="text-red-500 ml-1">*</span>
                  </span>
                }
                variant="outlined"
                fullWidth
                value={firstName}
                onChange={(e) =>
                  /^[A-Za-z\s]*$/.test(e.target.value) &&
                  e.target.value.length <= 20 &&
                  setFirstName(e.target.value)
                }
                inputProps={{ maxLength: 20 }}
                sx={{ marginBottom: 3 }}
              />
              <TextField
                // autoFocus
                id="last-name"
                label={
                  <span className="">
                    {language.last_Name}<span className="text-red-500 ml-1">*</span>
                  </span>
                }
                variant="outlined"
                fullWidth
                sx={{
                  height:"40"
                }}
                value={lastName}
                onChange={(e) =>
                  /^[A-Za-z\s]*$/.test(e.target.value) &&
                  e.target.value.length <= 20 &&
                  setLastName(e.target.value)
                }
                inputProps={{ maxLength: 20 }}
              />
            </div>

            {/* Submit Button */}
            <div className="flex justify-center mt-8">
              <button
                type="submit"
                className={`w-full h-10 max-w-[300px] rounded-md text-black font-medium ${
                  isFormValid
                    ? "bg-gradient-to-r from-[#FFD87A] to-[#F6B825]"
                    : "bg-[#FFD87A] opacity-[35%] text-gray-400 cursor-not-allowed"
                }`}
                disabled={!isFormValid || isBtnDisabled}
              >
                {isLoading ? <Loader width={20} height={20} /> : language?.update}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default MyProfile;
