import React, { useEffect, useState, useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid2";
import LeftPage from "../../UI/LeftPage";
import RightBar from "../../UI/RightBar";
import { useDispatch } from "react-redux";
import { loginApi } from "../../app/services/api";
import { setLanguage } from "../../app/Redux/reducer";
import { decryptAES, encryptAES } from "../../components/CommonLibrary";

const INITIAL_TIMER = 60;
const MOBILE_MAX_LENGTH = 10;
const DEFAULT_LANGUAGE = "en";
const LANGUAGE_API_BASE = "https://desihisab.s3.ap-south-1.amazonaws.com/confdata/devconf/lang";

function Login() {
  // State management
  const [loginState, setLoginState] = useState({
    mobileNumber: "",
    verifyOtp: false,
    isActive: true,
    isLoading: false,
    isBtnDisabled: false,
    isSuccessMessage: "",
    open: false,
    language: null
  });
  const [time, setTime] = useState(() => {
    const savedTime = localStorage.getItem("time");
    return savedTime ? parseInt(savedTime) : 60;
  });
  const dispatch = useDispatch();

  // Memoized login data
  const loginData = useMemo(() => ({
    mobile_number: Number(loginState.mobileNumber),
    country_id: "91",
    language_code: DEFAULT_LANGUAGE,
    login_device_detail: "android",
    new_app: "Newapp",
    route: null
  }), [loginState.mobileNumber]);

  // API call handler
  const handleApiCall = useCallback(async () => {
    setLoginState(prev => ({ ...prev, isLoading: true, isBtnDisabled: true }));

    try {
      const formData = new FormData();
      formData.append("data", encryptAES(JSON.stringify(loginData)));

      const url = `${process.env.REACT_APP_API_BASE_URL}/users/generate_otp`;
      const result = await loginApi(url, "POST", formData);
      
      const decryptedResponse = JSON.parse(decryptAES(result.data.response));
      
      if (decryptedResponse.message === `Your account has been deleted by you. Click on "Recover Now" to restore it.`) {
        setLoginState(prev => ({
          ...prev,
          isSuccessMessage: decryptedResponse.message,
          open: true
        }));
        return;
      }

      if (decryptedResponse.status === 200 || decryptedResponse.status === "200") {
        setLoginState(prev => ({
          ...prev,
          verifyOtp: true,
          time: INITIAL_TIMER,
          isActive: true
        }));
        toast.success(decryptedResponse.message);
      } else {
        setLoginState(prev => ({ ...prev, verifyOtp: false }));
        toast.error(decryptedResponse.message);
      }
    } catch (error) {
      console.error("Error during login:", error);
      toast.error("Login failed. Please try again.");
    } finally {
      setLoginState(prev => ({
        ...prev,
        isLoading: false,
        isBtnDisabled: false
      }));
    }
  }, [loginData]);

  // Event handlers
  const handleMobileChange = useCallback((e) => {
    const value = e.target.value.replace(/[^0-9]/g, "").slice(0, MOBILE_MAX_LENGTH);
    setLoginState(prev => ({ ...prev, mobileNumber: value }));
  }, []);

  const handleKeyDown = useCallback((e) => {
    if (e.key === "Enter" && loginState.mobileNumber.length === MOBILE_MAX_LENGTH) {
      e.preventDefault();
      handleApiCall();
    }
  }, [handleApiCall, loginState.mobileNumber]);

  const onLogin = useCallback((e) => {
    e.preventDefault();
    handleApiCall();
  }, [handleApiCall]);

  // Language setup effect
  useEffect(() => {
    if (!localStorage.getItem("selectedLanguage")) {
      localStorage.setItem("selectedLanguage", DEFAULT_LANGUAGE);
    }

    const fetchLanguageData = async () => {
      if (loginState.language) return;

      try {
        const selectedLanguage = localStorage.getItem("selectedLanguage");
        const response = await fetch(`${LANGUAGE_API_BASE}/${selectedLanguage}.json`);
        
        if (!response.ok) throw new Error('Failed to fetch language data');
        
        const data = await response.json();
        dispatch(setLanguage(data));
        setLoginState(prev => ({ ...prev, language: data }));
      } catch (error) {
        console.error("Error fetching language data:", error);
        toast.error("Failed to load language settings");
      }
    };

    fetchLanguageData();
  }, [dispatch, loginState.language]);

  useEffect(() => {
    const toastMessage = localStorage.getItem("toastMessage");
    if (toastMessage && toastMessage !== "") {
      toast.warn(toastMessage);
    }
    setTimeout(() => {
      localStorage.setItem("toastMessage", "");
    }, 500);
  }, []); 

  return (
    <Grid container className="justify-center">
      <Grid  size={7} className="h-screen hidden lg:block">
        <LeftPage />
      </Grid>

      <Grid className="" size={{ xs: 12, md: 5 }}>
        <RightBar
          {...loginState}
          time={time}
          setVerifyOtp={(verifyOtp) => setLoginState(prev => ({ ...prev, verifyOtp }))}
          setTime={setTime}
          setIsActive={(isActive) => setLoginState(prev => ({ ...prev, isActive }))}
          setOpen={(open) => setLoginState(prev => ({ ...prev, open }))}
          handleMobileChange={handleMobileChange}
          handleKeyDown={handleKeyDown}
          onLogin={onLogin}
        />
      </Grid>
    </Grid>
  );
}

export default Login;