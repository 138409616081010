import axios from "axios";
import { decryptAES } from "../../components/CommonLibrary";

const sessionOut = () =>{
  const message = "This account has been logged-in from different device";
  localStorage.setItem( "accessToken" , "");
  localStorage.setItem('toastMessage', message);
  window.location.href = "/login";
}

export const loginApi = async (url, method, data = null) => {
  try {
    const response = await axios({
      url,
      method,
      data,
    });
    return response;
  } catch (error) {
    if (error?.response.status === 401 ||  error?.response?.statusText === "Unauthorized"){
      sessionOut()
    }  
    return error.response;
  }
};

export const apiService = async (url, method, data = null, token) => {
  const headers = {
    token: `${decryptAES(token)}`,
  };

  try {
    const response = await axios({
      url,
      method,
      data,
      headers,
    });

    return response;
  } catch (error) {
    if (error?.response.status === 401 ||  error?.response?.statusText === "Unauthorized"){
      sessionOut()
    }  
    return error.response;
  }
};
