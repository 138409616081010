import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader/Loader";
import React, { useState, useEffect } from "react";
import { setLanguage } from "../app/Redux/reducer";
import { Box, Radio, Typography } from "@mui/material";
import hindiImage1 from "../images/Language/hindLanguage1.png";
import hindiImage2 from "../images/Language/hindiLanguage2.png";
import englishImage1 from "../images/Language/engilshLanguage1.png";
import englishImage2 from "../images/Language/englishLanguage2.png";
import { toast } from "react-toastify";
import Header from "../UI/Header";
import BackArrow from "../images/hisabdetails/LeftArrow.png";
import { useNavigate } from "react-router-dom";


function Language() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const language = useSelector((state) => state.usersLoginData.allLanguage);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      setSelectedLanguage(savedLanguage);
    }
  }, []);

  const handleLanguageChange = (event) => {
    const language = event.target.value;
    setSelectedLanguage(language);
  };

  const onHandleLanguageChange = async () => {
    if (selectedLanguage != localStorage.getItem("selectedLanguage")){
      toast.dismiss()
      toast.success(`Language changed to ${selectedLanguage === 'en' ? "English" : "Hindi"} successfully`)
    }
    localStorage.setItem("selectedLanguage", selectedLanguage || 'en');
    try {

      const response = await fetch(
        `${process.env.REACT_APP_WEB_LANGUGAE}/${localStorage.getItem("selectedLanguage")}.json`
      );
      const data = await response.json();
      setIsLoading(false);
      dispatch(setLanguage(data));
    } catch (error) {
      console.error("Error fetching language data:", error);
      setIsLoading(false);
    }
  };

  return (
    <Box className="">
      {/* <Typography className=" pl-[16px] pt-2 pb-2 text-[20px] font-[500] font-sans max-768:text-[18px]">
        {language.cahnge_language}
      </Typography> */}
      <header className="flex gap-1 border-b"> 
          <button
            className="pt-2 max-480:w-[46px] pl-2 flex justify-center items-center"
            onClick={() => navigate(-1)}
            aria-label="Go back"
          >
            <img alt="Back" src={BackArrow} width={24} height={24} />
          </button>
          <Header title={language?.cahnge_language} className="w-full p-2"/>
        </header>

      <Box className="bg-white rounded-lg ml-4  mr-4 shadow-2xl overflow-hidden z-30 flex justify-between items-center pl-[13px] pr-[25px] py-[13px] my-[20px]">
        <Box className="flex justify-center items-center">
          <Radio
            checked={selectedLanguage === "en"}
            value="en"
            onChange={handleLanguageChange}
          />
          <Box className="ml-[17px]">
            <Typography className="font-medium text-[16px]">English</Typography>
            <Typography className="font-medium text-[11px] text-[#848282]">
              English
            </Typography>
          </Box>
        </Box>
        <Box>
          <img
            src={selectedLanguage === "en" ? englishImage2 : englishImage1}
            alt="English Language"
            className="w-[53px] h-[53px]"
          />
        </Box>
      </Box>

      <Box className="bg-white rounded-lg ml-4  mr-4 shadow-2xl overflow-hidden z-30 flex justify-between items-center pl-[13px] pr-[25px] py-[13px]">
        <Box className="flex justify-center items-center">
          <Radio
            checked={selectedLanguage === "hi"}
            value="hi"
            onChange={handleLanguageChange}
          />
          <Box className="ml-[17px]">
            <Typography className="font-medium text-[16px]">हिंदी</Typography>
            <Typography className="font-medium text-[11px] text-[#848282]">
              Hindi
            </Typography>
          </Box>
        </Box>
        <Box>
          <img
            src={selectedLanguage === "hi" ? hindiImage2 : hindiImage1}
            alt="Hindi Language"
            className="w-[53px] h-[53px]"
          />
        </Box>
      </Box>
      <Box className=" ml-4  mr-4">

        <button
          className="bg-gradient-to-r  from-[#FFD87A] to-[#F6B825] w-[100%] h-[40.67px] rounded-[5px] text-[16px] font-medium mt-[30px]"
          onClick={onHandleLanguageChange}
        >
          {isLoading ? (
            <Loader width={20} height={20} color={"black"} />
          ) : selectedLanguage === "hi" ? (
            "आगे बढ़ें"
          ) : (
            "Next"
          )}
        </button>
      </Box>
    </Box>
  );
}

export default Language;
