import React from "react";
import loaderGif from "../../images/Loader/Loader.gif";
function Loader({ width, height, color }) {
  return (
    <div
      className="inline-block h-8 w-8 "
      role="status"
      style={{
        width: `${80}px`,
        height: `${80}px`,
        color: `${80}`,
      }}
    >
     <img alt="loader" src={loaderGif}></img>
    </div>
  );
}

export default Loader;
