import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { decryptAES, encryptAES } from "../components/CommonLibrary";
import { apiService } from "../app/services/api";
import { toast } from "react-toastify";
import { Avatar, Box, Button, TextField, Typography } from "@mui/material";
import BackArrow from "../images/hisabdetails/LeftArrow.png";
import Grid from "@mui/material/Grid2";
import SearchBar from "../components/Search";
import { debounce } from "lodash";
import Loader from "../components/Loader/Loader";
import Detials from "../images/hisabdetails/Details.png"
import plus from "../images/hisabdetails/plus.png"


function ViewAllTransaction() {
  const { id } = useParams();
  let decryptedID;
  if (id){
    decryptedID = decryptAES(id);
  }
  const urlObject = JSON.parse(decryptedID ? decryptedID : null);
  
  const navigate = useNavigate();
  const number =urlObject?.id;
  const userId = useSelector(
    (state) => state.usersLoginData.loginUserDetails.user_id
  );
  const [isLoading, setIsLoading] = useState(false);
  const [hisabDetailsData, setHisabDetailsData] = useState([]);
  const tokenUrl = localStorage.getItem("accessToken");
  const [query, setQuery] = useState("");
  const language = useSelector((state) => state.usersLoginData.allLanguage);
  

  async function getDetails() {
    setIsLoading(true);
    try {
      const url =
        process.env.REACT_APP_API_BASE_URL + "/transaction/list_transaction";
        const body = {
          hisab_id: number,
          page_no: 1,
        };
        if (query.length > 0) {
          body.search_by = query;
        }
      const encryptedData = encryptAES(JSON.stringify(body));
      const formData = new FormData();
      formData.append("data", encryptedData);
      const res = await apiService(url, "POST", formData, tokenUrl);
      const decryptedResponse = decryptAES(res.data.response);
      if (
        JSON.parse(decryptedResponse).status === 200 ||
        JSON.parse(decryptedResponse).status === "200"
      ) {
        toast.success(JSON.parse(decryptedResponse).message);
        const data = await JSON.parse(decryptedResponse);
        setHisabDetailsData(data);
        setIsLoading(false);
      } else {
        toast.error(JSON.parse(decryptedResponse).message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("error", error);
      setIsLoading(false);
    }
  }
  
  useEffect(() => {
    if (number) {
      getDetails();
    }else{
      navigate("/Error")
    }
  }, [number, query]);

  const debouncedSearch = debounce((searchQuery) => {
    setQuery(searchQuery.trim());
  }, 500);
  const findModeTransactions = (item) => {
    let found = language.payment_mode_list[0].val1.find(
      (element) => element.id === item?.transaction_mode
    );
    return found?.title;
  };

  return (
    <>
      <div className="overflow-y-hidden h-[100%]">
        <div className="flex border-b">
          <Button
            className="md:pl-2 bg-transparent"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img
              alt="backImage"
              className="h-[23px] w-[23px]"
              src={BackArrow}
            ></img>
          </Button>
          <h1 className="pb-3 pt-3 text-[16px] font-medium max-480:text-[16px] pl-0">
            {hisabDetailsData.hisab_name}
          </h1>
        </div>
        <Box className="pl-3 pr-4 w-full mt-4 mb-3">
          <Grid container>
            <Grid size={{ md: 12, xs: 12 }}>
              <SearchBar onSearch={debouncedSearch} />
            </Grid>
            {/* < size={{ md: 1, xs: 1 }} className="flex items-center justify-center">
            <button className="!justify-center ml-1 w-full flex bg-transparent">
              <img alt="add" className="h-[20px] w-[18px]" src={Detials}></img>
            </button>
          </Grid> */}
          </Grid>
        </Box>
        <div className="gap-1 flex flex-col w-full pl-2 pr-2 pb-10 viewallscroll ">
          {isLoading ? (
            <div className="flex items-center !justify-center !w-[96] !h-[60vh]">
              <Loader width={40} height={40} color={"black"} />
            </div>
          ) : hisabDetailsData &&
            hisabDetailsData.transactions_data?.length > 0 ? (
            hisabDetailsData.transactions_data.map((item) => (
              <div
                className="w-[100%] flex flex-col items-center"
                to={`/ViewTransaction/:id=${item.id}`}
                key={item.id}
                onClick={() => {
                  const encryptedId = encryptAES(
                    JSON.stringify({ id: item?.id })
                  );
                  const encryptedNumber = encryptAES(
                    JSON.stringify({ id: number })
                  );
                  if (item.id && encryptedId) {
                    const encodedData = encodeURIComponent(encryptedId);
                    const encodedDataNumber =
                      encodeURIComponent(encryptedNumber);
                    {
                      item.transaction_title === "Opening Balance"
                        ? navigate(`/Details/${encodedDataNumber}`)
                        : navigate(`/ViewTransaction/${encodedData}`);
                    }
                  }
                }}
              >
                <Grid
                  container
                  spacing={2}
                  className="!w-full pl-1 pr-2 mt-1 cursor-pointer"
                >
                  <Grid size={12}>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        border: "1px solid #F5F5F5",
                        alignItems: "center",
                        paddingLeft: 2,
                        borderRadius: "8px",
                        height: "75px",
                        gap: "1rem",
                      }}
                    >
                      <Avatar
                        className="text-[14px]"
                        sx={{
                          width: { xs: 30, sm: 40 },
                          height: { xs: 30, sm: 40 },
                        }}
                        alt="profile"
                        src={`${process.env.REACT_APP_API_BASE_URL}/uploads/user/${item.user_id}/${item.profile_photo}`}
                      />

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "90%",
                          gap: "5px",
                        }}
                      >
                        {/* Transaction Title & Amount Section */}
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={1}
                          className=""
                        >
                          <Grid item xs={7}>
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: "500",
                                fontSize: { xs: "10px", sm: "15px" },
                              }}
                            >
                              {item.transaction_title}
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: "bold",
                                fontSize: { xs: "10px", sm: "14px" },
                                color:
                                  item.transaction_type === "income"
                                    ? "#46A759"
                                    : "#E5474D",
                                textAlign: "right",
                              }}
                            >
                              {item.transaction_type === "income" ? "+" : "-"}{" "}
                              {item.transaction_amount}
                            </Typography>
                          </Grid>
                        </Grid>

                        {/* User Info & Date Section */}
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={1}
                          className=""
                        >
                          <Grid>
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{
                                  backgroundColor: "#F6B82547",
                                  fontWeight: "400",
                                  borderRadius: "2px",
                                  fontSize: { xs: "10px", sm: "12px" },
                                  padding: "3px 6px",
                                }}
                              >
                                {item?.first_name} {item?.last_name}
                              </Typography>
                              {item.transaction_title !== "Opening Balance" && <p className="bg-[#92CDFE47] text-[12px] px-[6px] rounded-[2px] p-[3px] max-480:text-[10px]">
                                    {findModeTransactions(item)}
                                  </p>}
                            </Box>
                          </Grid>
                          <Grid>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: { xs: "10px", sm: "12px" },
                                color: "#B1B1B1",
                                textAlign: "right",
                              }}
                            >
                              {item.transaction_date}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            ))
          ) : (
            <div className="flex items-center !justify-center !w-[96%] !h-[60vh]">
              No Transaction Found
            </div>
          )}
          {/* {hisabDetailsData.user_permission === "edit" ? (
            <Button
              className="fixed right-[15px] bottom-[3px] max-768:right-[3px]"
              onClick={() => {
                const encryptedData = encryptAES(
                  JSON.stringify({
                    id: hisabDetailsData?.transactions_data[0]?.hisab_id,
                  })
                );
                const encryptedData1 = encryptAES(
                  JSON.stringify({
                    hisabType: "Expense",
                  })
                );
                const encodedData = encodeURIComponent(encryptedData);
                const encodedData1 = encodeURIComponent(encryptedData1);
                navigate(`/AddIncome/${encodedData}/${encodedData1}`);
              }}
            >
              <img
                src={plus}
                alt="plus_image"
                className="w-[50px] max-424:w-[40px]"
              />
            </Button>
          ) : (
            ""
          )} */}
        </div>
      </div>
    </>
  );
}
export default ViewAllTransaction;
